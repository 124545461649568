import { host } from "host/host";
import mac from "./mac.module.css";
import win from "./win.module.css";

export const headerHostStyle: {
    container: string;
    main: string;
    more: string;
    box: string;
} = (() => {
    switch (host) {
        case "web":
            return { container: "", main: "", more: "", box: "" };
        case "mac": {
            const { container, main, more, box } = mac;
            return { container, main, more, box };
        }
        case "win": {
            const { container, main, more, box } = win;
            return { container, main, more, box };
        }
    }
})();
