import { TABLE_BODY_WIDTH } from "canvas/table/geometry";
import { EditColumn } from "diagram/model/edit/edit";
import { RowWrapper, RowWrapperProps } from "../wrapper/wrapper";

interface Props extends RowWrapperProps {
    columns: EditColumn[];
}

export const RowInactive = (props: Props) => {
    const { row } = props;
    const { isPrimary } = row;

    return (
        <RowWrapper {...props}>
            <div
                className={[
                    "flex items-center h-full px-3",
                    isPrimary ? "font-semibold" : "",
                ].join(" ")}
            >
                <div className={"flex"} style={{ width: TABLE_BODY_WIDTH }}>
                    <div
                        className={[
                            "flex-1 overflow-hidden text-ellipsis whitespace-nowrap",
                        ].join(" ")}
                    >
                        {props.row.value[props.columns[0].value.name]}
                    </div>
                    <div
                        className={[
                            "flex-0 text-right ml-2",
                            "text-[var(--slate11)]",
                        ].join(" ")}
                    >
                        {props.row.value[props.columns[1].value.name]}
                    </div>
                </div>
            </div>
        </RowWrapper>
    );
};
