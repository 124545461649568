import { EditDiagram } from "diagram/model/edit/edit";
import { DiagramArrangeActions, diagramArrangeReducer } from "./arrange/arrange"; // prettier-ignore
import { DiagramDiagramActions, diagramDiagramReducer } from "./diagram/diagram"; // prettier-ignore
import { DiagramRefActions, diagramRefReducer } from "./ref/ref";
import { DiagramRowActions, diagramRowReducer } from "./row/row";
import { DiagramSelectActions, diagramSelectReducer } from "./select/select";
import { DiagramTableActions, diagramTableReducer } from "./table/table";

export type DiagramReducer<T = DiagramAction> = (
    diagram: EditDiagram,
    arg: T
) => EditDiagram;

export type DiagramAction =
    | DiagramTableActions
    | DiagramRowActions
    | DiagramRefActions
    | DiagramSelectActions
    | DiagramArrangeActions
    | DiagramDiagramActions;

export const diagramReducer: DiagramReducer = (prev, action) => {
    let next = prev;
    next = diagramTableReducer(next, action);
    next = diagramRowReducer(next, action);
    next = diagramRefReducer(next, action);
    next = diagramSelectReducer(next, action);
    next = diagramArrangeReducer(next, action);
    next = diagramDiagramReducer(next, action);
    return next;
};
