import { TABLE_MARGIN_SINGLE } from "canvas/table/geometry";
import { EditDiagram } from "diagram/model/edit/edit";
import { Position } from "geometry/geometry";
import { getNumberId } from "utilities/id";

export const appendDiagram = (
    original: EditDiagram,
    target: EditDiagram
): EditDiagram => {
    if (original.columns.length !== target.columns.length)
        throw Error("Cannot merge diagrams with different columns");

    const ids: Set<number> = new Set(original.tables.map((t) => t.id));

    const tables = [...original.tables];
    target.tables.forEach((table) => {
        const id = getNumberId(ids);
        ids.add(id); // Avoid using this id next time
        const left = table.position.left + TABLE_MARGIN_SINGLE;
        const position: Position = { ...table.position, left };
        tables.push({ ...table, id, position });
    });

    return { ...original, tables };
};
