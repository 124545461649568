import { parseDiagram } from "diagram/model/parse/parse";
import { Library, LibraryItem, OldLibraryItem } from "library/library";
import { getStringId } from "utilities/id";

const DOC_PREFIX = "dg/v2/doc/";
const OLD_DOC_PREFIX = "dg/doc/";

const getKey = (id: string): string => `${DOC_PREFIX}${id}`;

const get = async (id: string): Promise<LibraryItem> => {
    const stored = window.localStorage.getItem(getKey(id));
    if (stored !== null) {
        // Rebuild diagram from stored JSON string
        const retrievedItemJSON = JSON.parse(stored);
        const id = retrievedItemJSON["id"];
        const name = retrievedItemJSON["name"];
        const diagramText = retrievedItemJSON["diagram"];
        const saved = retrievedItemJSON["saved"];

        return {
            id: id,
            name: name,
            diagram: parseDiagram(diagramText),
            saved: saved,
            shared: false,
        };
    }
    throw Error(`Document not found: "${id}"`);
};

const set = async (item: LibraryItem): Promise<void> => {
    const { id } = item;
    const value = JSON.stringify(item);

    window.localStorage.setItem(getKey(id.toString()), value);
};

const list = async (): Promise<Library["all"]> => {
    return (
        Object.entries(window.localStorage)
            .map((entry) => {
                return { key: entry[0], value: entry[1] as string };
            })
            .filter((entry) => entry.key.startsWith(DOC_PREFIX))
            // Filter out unparseable diagrams
            .filter((entry) => {
                try {
                    const retrievedItemJSON = JSON.parse(entry.value);
                    const diagramText = retrievedItemJSON["diagram"];
                    parseDiagram(diagramText);
                    return true;
                } catch (e) {
                    return false;
                }
            })
            .map((entry) => {
                const retrievedItemJSON = JSON.parse(entry.value);
                const id = retrievedItemJSON["id"];
                const name = retrievedItemJSON["name"];
                const diagramText = retrievedItemJSON["diagram"];
                const saved = retrievedItemJSON["saved"];

                return {
                    id: id,
                    name: name,
                    diagram: parseDiagram(diagramText),
                    saved: saved,
                    shared: false,
                };
            })
    );
};

const listOldDiagrams = async (): Promise<OldLibraryItem[]> => {
    return (
        Object.entries(window.localStorage)
            .map((entry) => {
                return { key: entry[0], value: entry[1] as string };
            })
            .filter((entry) => entry.key.startsWith(OLD_DOC_PREFIX))
            // Filter out unparseable diagrams
            .filter((entry) => {
                try {
                    parseDiagram(entry.value);
                    return true;
                } catch (e) {
                    return false;
                }
            })
            .map((entry) => {
                return {
                    name: entry.key.replace(OLD_DOC_PREFIX, ""),
                    diagram: parseDiagram(entry.value),
                    saved: true,
                    shared: false,
                };
            })
    );
};

const remove = async (id: number): Promise<void> => {
    window.localStorage.removeItem(getKey(id.toString()));
};

const duplicate = async (item: LibraryItem): Promise<void> => {
    const { id, name } = item;
    const dupedItem = {
        ...item,
        id: id + 1,
        name: `${getStringId([name], name)}`,
    };
    await set(dupedItem);
};

const rename = async (item: LibraryItem, to: string): Promise<void> => {
    const { id } = item;
    const renamedItem = { ...item, name: to };
    await remove(id);
    await set(renamedItem);
};

const clear = async (): Promise<void> => {
    Object.entries(window.localStorage)
        .map((entry) => {
            return { key: entry[0], value: entry[1] as string };
        })
        .filter((entry) => entry.key.startsWith(DOC_PREFIX))
        .forEach((entry) => {
            window.localStorage.removeItem(entry.key);
        });
};

const clearOldDiagrams = async (): Promise<void> => {
    Object.entries(window.localStorage)
        .map((entry) => {
            return { key: entry[0], value: entry[1] as string };
        })
        .filter((entry) => entry.key.startsWith(OLD_DOC_PREFIX))
        .forEach((entry) => {
            window.localStorage.removeItem(entry.key);
        });
};

export const DocStorage = {
    get,
    set,
    list,
    listOldDiagrams,
    remove,
    clear,
    clearOldDiagrams,
    duplicate,
    rename,
};
