import { EditDiagramUnknown } from "./edit";
import { EditDiagramV1 } from "./edit-v1";

export interface EditDiagramV2 extends EditDiagramV1 {
    type: "edit";
    version: 2;
}

export const isEditDiagramV2 = (
    diagram: EditDiagramUnknown
): diagram is EditDiagramV2 => {
    return "version" in diagram && diagram.version === 2;
};

export const migrateEditDiagramV1toV2 = (v1: EditDiagramV1): EditDiagramV2 => ({
    ...v1,
    type: "edit",
    version: 2,
});
