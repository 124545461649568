import { createRoot } from "react-dom/client";

type Render = (unmount: () => void) => JSX.Element;

export const renderDialog = (render: Render): void => {
    const container = document.createElement("div");
    document.body.appendChild(container);
    const root = createRoot(container);
    const dialog = render(() => {
        root.unmount();
        container.remove();
    });
    root.render(dialog);
};
