import { Icons } from "utilities/icon";
import { AuthContext } from "auth/auth";
import React from "react";
import { ButtonMenu } from "app/button/menu";

export const HeaderLogin = () => {
    const {
        userDisplayName,
        isAuthenticated,
        loginWithGithub,
        loginWithGoogle,
        logout,
    } = React.useContext(AuthContext);

    // If authed, just says logout
    // If not authed, shows a dropdown with login options
    const userShortName = userDisplayName?.split(" ")[0];

    return isAuthenticated ? (
        <ButtonMenu
            key="more"
            button={{
                icon: Icons.people,
                children: `${userShortName}...`,
                color: "solid",
            }}
            items={[
                {
                    label: "Logged in as " + userDisplayName,
                },
                {
                    label: "Logout",
                    fn: () => {
                        logout();
                    },
                },
            ]}
        />
    ) : (
        <ButtonMenu
            key="more"
            button={{
                icon: Icons.login,
                children: "Login",
                color: "solid",
            }}
            items={[
                {
                    label: "Login with GitHub",
                    fn: () => loginWithGithub(),
                },
                {
                    label: "Login with Google",
                    fn: () => loginWithGoogle(),
                },
            ]}
        />
    );
};
