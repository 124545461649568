type ArrOrSet<T> = T[] | Set<T>;

const getGenericId = <T>(
    ids: ArrOrSet<T>,
    makeId: (counter: number) => T
): T => {
    const set: Set<T> = ids instanceof Set ? ids : new Set(ids);
    let counter = 1;
    let id = makeId(counter);
    while (set.has(id)) {
        counter = counter + 1;
        id = makeId(counter);
    }
    return id;
};

export const getStringId = (ids: ArrOrSet<string>, prefix: string) =>
    getGenericId(ids, (counter) =>
        counter === 1 ? prefix : `${prefix}_${counter}`
    );

export const getNumberId = (ids: ArrOrSet<number>) => {
    const set: Set<number> = ids instanceof Set ? ids : new Set(ids);
    let array = Array.from(set);
    let counter = array[array.length - 1] ?? 1
    let id = counter;
    while (set.has(id)) {
        counter = counter + 1;
        id = counter;
    }
    return id;
};
