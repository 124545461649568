import { Position } from "./point";

export interface Size {
    width: number;
    height: number;
}

export interface Box extends Position, Size {}

export const isBoxesOverlap = (a: Box, b: Box) => {
    // If one rectangle is on the left side of other
    if (a.left > b.left + b.width) return false;
    if (b.left > a.left + a.width) return false;
    // If one rectangle is above the other
    if (a.top > b.top + b.height) return false;
    if (b.top > a.top + a.height) return false;
    // Otherwise it overlaps
    return true;
};
