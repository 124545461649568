import { EditDiagram, EditRow, EditTable } from "diagram/model/edit/edit";
import { DiagramReducer } from "../reducer";

export interface UpdateRowPayload {
    tableId: number;
    rowId: number;
    property: string;
    value: string;
}

type Payload = UpdateRowPayload;

const updateEditRow = (payload: Payload) => (row: EditRow): EditRow => {
    if (row.id !== payload.rowId) return row;
    const value = { ...row.value }; // EditRow.value or Diagram.Row
    value[payload.property] = payload.value;
    return { ...row, value };
};

const updateTable = (payload: Payload) => (table: EditTable): EditTable => {
    if (table.id !== payload.tableId) return table;
    const rows = table.value.rows.map(updateEditRow(payload));
    return { ...table, value: { ...table.value, rows } };
};

const isSame = (diagram: EditDiagram, payload: Payload): boolean => {
    const { tableId, rowId, property: attribute, value } = payload;
    const row = diagram.tables
        .find((table) => table.id === tableId) // table
        ?.value.rows.find((row) => row.id === rowId); // row
    if (!row) throw Error(`Row "${tableId}.${rowId}" not found`);
    return row.value[attribute] === value;
};

export const updateRow: DiagramReducer<Payload> = (diagram, payload) => {
    if (isSame(diagram, payload)) return diagram;
    const tables = diagram.tables.map(updateTable(payload));
    return { ...diagram, tables };
};
