import { EditTable } from "diagram/model/edit/edit";
import { DiagramReducer } from "../reducer";

export interface MoveRowPayload {
    tableId: number;
    fromIndex: number;
    toIndex: number;
}

type Payload = MoveRowPayload;

const updateTable = (payload: Payload) => (table: EditTable): EditTable => {
    if (table.id !== payload.tableId) return table;
    let { fromIndex: from, toIndex: to } = payload;
    [from, to] = from > to ? [from, to] : [from, to - 1];
    const row = table.value.rows[from];
    const rows = [...table.value.rows];
    rows.splice(from, 1);
    rows.splice(to, 0, row);
    return { ...table, value: { ...table.value, rows } };
};

export const moveRow: DiagramReducer<Payload> = (diagram, payload) => {
    if (payload.fromIndex === payload.toIndex) return diagram;
    const tables = diagram.tables.map(updateTable(payload));
    return { ...diagram, tables };
};
