import { EditRef, EditRefSide } from "diagram/model/edit/edit";
import { getNumberId } from "utilities/id";
import { DiagramReducer } from "../reducer";

export interface AddRefPayload {
    from: EditRefSide;
    to: EditRefSide;
}

type Payload = AddRefPayload;

const isFromPayload = (ref: EditRef, payload: Payload): boolean =>
    ref.from.table === payload.from.table &&
    ref.from.rows.some((id) => payload.from.rows.includes(id));

export const addRef: DiagramReducer<Payload> = (diagram, payload) => {
    // Must remove related ref(s) before adding the new one
    const refs = diagram.refs.filter((ref) => !isFromPayload(ref, payload));
    // New ref
    const prevIds = diagram.refs.map((ref) => ref.id);
    const ref: EditRef = {
        ...payload,
        id: getNumberId(prevIds),
        selected: true,
    };
    refs.push(ref);
    return { ...diagram, refs };
};
