import { TableEdges } from "canvas/edges-map";
import { EditRow, EditTable } from "diagram/model/edit/edit";

const MAX_ROWS_COUNT = 50;

// Rows are either stay on their own or grouped
export type TableGroupedRows = (EditRow | EditRow[])[];

export const groupTableRows = (
    table: EditTable,
    // Actually we only need a Set<EditRow["id"]> but this is better as we
    // already have it so no need an O(n) to get a set
    tableEdges: TableEdges | null
): [TableGroupedRows, boolean] => {
    // Rows with edges are always rendered, so each of them takes 1 slot no
    // matter where they are on the list. This needs to be calculated
    // beforehand to avoid running out of slot when there are still rows with
    // edges
    const slots = MAX_ROWS_COUNT - (tableEdges?.size ?? 0);

    if (slots >= table.value.rows.length) return [table.value.rows, false];
    if (table.rowsExpanded) return [table.value.rows, true];

    const result: TableGroupedRows = [];
    let lastGroup: EditRow[] = [];
    table.value.rows.forEach((row) => {
        if (result.length < slots || tableEdges?.has(row.id)) {
            if (lastGroup.length > 0) {
                result.push(lastGroup);
                lastGroup = [];
            }
            result.push(row);
        } else {
            lastGroup.push(row);
        }
    });
    if (lastGroup.length > 0) result.push(lastGroup);
    return [result, true];
};
