import { MenuItemProps } from "app/button/menu";
import { EditDiagram } from "diagram/model/edit/edit";
import { stringifyDiagram } from "diagram/model/stringify/stringify";
import { saveFileToHost } from "host/message/save";
import { copyToClipboard } from "utilities/clipboard";

const stringify = (diagram: EditDiagram) => {
    return stringifyDiagram(diagram, "edit", "json");
};

export const exportDiagramToClipboard = async (
    diagram: EditDiagram
): Promise<void> => {
    const text = stringify(diagram);
    const copied = await copyToClipboard(text, [
        "Please copy your diagram from the text box below:",
        `To import it, go to "Import" > "Import from text"`,
    ]);
    if (copied) {
        alert(
            [
                "Your diagram is copied to clipboard.",
                `To import it, go to "Import" > "Import from text".`,
            ].join(" ")
        );
    }
};

export const exportDiagramToFile = (
    diagram: EditDiagram,
    name?: string
): void => {
    saveFileToHost({
        format: "diagram",
        name: name ?? "diagram",
        content: stringify(diagram),
    });
};

export const getHeaderExportTextItems = (
    diagram: EditDiagram
): MenuItemProps[] => [
    { label: "Save to file", fn: () => exportDiagramToFile(diagram) },
    { label: "Copy to clipboard", fn: () => exportDiagramToClipboard(diagram) },
];
