import { host } from "host/host";
import { encodeText } from "utilities/encoding";
import { postMessageToHost } from "./message";

// FILE

export type FileFormat = "png" | "svg" | "diagram" | "sql";

interface FileFormatDetail {
    extension: string;
    isBase64: boolean;
    mime: string;
}

const fileFormatDetail: Record<FileFormat, FileFormatDetail> = {
    png: { extension: "png", isBase64: true, mime: "image/png;base64" },
    svg: { extension: "svg", isBase64: false, mime: "image/svg+xml;charset=utf-8" }, // prettier-ignore
    sql: { extension: "sql", isBase64: false, mime: "application/sql" },
    diagram: { extension: "diagram", isBase64: false, mime: "text/plain" },
};

// SAVE

export interface HostSaveOptions {
    format: FileFormat;
    content: string;
    name: string;
    preserveFormatting?: boolean;
}

const saveInWeb = (options: HostSaveOptions): void => {
    const format = fileFormatDetail[options.format];
    const link = document.createElement("a");
    link.download = `${options.name}.${format.extension}`;
    link.href = `data:${format.mime},${options.content}`;
    link.click();
};

const saveInWebPreservingFormatting = (options: HostSaveOptions): void => {
    const format = fileFormatDetail[options.format];
    const link = document.createElement("a");
    link.download = `${options.name}.${format.extension}`;
    link.href = `data:${format.mime},${encodeURIComponent(options.content)}`;
    link.click();
};

// TODO(#78): Investigate why the encoder can't encode these characters
const clean = (text: string): string => {
    let result = text;
    ["&%23xA;", "&%239;"].forEach((word) => {
        result = result.split(word).join("");
    });
    return result;
};

const saveInApp = (options: HostSaveOptions): void => {
    const format = fileFormatDetail[options.format];
    // "method" and interface of "body" are defined by TablePlus app
    postMessageToHost("saveFile", {
        data: format.isBase64
            ? options.content
            : encodeText(clean(options.content)),
        ext: format.extension,
        name: options.name,
        title: `Save ${options.name} as`,
    });
};

export const saveFileToHost = (options: HostSaveOptions): void => {
    switch (host) {
        case "web":
            if (options.preserveFormatting) {
                return saveInWebPreservingFormatting(options);
            } else {
                return saveInWeb(options);
            }
        case "mac":
        case "win":
            return saveInApp(options);
    }
};
