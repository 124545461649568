// To avoid confusion, in this file, "left" and "right" are "hands", while
// "from" and "to" are "sides"
import { roundPolylineCorner } from "geometry/corner";
import { GRID_CELL, Side as Hand } from "geometry/geometry";
import { getSegmentLength, Segment } from "geometry/line";
import { getPathPoint as p, Point } from "geometry/point";
import { EdgeEnds } from "../ends/ends";
import { EdgePathState } from "../path";
import { EdgeCross } from "./cross";
import { EdgeSplitX } from "./split-x";

/** Returns F and H when receives E and I respectively */
const getSidePoint = (point: Point, hand: Hand): Point => ({
    x: point.x - (hand === "left" ? GRID_CELL : 0),
    y: point.y,
});

/** Returns G */
const getMidPoint = (F: Point, H: Point): Point => {
    // We always pick G so that the main line goes vertically as right as
    // possible. This is because:
    // - The auto layout algorithm put "to" tables on the right side
    // - SplitX optimization shift lines to the left side
    const G: Point = { x: Math.max(F.x, H.x), y: 0 };
    G.y = G.x === F.x ? H.y : F.y;
    return G;
};

interface Props {
    edgeEnds: EdgeEnds;
    state: EdgePathState;
}

const splitX = (state: EdgePathState, S: Segment): void => {
    if (S.A.x !== S.B.x) return; // Not a vertical segment, no need to splitX
    const newX = EdgeSplitX.getOffset(state.splitX, S);
    S.A.x = newX;
    S.B.x = newX;
    EdgeSplitX.save(state.splitX, S);
};

const toLength = (S: number, P: Point, i: number, Ps: Point[]): number => {
    return i === 0 ? 0 : S + getSegmentLength(Ps[i - 1], P);
};

const mayRoundCorner = (A: Point, B: Point, C: Point): string => {
    // collinear ---> no rounding
    if (A.y === B.y && B.y === C.y) return `L ${p(B)}`;
    const { X: B1, Y: B2 } = roundPolylineCorner(A, B, C);
    return `L ${p(B1)} Q ${p(B)} ${p(B2)}`;
};

/** Returns [main, mainLength] */
export const getEdgeMain = (props: Props): [string, number] => {
    const { edgeEnds, state } = props;
    const { from, to } = edgeEnds;
    const [E, I] = [from.start, to.start];
    const [F, H] = [getSidePoint(E, from.hand), getSidePoint(I, to.hand)];
    const G: Point = getMidPoint(F, H);

    const EF: Segment = { A: E, B: F };
    const FG: Segment = { A: F, B: G };
    const GH: Segment = { A: G, B: H };
    const HI: Segment = { A: H, B: I };

    // Split X
    splitX(state, FG);
    splitX(state, GH);

    // Cross
    // Note: Becareful when allowing jump in EF and HI as they are quite short
    const cEF = EdgeCross.getPath(state.cross, EF);
    const cHI = EdgeCross.getPath(state.cross, HI);
    const cFG = EdgeCross.getPath(state.cross, FG);
    const cGH = EdgeCross.getPath(state.cross, GH);
    EdgeCross.save(state.cross, EF);
    EdgeCross.save(state.cross, FG);
    EdgeCross.save(state.cross, GH);
    EdgeCross.save(state.cross, HI);

    // Round corners
    const pF = mayRoundCorner(E, F, G);
    const pG = mayRoundCorner(F, G, H);
    const pH = mayRoundCorner(G, H, I);

    return [
        `M ${p(E)} ${cEF} ${pF} ${cFG} ${pG} ${cGH} ${pH} ${cHI} L ${p(I)}`,
        [E, F, G, H, I].reduce(toLength, 0),
    ];
};
