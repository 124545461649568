import { EditColumn, EditRow } from "diagram/model/edit/edit";
import { UpdateRowPayload } from "diagram/state/reducer/row/update";
import { DiagramProps } from "diagram/state/state";
import { RowInputBoolean } from "./boolean/boolean";
import { RowFieldString } from "./string/string";

interface Props {
    tableId: number;
    row: EditRow;
    column: EditColumn;
    diagramDispatch: DiagramProps["diagramDispatch"];
}

const updateRowField = (props: Props) => (value: string) => {
    const { tableId, row, column } = props;
    const [property, rowId] = [column.value.name, row.id];
    if (value === row.value[property]) return;
    const payload: UpdateRowPayload = { tableId, rowId, property, value };
    props.diagramDispatch({ type: "update-row", payload });
};

interface RowFieldProps {
    value: string;
    setValue: (value: string) => void;
}

type RowFieldComponent = (props: RowFieldProps) => JSX.Element;

const getField = (props: Props): RowFieldComponent => {
    const { type } = props.column.value;
    switch (type) {
        case "boolean":
            return RowInputBoolean;
        case "string":
            return RowFieldString;
        default:
            throw Error(`Unknown column type "${type}"`);
    }
};

export const RowField = (props: Props) =>
    getField(props)({
        value: props.row.value[props.column.value.name] ?? "",
        setValue: updateRowField(props),
    });
