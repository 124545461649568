import * as React from "react";

interface Props {
    children: React.ReactNode;
    href: string;
    title?: string;
}

export const FooterLink = (props: Props) => (
    <a
        target="_blank"
        rel="noopener noreferrer"
        href={props.href}
        children={props.children}
        title={props.title}
    />
);
