import { DiagramElements } from "canvas/elements";
import { AddTablePayload } from "diagram/state/reducer/table/add/add";
import { DiagramProps } from "diagram/state/state";
import { getNumberId } from "utilities/id";
import { scrollTableIntoView } from "../search/search";
import { Icons } from "utilities/icon";
import { Button } from "app/button/button";

interface Props extends DiagramProps {
    elements: DiagramElements;
}

export const HeaderTable = (props: Props) => {
    const onClick = () => {
        // Intentionally create a valid ID ourselves so we can scroll to it
        // after the creation
        const id = getNumberId(props.diagram.tables.map((t) => t.id));
        const payload: AddTablePayload = { id, position: null };
        props.diagramDispatch({ type: "add-table", payload });
        window.setTimeout(() => {
            scrollTableIntoView(props.elements, id);
        }, 0); // Run after React's render new table
    };
    return (
        <Button
            onClick={onClick}
            icon={Icons.plus}
            children="Table"
            color="solid"
        />
    );
};
