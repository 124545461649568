import { DiagramElements } from "canvas/elements";
import { EditEdge, EditEdgeSide } from "diagram/model/edit/edit";
import { roundPolylineCorner } from "geometry/corner";
// To avoid confusion, in this file, "left" and "right" are "hands", while
// "from" and "to" are "sides"
import { getPathPoint as p, GRID_CELL, Side as Hand } from "geometry/geometry";
import { Point } from "geometry/point";
import { EdgePathState } from "../path";
import { EdgeSplitY } from "./split-y";

/**
 * The middle points of left and right edge of a Row. They are both candidates
 * from which an Edge connects to that Row. One of them will be used.
 */
interface RowEnds {
    left: Point;
    right: Point;
}

// @TODO: Extract this
export const getCanvasScale = (canvas: HTMLElement): number => {
    const transform = canvas.style.transform;
    if (!transform) return 1;
    return parseFloat(transform.replace("scale(", "").replace(")", ""));
};

/**
 * Return RowEnds of all rows on one side of an Edge
 */
const getSideRowEnds = (props: Props, sideText: "from" | "to"): RowEnds[] => {
    const side: EditEdgeSide = props.edge[sideText];

    // Offset of Canvas because all positions are absolute to Canvas
    const { canvas } = props.elements;
    if (canvas === null) throw Error("Canvas is null");
    // All calculation should based on 1x scale
    const scale = getCanvasScale(canvas);
    const canvasRect = canvas.getBoundingClientRect();

    const table = props.elements.tables.get(side.table);
    if (table === undefined) throw Error("Table is undefined");

    return side.rows.map((id) => {
        const row = table.rows.get(id)?.element ?? undefined;
        if (row === undefined) throw Error("Row is undefined");
        const rowRect = row.getBoundingClientRect();
        let top = (rowRect.top + rowRect.height / 2 - canvasRect.top) / scale;
        // Apply optimizations
        if (sideText === "to") {
            const state = props.state.splitY;
            top = top + EdgeSplitY.getOffset(state, side.table, id);
        }
        const ends: RowEnds = {
            left: { x: (rowRect.left - canvasRect.left) / scale, y: top },
            right: { x: (rowRect.right - canvasRect.left) / scale, y: top },
        };
        // Record optimizations
        if (sideText === "to") {
            EdgeSplitY.save(props.state.splitY, side.table, id);
        }
        return ends;
    });
};

const SAFE_DISTANCE = GRID_CELL * 2;

interface SideEndPoints {
    points: Point[];
    hand: Hand;
}

interface EdgeEndPoints {
    from: SideEndPoints;
    to: SideEndPoints;
}

/** Choose which sides of both tables to connect to. Returns A, B, M, L */
const getEdgeEndPoints = (froms: RowEnds[], tos: RowEnds[]): EdgeEndPoints => {
    // All rows share same "left" and "right" points horizontally
    const [from, to] = [froms[0], tos[0]];
    let fromHand: Hand, toHand: Hand;
    if (from.right.x <= to.left.x - SAFE_DISTANCE) {
        [fromHand, toHand] = ["right", "left"];
    } else if (from.left.x >= to.right.x + SAFE_DISTANCE) {
        [fromHand, toHand] = ["left", "right"];
    } else {
        [fromHand, toHand] = ["right", "right"];
    }
    return {
        from: { hand: fromHand, points: froms.map((row) => row[fromHand]) },
        to: { hand: toHand, points: tos.map((row) => row[toHand]) },
    };
};

interface SideEndPaths {
    paths: string[]; // ECA, EDB or IJM, IKL
    start: Point; // E or I
    hand: Hand;
}

const getSideEndPaths = (props: SideEndPoints): SideEndPaths => {
    const { points, hand } = props;
    const E: Point = { x: 0, y: 0 }; // common/start point of all paths
    E.x = points[0].x + (hand === "right" ? 1 : -1) * GRID_CELL;
    E.y = points.reduce((s, p) => s + p.y, 0) / points.length;
    const paths: string[] = points.map((A): string => {
        const C: Point = { x: E.x, y: A.y };
        const { X: C1, Y: C2 } = roundPolylineCorner(E, C, A);
        return `M ${p(E)} L ${p(C1)} Q ${p(C)} ${p(C2)} L ${p(A)}`;
    });
    return { paths, start: E, hand };
};

export interface EdgeEnds {
    from: SideEndPaths;
    to: SideEndPaths;
}

interface Props {
    state: EdgePathState;
    elements: DiagramElements;
    edge: EditEdge;
}

export const getEdgeEnds = (props: Props): EdgeEnds => {
    const froms = getSideRowEnds(props, "from");
    const tos = getSideRowEnds(props, "to");
    const { from, to } = getEdgeEndPoints(froms, tos);
    return { from: getSideEndPaths(from), to: getSideEndPaths(to) };
};
