import { Icons } from "utilities/icon";
import { DiagramElements } from "canvas/elements";
import { AutoArrangePayload } from "diagram/state/reducer/arrange/auto";
import { ExpandAllTablesRowsPayload } from "diagram/state/reducer/table/rows/all";
import { DiagramProps } from "diagram/state/state";
import { ButtonGroup } from "app/button/group";
import { Button } from "app/button/button";
import { ButtonMenu } from "app/button/menu";

interface Props extends DiagramProps {
    elements: DiagramElements;
}

const arrange = (props: Props) => {
    const payload: AutoArrangePayload = { elements: props.elements };
    props.diagramDispatch({ type: "auto-arrange", payload });
};

const expandAndArrange = (props: Props, expanded: boolean): void => {
    const payload: ExpandAllTablesRowsPayload = { expanded };
    props.diagramDispatch({ type: "expand-all-tables-rows", payload });
    // Wait for React to re-render tables
    window.setTimeout(() => void arrange(props), 0);
};

export const HeaderArrange = (props: Props) => (
    <div className={["border border-[var(--slate7)] rounded"].join(" ")}>
        <ButtonGroup>
            {[
                <Button
                    key="arrange"
                    icon={Icons.layoutAuto}
                    onClick={() => arrange(props)}
                    children="Arrange"
                    textColor="text-[var(--slate12)]"
                    color="clear"
                />,
                <ButtonMenu
                    key="more"
                    button={{
                        icon: Icons.caretDown,
                        color: "clear",
                    }}
                    items={[
                        {
                            label: "Expand all columns and Arrange",
                            fn: () => expandAndArrange(props, true),
                        },
                        {
                            label: "Collapse columns and Arrange",
                            fn: () => expandAndArrange(props, false),
                        },
                    ]}
                />,
            ]}
        </ButtonGroup>
    </div>
);
