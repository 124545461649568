/**
 * This module makes an Edge "jumps" over another when it crosses. In the
 * figure below, edge CD crosses edge AB at X. If CD is drawn later than AB
 * then a "jump" happen at X on CD:
 * A >----+
 *        |
 * C >----X-----> D
 *        |
 *        +-----> B
 */

import {
    getSegmentIntersection,
    getSegmentLength,
    Segment,
    splitSegment,
} from "geometry/line";
import { getPathPoint as p } from "geometry/point";

interface State {
    segments: Segment[];
}

export type EdgeCrossState = State;

const R = 2;

/** Return cross points on S against "segments" */
const getPath = (state: State, S: Segment): string => {
    // Distance: from the intersection to the begin of the segment (PA). This
    // is needed to sort the paths after the loop because the order of
    // state.segments is not the same as the order of those intersections on
    // the final segment.
    const paths: { distance: number; d: string }[] = [];
    // Avoid drawing multiple arcs over one point. This happens when a segment
    // cross the common point of 2 segments. This is distance-based
    const seen: Set<number> = new Set();
    state.segments.forEach((T) => {
        const P = getSegmentIntersection(S, T);
        if (P === null) return;
        // Note that technically the first argument of the split is not "S.A"
        // but the previous point. However it makes no difference as they are
        // all collinear with each other
        const split = splitSegment(S.A, P, R * 2);
        if (split === null) return;
        const { X: P1, Y: P2 } = split;
        const distance = getSegmentLength(P, S.A);
        if (seen.has(distance)) return;
        seen.add(distance);
        paths.push({ distance, d: `L ${p(P1)} A ${R} ${R} 0 0 1 ${p(P2)}` });
    });
    return paths
        .sort((a, b) => a.distance - b.distance)
        .map((path) => path.d)
        .join(" ");
};

const save = (state: State, S: Segment): void => {
    state.segments.push(S);
};

const init = (): State => ({ segments: [] });

export const EdgeCross = {
    init,
    getPath,
    save,
};
