import { AppCanvasEmpty } from "app/canvas/empty/empty";
import { AppCanvasProgress } from "app/canvas/progress/progress";
import { Footer } from "app/footer/footer";
import { ProgressCircle } from "app/progress/circle";
import { Backend } from "backend/backend";
import { Border } from "border/border";
import { CanvasInactive } from "canvas/canvas/inactive/inactive";
import { DiagramElementsUtils } from "canvas/elements";
import { isAppHost } from "host/host";
import { LibraryItem } from "library/library";
import { PreferencesContext } from "preferences/preferences";
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { SharedHeader } from "../header/header";

const initialElements = DiagramElementsUtils.newDiagram();

export const SharedCanvas = () => {
    const { uniqueToken } = useParams();
    const prefs = useContext(PreferencesContext).preferences;
    const [sharedDiagram, setSharedDiagram] =
        React.useState<LibraryItem | null>();

    const elementsRef = React.useRef(initialElements);
    const elements = elementsRef.current;

    useEffect(() => {
        const fetchSharedDiagram = async (uniqueToken: string) => {
            const sharedDiagram = await Backend.getSharedDiagram(uniqueToken);
            return sharedDiagram;
        };

        if (uniqueToken) {
            fetchSharedDiagram(uniqueToken).then((sharedDiagram) => {
                setSharedDiagram(sharedDiagram);
            });
        }
    }, [uniqueToken]);

    const renderCanvas = !sharedDiagram ? (
        <div className={["flex-0 m-auto"].join(" ")}>
            {ProgressCircle({ size: 32 })}
        </div>
    ) : (
        <div className={"w-auto h-auto flex-1 overflow-scroll relative"}>
            <CanvasInactive
                diagram={sharedDiagram.diagram}
                elements={elements}
            />
            {/* Note that Empty and Progress are just an overlays, not prevent
            the Canvas from rendering. This is expected because the Diagram
            technically still exists and its DOM should be accessible */}
            {sharedDiagram === null && <AppCanvasEmpty />}
            <AppCanvasProgress />
        </div>
    );

    const app = (
        <div
            className={["h-screen flex flex-col", "bg-[var(--slate1)]"].join(
                " "
            )}
        >
            {sharedDiagram && (
                <div className={"flex-0"}>
                    <SharedHeader item={sharedDiagram} />
                </div>
            )}
            <Border color="strong" />
            <div className={["flex-1 overflow-hidden flex"].join(" ")}>
                {renderCanvas}
            </div>
            {!isAppHost && prefs.footerVisible && (
                <>
                    <Border color="strong" />
                    <div className={"flex-0"} children={<Footer />} />
                </>
            )}
        </div>
    );
    return app;
};
