import { DiagramElements } from "canvas/elements";
import { SelectSomeTablesPayload } from "diagram/state/reducer/table/select/some";
import { DiagramProps } from "diagram/state/state";
import * as React from "react";
import { Input } from "input/input";

interface Props extends DiagramProps {
    elements: DiagramElements;
}

export const scrollTableIntoView = (
    elements: DiagramElements,
    id: number
): void => {
    const element = elements.tables.get(id);
    if (element === undefined) throw Error(`Table ${id} is undefined`);
    element.element?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
    });
};

const focusTable = (props: Props, id: number) => {
    // Select
    const payload: SelectSomeTablesPayload = {
        tableIds: new Set([id]),
        append: false,
    };
    props.diagramDispatch({ type: "select-some-tables", payload });
    scrollTableIntoView(props.elements, id);
};

export const HeaderSearch = (props: Props) => {
    const [value, setValue] = React.useState("");

    // Table's name to id
    const [names, ids]: [Set<string>, Map<string, number>] =
        React.useMemo(() => {
            const ids = new Map<string, number>();
            const names: Set<string> = new Set();
            props.diagram.tables.forEach((table) => {
                names.add(table.value.name);
                ids.set(table.value.name, table.id);
            });
            return [names, ids];
        }, [props.diagram.tables]);

    const tryFocus = (name: string) => {
        const id = ids.get(name);
        if (id !== undefined) focusTable(props, id);
    };

    return (
        <div className="flex-none flex items-center p-2 w-full">
            <div className="p-1 bg-[var(--slate1)] w-full rounded">
                <datalist id="header-search">
                    {Array.from(names).map((name) => {
                        return <option key={name} value={name} />;
                    })}
                </datalist>
                <Input
                    placeholder="Find table…"
                    value={value}
                    onValueChange={(newValue) => {
                        setValue(newValue);
                        tryFocus(newValue);
                    }}
                    list="header-search"
                    onKeyUp={(event) => {
                        if (event.key === "Enter") tryFocus(value);
                    }}
                />
            </div>
        </div>
    );
};
