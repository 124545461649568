import { Button } from "app/button/button";
import { isAppHost } from "host/host";
import * as React from "react";
import { Report } from "report/report";

interface Props {
    children: React.ReactChild;
}

interface State {
    hasError: boolean;
    error: any;
}

const Description = () => (
    <>
        <p className="leading-normal">
            Please tell us about this problem. We will treat your report as
            confidential and anonymous.
        </p>
        <div className="h-3 w-3" />
        <p>
            To help us diagnose the problem, please describe what you were doing
            when this error occured:
        </p>
        <div className="h-3 w-3" />
    </>
);

const Footer = () => {
    if (isAppHost) return null;
    const reload = () => window.location.reload();
    return <Button color="solid" onClick={reload} children="Reload" />;
};

export class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    static getDerivedStateFromError(error: any) {
        return { hasError: true, error };
    }

    render() {
        if (this.state.hasError)
            return (
                <Report
                    type="uncaught"
                    title="There was an unexpected error"
                    description={<Description />}
                    footer={<Footer />}
                    error={this.state.error}
                />
            );
        return this.props.children;
    }
}
