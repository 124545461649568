import { Icons } from "utilities/icon";
import { EditDiagram } from "diagram/model/edit/edit";
import { getHeaderExportImageItems } from "./image/image";
import { getHeaderExportTextItems } from "./text";
import { ButtonMenu } from "app/button/menu";
import { useContext, useState } from "react";
import CodeMirror from "@uiw/react-codemirror";
import "./export.css";
import { sql } from "@codemirror/lang-sql";
import { Dialog } from "app/dialog/dialog";
import { Button } from "app/button/button";
import { makeBaseDiagramFromEdit } from "diagram/model/stringify/base";
import { toast } from "react-hot-toast";
import { Exporter } from "exporter/exporter";
import { isAppHost } from "host/host";
import { saveFileToHost } from "host/message/save";
import * as HostSql from "host/message/sql";
import { MenuItemProps } from "app/button/menu";
import { PreferencesContext } from "preferences/preferences";

type Get = (diagram: EditDiagram) => MenuItemProps[];

interface Props {
    diagram: EditDiagram;
}

export const HeaderExport = (props: Props) => {
    const { diagram } = props;
    const [exportDialogOpen, setExportDialogOpen] = useState(false);
    const [exportedSQL, setExportedSQL] = useState("");

    const preferencesContext = useContext(PreferencesContext);
    const { preferences } = preferencesContext;

    const theme = preferences.theme === "dark-theme" ? "dark" : "light";

    const appItems: Get = (diagram) =>
        HostSql.HostSqlDrivers.map((driver) => ({
            label: `Export to ${driver}`,
            fn: () => {
                const data = makeBaseDiagramFromEdit(diagram);
                HostSql.exportSqlToHost({ data, driver });
            },
        }));

    const webItems: Get = (diagram) => [
        {
            label: "Export to SQL...",
            items: [
                {
                    label: "Export to PostgreSQL",
                    fn: () => {
                        setExportDialogOpen(true);
                        toast.success("PostgreSQL export successful.");
                        const generatedSQL =
                            Exporter.exportToPostgreSQL(diagram);
                        setExportDialogOpen(true);
                        setExportedSQL(generatedSQL);
                    },
                },
                {
                    label: "Export to MySQL",
                    fn: () => {
                        toast.success("MySQL export successful.");
                        const generatedSQL = Exporter.exportToMySQL(diagram);
                        setExportDialogOpen(true);
                        setExportedSQL(generatedSQL);
                    },
                },
                {
                    label: "Export to SQL Server",
                    fn: () => {
                        toast.success("SQL Server export successful.");
                        const generatedSQL =
                            Exporter.exportToSQLServer(diagram);
                        setExportDialogOpen(true);
                        setExportedSQL(generatedSQL);
                    },
                },
            ],
        },
    ];

    const getHeaderExportSqlItems: Get = (diagram) => {
        return isAppHost ? appItems(diagram) : webItems(diagram);
    };

    return (
        <>
            <ButtonMenu
                items={[
                    ...getHeaderExportTextItems(diagram),
                    "divider",
                    ...getHeaderExportImageItems(diagram),
                    "divider",
                    ...getHeaderExportSqlItems(diagram),
                ]}
                button={{
                    icon: Icons.export,
                    color: "solid",
                    children: "Export",
                }}
            />
            <Dialog.Root open={exportDialogOpen}>
                <Dialog.Content
                    onClickOverlay={() => {
                        setExportDialogOpen(false);
                        setExportedSQL("");
                    }}
                    extraStyles={[
                        "w-fit flex flex-col items-center justify-center h-[75vh]",
                        "resize overflow-hidden min-h-[50vh] min-w-[75vw]",
                        "max-h-[90vh] max-w-[90vw]",
                    ].join(" ")}
                >
                    <>
                        <div className={["p-4 text-center flex-0"].join(" ")}>
                            <Dialog.Description asChild>
                                <p>
                                    Previewing your exported SQL. Press the{" "}
                                    <span className="font-medium">
                                        Download
                                    </span>{" "}
                                    button to save it as an SQL file.
                                </p>
                            </Dialog.Description>
                        </div>

                        <div
                            className={[
                                "p-4 flex-row flex-1 flex min-w-0 min-h-0 w-full",
                            ].join(" ")}
                        >
                            <CodeMirror
                                id={"exported"}
                                className="flex flex-1"
                                extensions={[sql()]}
                                basicSetup={{
                                    lineNumbers: true,
                                    foldGutter: false,
                                    autocompletion: false,
                                }}
                                theme={theme}
                                value={exportedSQL}
                                onChange={setExportedSQL}
                                width="100%"
                                height="100%"
                            />
                        </div>

                        <div
                            className={[
                                "flex flex-0 justify-center gap-2 p-4",
                            ].join(" ")}
                        >
                            <Dialog.Close asChild>
                                <Button
                                    extraStyle="w-[96px]"
                                    color="gray"
                                    children="Cancel"
                                    onClick={() => {
                                        setExportDialogOpen(false);
                                        setExportedSQL("");
                                    }}
                                />
                            </Dialog.Close>
                            <Dialog.Close asChild>
                                <Button
                                    extraStyle="w-[96px]"
                                    color="blue"
                                    children="Download"
                                    onClick={() => {
                                        saveFileToHost({
                                            format: "sql",
                                            name: "exported_diagram",
                                            content: exportedSQL,
                                            preserveFormatting: true,
                                        });
                                        setExportDialogOpen(false);
                                        setExportedSQL("");
                                    }}
                                />
                            </Dialog.Close>
                        </div>
                    </>
                </Dialog.Content>
            </Dialog.Root>
        </>
    );
};
