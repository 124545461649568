export const AppCanvasEmpty = () => (
    <div
        className={[
            "absolute top-0 left-0 w-full h-full z-[1]",
            "flex items-center justify-center text-center",
            "bg-[var(--slate1)]",
        ].join(" ")}
    >
        <div>
            <p className="leading-1">
                <span className="font-bold">No diagram is opened</span>
            </p>
            <div className="w-2 h-2" />
            <p className="leading-1">
                You can open diagrams or create new ones
                <br />
                from the Library pane on the left
            </p>
        </div>
    </div>
);
