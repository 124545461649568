import { DiagramReducer } from "../reducer";
import { removeSelectedItems } from "./remove";

export type DiagramSelectActions = { type: "remove-selected-items" };

export const diagramSelectReducer: DiagramReducer = (diagram, action) => {
    switch (action.type) {
        case "remove-selected-items":
            return removeSelectedItems(diagram, undefined);
        default:
            return diagram;
    }
};
