import { EditDiagram, EditEdgeSide } from "diagram/model/edit/edit";

/**
 * This is not the same as EditRefSide. Although having the same properties,
 * this one uses "name" (string) (technically the first column) while
 * EditRefSide uses "id". This will be converted to EditRefSide on submit.
 */
interface Side {
    table: string;
    rows: string[];
}

export type CreateEdgeSide = Side;

const makeCreate = (diagram: EditDiagram, side: EditEdgeSide): Side => {
    const table = diagram.tables.find((t) => t.id === side.table);
    if (table === undefined) throw Error("Table is undefined");
    const rows: string[] = side.rows.map((id) => {
        const row = table.value.rows.find((r) => r.id === id);
        if (row === undefined) throw Error("Row is undefined");
        const name = row.value[diagram.columns[0].value.name];
        if (name === undefined) throw Error("Name is undefined");
        return name;
    });
    return { table: table.value.name, rows };
};

const makeEdit = (diagram: EditDiagram, state: Side): EditEdgeSide => {
    const table = diagram.tables.find((t) => t.value.name === state.table);
    if (table === undefined) throw Error("Table is undefined");
    const nameKey = diagram.columns[0].value.name;
    const rows: number[] = state.rows.map((name) => {
        const row = table.value.rows.find((r) => r.value[nameKey] === name);
        if (row === undefined) throw Error("Row is undefined");
        return row.id;
    });
    return { table: table.id, rows };
};

const makeEmpty = (): CreateEdgeSide => ({
    table: "",
    rows: [""],
});

export const CreateEdgeSideUtils = {
    makeEdit,
    makeCreate,
    makeEmpty,
};
