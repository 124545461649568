import { Button } from "app/button/button";
import { Dialog } from "app/dialog/dialog";
import { ProgressCircle } from "app/progress/circle";
import { Backend } from "backend/backend";
import { Input } from "input/input";
import { LibraryItem, LibraryProps } from "library/library";
import { LibraryReducer } from "library/reducer";
import { useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { Icons } from "utilities/icon";

interface Props {
    diagram: LibraryItem;
    setLibrary: LibraryProps["setLibrary"];
    onDone?: () => void;
    open?: boolean;
    setOpen?: (open: boolean) => void;
}

export const SharedDialog = (props: Props) => {
    const [isLoadingShare, setIsLoadingShare] = useState(false);
    const [sharedURL, setSharedURL] = useState("");
    const { diagram, setLibrary, onDone, open, setOpen } = props;
    const [isSharing, setIsSharing] = useState(diagram.shared);

    const loadSharedURL = useCallback(async (): Promise<string> => {
        const sharedURL = await Backend.getSharedDiagramURL(diagram);
        return sharedURL;
    }, [diagram]);

    const loadShare = async (diagram: LibraryItem): Promise<string> => {
        const sharedURL = await Backend.shareDiagram(diagram);
        return sharedURL;
    };

    const loadUnshare = async (diagram: LibraryItem): Promise<void> => {
        await Backend.unshareDiagram(diagram);
    };

    useEffect(() => {
        if (diagram.shared) {
            loadSharedURL()
                .then((url) => setSharedURL(url))
                .catch(() => {
                    onDone?.();
                });
        }
    }, [diagram.shared, loadSharedURL, onDone]);

    const shareDiagram = () => {
        setIsLoadingShare(true);
        loadShare(diagram)
            .then((sharedURL) => {
                setIsLoadingShare(false);
                setSharedURL(sharedURL);
                setLibrary(LibraryReducer.share(diagram.id));
            })
            .catch(() => {
                props.onDone?.();
            });
    };

    const unshareDiagram = () => {
        setIsLoadingShare(true);
        loadUnshare(diagram)
            .then(() => {
                setIsLoadingShare(false);
                setSharedURL("");
                setLibrary(LibraryReducer.unshare(diagram.id));
            })
            .catch(() => {
                props.onDone?.();
            });
    };

    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!isLoadingShare || !isSharing) {
            return;
        }
        window.setTimeout(() => {
            ref.current?.focus();
        }, 100);
    }, [isLoadingShare, isSharing]);

    const toggleShareButton = isSharing ? (
        <Button
            color="gray"
            children="Unshare"
            onClick={(e) => {
                e.preventDefault();
                unshareDiagram();
                setIsSharing(false);
            }}
        />
    ) : (
        <Button
            color="blue"
            children="Share"
            onClick={(e) => {
                e.preventDefault();
                shareDiagram();
                setIsSharing(true);
            }}
        />
    );

    return (
        <Dialog.Root open={open} onOpenChange={setOpen}>
            <Dialog.Content
                extraStyles={[
                    "w-fit lg:min-w-[620px] md:min-w-[300px] sm:min-w-[300px]",
                ].join(" ")}
                onClickOverlay={() => {
                    props.onDone?.();
                }}
            >
                <div className="p-4 text-center">
                    <Dialog.Title>Share diagram</Dialog.Title>
                </div>
                <div className="p-4 mx-auto">
                    <p>
                        Press <span className="font-bold">Share</span> to
                        generate a link to your diagram.
                        <br />
                        Anyone with the link{" "}
                        <span className="font-bold">can view</span> the diagram.
                    </p>
                    <div className="h-4 w-4" />
                    {isLoadingShare ? (
                        <div className={["flex items-center"].join(" ")}>
                            <div className={["flex-0 m-auto block"].join(" ")}>
                                <ProgressCircle size={32} />
                            </div>
                        </div>
                    ) : (
                        isSharing && (
                            <div
                                className={
                                    "flex flex-row justify-between text-center font-mono"
                                }
                            >
                                <Input
                                    ref={ref}
                                    value={sharedURL}
                                    onValueChange={setSharedURL}
                                    onFocus={(e) => e.currentTarget.select()}
                                />
                                <div className={"flex-0 mx-[5px]"}>
                                    <Button
                                        icon={Icons.copy}
                                        color="solid"
                                        onClick={() => {
                                            toast.success(
                                                "Copied to clipboard"
                                            );
                                            ref.current?.select();
                                            navigator.clipboard.writeText(
                                                sharedURL
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        )
                    )}
                    <div className="h-4 w-4" />
                    <div className="py-4 flex justify-end gap-2 items-centeend">
                        {toggleShareButton}
                        <Dialog.Close>
                            <Button
                                color="blue"
                                children="Done"
                                onClick={() => props.onDone?.()}
                            />
                        </Dialog.Close>
                    </div>
                </div>
            </Dialog.Content>
        </Dialog.Root>
    );
};
