import { Checkbox } from "checkbox/checkbox";
import { PreferencesContext } from "preferences/preferences";
import * as React from "react";

export const HeaderAnimation = () => {
    const context = React.useContext(PreferencesContext);
    const { preferences: prefs, setPreferences: setPrefs } = context;
    return (
        <Checkbox
            checked={prefs.edgeAnimation}
            onCheckedChange={(value) => {
                const checked = value === true;
                setPrefs((prev) => ({ ...prev, edgeAnimation: checked }));
            }}
            children="Show animation on highlighted arrows"
        />
    );
};
