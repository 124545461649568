import { EditSelectable } from "diagram/model/edit/edit";
import { DiagramReducer } from "../../reducer";

export interface SelectAllTablesPayload {
    selected: boolean;
}

type Reducer = DiagramReducer<SelectAllTablesPayload>;

// "select" is a very common action so we try to not return new state if
// nothing was actually changed
interface Flags {
    changed: boolean;
}

export const setEditSelected = (flags: Flags, selected: boolean) => {
    return <T extends EditSelectable>(item: T): T => {
        if (item.selected === selected) return item;
        flags.changed = true;
        return { ...item, selected };
    };
};

export const selectAllTables: Reducer = (diagram, payload) => {
    const flags = { changed: false };
    const setSelected = setEditSelected(flags, payload.selected);
    const tables = diagram.tables.map(setSelected);
    const refs = diagram.refs.map(setSelected);
    if (flags.changed === false) return diagram;
    return { ...diagram, tables, refs };
};
