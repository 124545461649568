import * as Edit from "../edit/edit";
import * as Base from "../base/base";

// Prepared maps from EDIT ids to [name, schema] to speed up EditRef conversion
type Ids = {
    table: { name: string; schema: string | undefined };
    rows: Map<number, string>;
};
type IdsMap = Map<number, Ids>;

const makeIdsMap = (tables: Edit.EditTable[]): IdsMap => {
    const idsMap = new Map<number, Ids>();
    tables.forEach((table) => {
        const rows = new Map<number, string>();
        table.value.rows.forEach((row) => void rows.set(row.id, row.value.id));
        const idsTable = { name: table.value.name, schema: table.value.schema };
        idsMap.set(table.id, { table: idsTable, rows });
    });
    return idsMap;
};

const makeRefSide = (map: IdsMap, side: Edit.EditRefSide): Base.BaseRefSide => {
    const ids = map.get(side.table);
    if (!ids) throw Error(`Table Ids "${side.table}" not found`);
    const rows = side.rows.map((editId) => {
        const realId = ids.rows.get(editId);
        if (!realId) throw Error(`Row id "${editId}" not found`);
        return realId;
    });
    const { name, schema } = ids.table;
    return { name, schema, rows };
};

export const makeBaseDiagramFromEdit = (
    edit: Edit.EditDiagram
): Base.BaseDiagram => ({
    type: "base",
    version: 3,
    columns: edit.columns.map((column) => column.value),
    items: edit.tables.map((table) => ({
        schema: table.value.schema,
        name: table.value.name,
        id: table.value.id,
        rows: table.value.rows.map((row) => {
            const isPrimaryString = row.isPrimary ? "true" : "false";
            const { id, ...value } = row.value;
            return { ...value, isPrimary: isPrimaryString, id };
        }),
    })),
    refs: (() => {
        const idsMap = makeIdsMap(edit.tables);
        return edit.refs.map((ref) => ({
            from: makeRefSide(idsMap, ref.from),
            to: makeRefSide(idsMap, ref.to),
        }));
    })(),
    schema: edit.schema,
});
