import { Icons } from "utilities/icon";
import { DiagramElementsUtils, FieldName } from "canvas/elements";
import { Button } from "app/button/button";

interface Props {
    tableId: number;
    rowId: number;
    removeRow: (name: FieldName) => void;
}

export const RowRemoveButton = (props: Props) => (
    <div
        className={["animate-left-slide-in [&>button]:transition-none"].join(
            " "
        )}
    >
        <Button
            onClick={() => {
                props.removeRow(DiagramElementsUtils.removeFieldName);
            }}
            color="clear"
            icon={Icons.remove}
            iconSize={12}
            extraStyle="m-1 text-[var(--red10)]"
        />
    </div>
);
