import { EditRef, EditTable } from "diagram/model/edit/edit";
import { DiagramReducer } from "../../reducer";
import { selectAllTables, setEditSelected } from "./all";

export interface SelectSomeTablesPayload {
    tableIds: Set<number>;
    append: boolean;
}

type Reducer = DiagramReducer<SelectSomeTablesPayload>;

export const selectSomeTables: Reducer = (prev, payload) => {
    const diagram = payload.append
        ? prev
        : selectAllTables(prev, { selected: false });
    // Separate select Table and select Refs functions to save some performance
    // because there's a high chance that "refs" is not changed at all (when a
    // table without ref is selected)
    const tablesFlags = { changed: false };
    const setTableSelected = setEditSelected(tablesFlags, true);
    const tables: EditTable[] = diagram.tables.map((table) => {
        const matched = payload.tableIds.has(table.id);
        return matched ? setTableSelected(table) : table;
    });
    const refsFlags = { changed: false };
    const setRefSelected = setEditSelected(refsFlags, true);
    const refs: EditRef[] = diagram.refs.map((ref) => {
        const matched =
            payload.tableIds.has(ref.from.table) ||
            payload.tableIds.has(ref.to.table);
        return matched ? setRefSelected(ref) : ref;
    });
    if (!tablesFlags.changed && !refsFlags.changed) return diagram;
    return {
        ...diagram,
        tables: tablesFlags.changed ? tables : diagram.tables,
        refs: refsFlags.changed ? refs : diagram.refs,
    };
};
