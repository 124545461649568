import { Button } from "app/button/button";
import { Dialog } from "./dialog";
import { renderDialog } from "./native";

export const dialogConfirm = (message: React.ReactNode): Promise<boolean> => {
    return new Promise((resolve) => {
        renderDialog((unmount) => (
            <Dialog.Root open={true}>
                <Dialog.Content
                    onClickOverlay={() => {
                        resolve(false);
                        unmount();
                    }}
                >
                    <>
                        <div className={["p-4"].join(" ")}>
                            <Dialog.Description asChild>
                                <p>{message}</p>
                            </Dialog.Description>
                        </div>

                        <div
                            className={["flex justify-center gap-2 p-4"].join(
                                " "
                            )}
                        >
                            <Dialog.Close asChild>
                                <Button
                                    extraStyle="w-[96px]"
                                    color="gray"
                                    children="Cancel"
                                    onClick={() => {
                                        resolve(false);
                                        unmount();
                                    }}
                                />
                            </Dialog.Close>
                            <Dialog.Close asChild>
                                <Button
                                    extraStyle="w-[96px]"
                                    color="blue"
                                    children="OK"
                                    onClick={() => {
                                        resolve(true);
                                        unmount();
                                    }}
                                />
                            </Dialog.Close>
                        </div>
                    </>
                </Dialog.Content>
            </Dialog.Root>
        ));
    });
};
