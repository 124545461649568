import {
    IconName,
    iconNameToPathsRecordKey,
    IconSvgPaths16,
} from "@blueprintjs/icons";
import { IconType } from "react-icons/lib";

export type IconComponent = IconType;

/**
 * Wrap a Blueprint's icon in a format that is compatible with react-icons.
 * This is a simplified version of [the implementation][base] of react-icons.
 *
 * [base]: https://github.com/react-icons/react-icons/blob/master/packages/react-icons/src/iconBase.tsx
 *
 * See also:
 * - https://github.com/TablePlus/diagram/issues/89
 */
const makeIcon = (name: IconName): IconComponent => {
    const paths = IconSvgPaths16[iconNameToPathsRecordKey(name)].map(
        (d, index) => <path key={index} d={d} />
    );
    return (props) => {
        const { name, size, title, ...svgProps } = props;
        const computedSize = size || "1em";
        return (
            <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                {...svgProps}
                style={{ color: props.color, ...props.style }}
                height={computedSize}
                width={computedSize}
                xmlns="http://www.w3.org/2000/svg"
                // Blueprint viewBox
                viewBox="0 0 16 16"
            >
                {title && <title>{title}</title>}
                {paths}
            </svg>
        );
    };
};

/**
 * Blueprint's icons to be used. These icons are wrapped to be used with Diagram's
 * components (i.e. in a format that is compatible with react-icons). See the
 * "makeIcon" for more details.
 *
 * For performance reason, these icons are defined at load time instead of
 * creating on demand at render time.
 */
export const Icons = {
    add: makeIcon("add"),
    caretDown: makeIcon("caret-down"),
    chevronUp: makeIcon("chevron-up"),
    chevronDown: makeIcon("chevron-down"),
    chevronRight: makeIcon("chevron-right"),
    circle: makeIcon("circle"),
    close: makeIcon("cross"),
    check: makeIcon("tick"),
    copy: makeIcon("clipboard"),
    doubleChevronLeft: makeIcon("double-chevron-left"),
    doubleChevronRight: makeIcon("double-chevron-right"),
    documentShare: makeIcon("document-share"),
    edit: makeIcon("edit"),
    export: makeIcon("export"),
    flash: makeIcon("flash"),
    home: makeIcon("home"),
    import: makeIcon("import"),
    issue: makeIcon("issue"),
    layoutAuto: makeIcon("layout-auto"),
    locate: makeIcon("locate"),
    login: makeIcon("log-in"),
    logout: makeIcon("log-out"),
    menuOpen: makeIcon("menu-open"),
    moon: makeIcon("moon"),
    more: makeIcon("more"),
    plus: makeIcon("plus"),
    person: makeIcon("person"),
    people: makeIcon("people"),
    redo: makeIcon("redo"),
    remove: makeIcon("remove"),
    search: makeIcon("search"),
    selection: makeIcon("selection"),
    share: makeIcon("share"),
    undo: makeIcon("undo"),
    zoomIn: makeIcon("zoom-in"),
    zoomOut: makeIcon("zoom-out"),
};

interface Props {
    icon: IconComponent;
    size?: number;
    className?: string;
}

export type { Props as IconProps };

export const Icon = (props: Props) => {
    const { size, icon, className } = props;

    return icon({
        // react-icons support "size" option but avoid that because we want to
        // support all generic components
        style: { width: size ?? 16, height: size ?? 16 },
        className: `${className ?? ""}`,
    });
};
