import { Icons } from "utilities/icon";
import { DiagramElements } from "canvas/elements";
import { isJustLeftEdit } from "canvas/row/active/field/string/leave";
import { useReorderDrop } from "canvas/row/reorder/reorder";
import { EditColumn, EditTable } from "diagram/model/edit/edit";
import { AddRowPayload } from "diagram/state/reducer/row/add";
import { DiagramProps } from "diagram/state/state";
import { getNumberId } from "utilities/id";
import { focusChild } from "../rows/navigate";
import { Button } from "app/button/button";

interface Props {
    table: EditTable;
    diagramDispatch: DiagramProps["diagramDispatch"];
    elements: DiagramElements;
    columns: EditColumn[];
}

const add = (props: Props) => {
    const payload: AddRowPayload = { tableId: props.table.id, rowId: null };
    props.diagramDispatch({ type: "add-row", payload });
};

/** Add a new row if focused via tab from an editing field */
const addAndFocus = (props: Props) => {
    if (isJustLeftEdit() === false) return;
    // Generate an ID so we know where to focus on after created
    const rowId = getNumberId(props.table.value.rows.map((r) => r.id));
    const payload: AddRowPayload = { tableId: props.table.id, rowId };
    props.diagramDispatch({ type: "add-row", payload });
    // Add row is async so we need to wait before focus on the new row
    window.setTimeout(() => {
        const element = props.elements.tables
            .get(props.table.id)
            ?.rows.get(rowId)
            ?.fields.get(props.columns[0].value.name);
        if (element === undefined) throw Error("New row is undefined");
        focusChild(element);
    }, 0);
};

export const TableAddRow = (props: Props) => {
    // Drop here to insert the row at the end of the Table
    const reorderDrop = useReorderDrop({
        tableId: props.table.id,
        diagramDispatch: props.diagramDispatch,
        rowIndex: props.table.value.rows.length,
    });
    return (
        <div
            className={[
                "animate-bottom-slide-in",
                reorderDrop.isOver ? "shadow-[0_-2px_0px_var(--blue10)]" : "",
            ].join(" ")}
            ref={reorderDrop.ref}
        >
            <Button
                extraStyle="w-full"
                onClick={() => add(props)}
                onFocus={() => addAndFocus(props)}
                icon={Icons.add}
                color="clear"
                children="Add"
            />
        </div>
    );
};
