import { EditTable } from "diagram/model/edit/edit";
import { DiagramReducer } from "../../reducer";

export interface ExpandTableRowsPayload {
    id: number;
    expanded: boolean;
}

type Reducer = DiagramReducer<ExpandTableRowsPayload>;

export const expandTableRows: Reducer = (diagram, payload) => {
    const tables: EditTable[] = diagram.tables.map((table) => {
        if (table.id !== payload.id) return table;
        return { ...table, rowsExpanded: payload.expanded };
    });
    return { ...diagram, tables };
};
