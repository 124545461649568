import { EditDiagramUnknown } from "./edit";
import { EditRowV1, EditTableValueV1 } from "./edit-v1";
import { EditDiagramV3, EditTableV3 } from "./edit-v3";

export interface EditDiagramV4
    extends Omit<EditDiagramV3, "version" | "tables"> {
    version: 4;
    tables: EditTableV4[];
}

export const isEditDiagramV4 = (
    diagram: EditDiagramUnknown
): diagram is EditDiagramV4 => {
    return "version" in diagram && diagram.version === 4;
};

export interface EditRowV4 extends Omit<EditRowV1, "isExpanded"> {
    isPrimary: boolean;
}

export interface EditTableValueV2 extends Omit<EditTableValueV1, "rows"> {
    rows: EditRowV4[];
}

export interface EditTableV4 extends Omit<EditTableV3, "value"> {
    value: EditTableValueV2;
}

export const migrateEditDiagramV3toV4 = (v3: EditDiagramV3): EditDiagramV4 => ({
    ...v3,
    version: 4,
    tables: v3.tables.map((table) => ({
        ...table,
        value: {
            ...table.value,
            rows: table.value.rows.map((row) => ({
                ...row,
                isPrimary: false,
            })),
        },
    })),
});
