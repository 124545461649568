export const border = {
    strong: "border-[var(--gray8)]",
    weak: "border-[var(--gray6)]",
};

interface Props {
    color: keyof typeof border;
}

export const Border = (props: Props): JSX.Element => {
    const { color } = props;

    return (
        <hr
            className={[
                "border-[1px] border-solid",
                "self-stretch h-auto border-b-0 border-r-0",
                border[color],
            ].join(" ")}
        />
    );
};
