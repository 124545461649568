import { TABLE_MARGIN_SINGLE as MARGIN } from "canvas/table/geometry";
import * as Edit from "diagram/model/edit/edit";
import { getNumberId, getStringId } from "utilities/id";
import { DiagramReducer } from "../reducer";
import { selectAllTables } from "./select/all";

type Reducer = DiagramReducer<undefined>;

export const duplicateSelectedTables: Reducer = (diagram) => {
    const ids = new Set(diagram.tables.map((table) => table.id));
    const valueIds = new Set(diagram.tables.map((table) => table.value.id));
    const selected = diagram.tables.filter((table) => table.selected);
    const { refs, tables } = selectAllTables(diagram, { selected: false });
    selected.forEach((prev) => {
        const next: Edit.EditTable = {
            ...prev,
            id: getNumberId(ids),
            value: { ...prev.value, id: getStringId(valueIds, prev.value.id) },
            position: { ...prev.position, left: prev.position.left + MARGIN },
            selected: true,
        };
        tables.push(next);
        ids.add(next.id);
        valueIds.add(next.value.id);
    });

    return { ...diagram, tables, refs };
};
