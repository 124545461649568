import { DiagramReducer } from "../reducer";
import { autoArrangeDiagram, AutoArrangePayload } from "./auto";

export type DiagramArrangeActions = {
    type: "auto-arrange";
    payload: AutoArrangePayload;
};

export const diagramArrangeReducer: DiagramReducer = (diagram, action) => {
    switch (action.type) {
        case "auto-arrange":
            return autoArrangeDiagram(diagram, action.payload);
        default:
            return diagram;
    }
};
