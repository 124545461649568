import { DiagramReducer } from "../reducer";
import { addTable, AddTablePayload } from "./add/add";
import { duplicateSelectedTables } from "./duplicate";
import { moveSelectedTables, MoveSelectedTablesPayload } from "./move";
import { removeTable, RemoveTablePayload } from "./remove/single";
import { renameTable, RenameTablePayload } from "./rename";
import { expandAllTablesRows, ExpandAllTablesRowsPayload } from "./rows/all";
import { expandSelectedTableRows, ExpandSelectedTableRowsPayload } from "./rows/selected"; // prettier-ignore
import { expandTableRows, ExpandTableRowsPayload } from "./rows/single";
import { selectAllTables, SelectAllTablesPayload } from "./select/all";
import { selectSomeTables, SelectSomeTablesPayload } from "./select/some";

export type DiagramTableActions =
    // None
    | { type: "add-table"; payload: AddTablePayload }
    // One
    | { type: "remove-table"; payload: RemoveTablePayload }
    | { type: "rename-table"; payload: RenameTablePayload }
    | { type: "expand-table-rows"; payload: ExpandTableRowsPayload }
    // Selected
    | { type: "duplicate-selected-table" }
    | { type: "move-selected-tables"; payload: MoveSelectedTablesPayload }
    | { type: "select-some-tables"; payload: SelectSomeTablesPayload }
    | { type: "expand-selected-table-rows"; payload: ExpandSelectedTableRowsPayload } // prettier-ignore
    // All
    | { type: "select-all-tables"; payload: SelectAllTablesPayload }
    | { type: "expand-all-tables-rows"; payload: ExpandAllTablesRowsPayload };

export const diagramTableReducer: DiagramReducer = (diagram, action) => {
    switch (action.type) {
        case "add-table":
            return addTable(diagram, action.payload);
        case "remove-table":
            return removeTable(diagram, action.payload);
        case "rename-table":
            return renameTable(diagram, action.payload);
        case "expand-table-rows":
            return expandTableRows(diagram, action.payload);
        case "expand-selected-table-rows":
            return expandSelectedTableRows(diagram, action.payload);
        case "expand-all-tables-rows":
            return expandAllTablesRows(diagram, action.payload);
        case "move-selected-tables":
            return moveSelectedTables(diagram, action.payload);
        case "select-some-tables":
            return selectSomeTables(diagram, action.payload);
        case "select-all-tables":
            return selectAllTables(diagram, action.payload);
        case "duplicate-selected-table":
            return duplicateSelectedTables(diagram, undefined);
        default:
            return diagram;
    }
};
