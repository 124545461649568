import { Input } from "input/input";
import * as React from "react";
import { isJustLeftEdit, setJustLeftEdit } from "./leave";

interface Props {
    value: string;
    setValue: (value: string) => void;
}

interface State {
    isEdit: boolean;
    setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
    viewRef: React.RefObject<HTMLDivElement>;
}

interface InternalProps extends Props {
    state: State;
}

const focusView = (props: InternalProps) => {
    const view = props.state.viewRef.current;
    if (view === null) throw Error("View is null");
    view.focus();
};

const Edit = (props: InternalProps) => (
    <div
        className={[
            "absolute top-0 left-0",
            "w-full h-full",
            "shadow-[0_0px_3px_var(--blue10)]",
        ].join(" ")}
    >
        <Input
            paddingStyle="px-1"
            key={props.value} // uncontrolled
            defaultValue={props.value}
            autoFocus
            onBlur={(event) => {
                props.setValue(event.currentTarget.value);
                props.state.setIsEdit(false);
            }}
            onKeyDown={(event) => {
                if (event.key !== "Tab") return;
                setJustLeftEdit();
            }}
            onKeyUp={(event) => {
                if (event.key !== "Enter") return;
                props.setValue(event.currentTarget.value);
                props.state.setIsEdit(false);
                focusView(props);
            }}
        />
    </div>
);

const ViewValue = ({ value }: { value: string }) => {
    if (value.length !== 0) return <span>{value}</span>;
    return <span className={"italic"}>&lt;empty&gt;</span>;
};

const View = (props: InternalProps) => (
    <div
        // Avoid the View to steal the focus when it's not in edit mode
        tabIndex={props.state.isEdit ? -1 : 0}
        children={<ViewValue value={props.value} />}
        className={[
            "leading-normal w-full truncate",
            "cursor-pointer",
            "transition-outline",
            "outline-8 outline-transparent outline -outline-offset-2",
            "react-draggable-cancel",
            "row-input-string-view",
            "[&>:not(.weak)]:transition-none",
        ].join(" ")}
        onDoubleClick={() => {
            props.state.setIsEdit(true);
        }}
        onFocus={() => {
            if (isJustLeftEdit()) props.state.setIsEdit(true);
        }}
        onKeyUp={(event) => {
            if (event.key !== "Enter") return;
            props.state.setIsEdit(true);
        }}
        ref={props.state.viewRef}
    />
);

export const RowFieldString = (props: Props) => {
    const [isEdit, setIsEdit] = React.useState(false);
    const viewRef = React.useRef<HTMLDivElement>(null);
    const state: State = { isEdit, setIsEdit, viewRef };
    return (
        <div className={["relative"].join(" ")}>
            {/* We still need to keep the View rendered because the width
            depends on it */}
            <View {...props} state={state} />
            {isEdit && <Edit {...props} state={state} />}
        </div>
    );
};
