import { DiagramElements } from "canvas/elements";
import { Point, subtractPoint } from "geometry/point";
import * as React from "react";
import { DragLayerMonitor, useDragLayer } from "react-dnd";
import { Icon, Icons } from "utilities/icon";
import { EdgeDrag } from "./drag";

interface DragState {
    initial: Point;
    current: Point;
}

const getState = (monitor: DragLayerMonitor): DragState | null => {
    if (!monitor.isDragging()) return null;
    if (monitor.getItemType() !== EdgeDrag.dragId) return null;
    const current = monitor.getClientOffset();
    const initial = monitor.getInitialSourceClientOffset();
    if (current === null || initial === null) return null;
    return { current, initial };
};

interface Props {
    elements: DiagramElements;
}

type PointRef = React.MutableRefObject<Point | null>;

const getScrollOffset = (props: Props, initial: PointRef): Point => {
    const { viewport } = props.elements;
    if (viewport === null) throw Error("Viewport is null");
    const current: Point = { y: viewport.scrollTop, x: viewport.scrollLeft };
    if (initial.current === null) initial.current = current;
    return subtractPoint(current, initial.current);
};

export const EdgeDragIndicator = (props: Props) => {
    const state: DragState | null = useDragLayer(getState);
    const initialScroll = React.useRef<Point | null>(null);
    if (state === null) {
        initialScroll.current = null;
        return null;
    }
    const scroll = getScrollOffset(props, initialScroll);

    return (
        <div
            className={[
                "fixed top-0 left-0 w-full h-full pointer-events-none z-2 text-[var(--highlight-5)]",
            ].join(" ")}
        >
            <svg className={["w-full h-full"].join(" ")}>
                <line
                    className={["stroke-[2] stroke-current"].join(" ")}
                    x1={state.initial.x + 14 - scroll.x}
                    y1={state.initial.y + 12 - scroll.y}
                    x2={state.current.x - 4}
                    y2={state.current.y - 4}
                />
            </svg>
            <div
                className={"absolute"}
                style={{
                    top: state.current.y - 12,
                    left: state.current.x - 12,
                }}
            >
                <Icon className="block" icon={Icons.locate} size={16} />
            </div>
        </div>
    );
};
