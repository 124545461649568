import { Button } from "app/button/button";
import { Border } from "border/border";
import { MenuItemProps } from "./menu";

interface Props {
    item: MenuItemProps;
}

export const MenuItem = ({ item }: Props): JSX.Element =>
    item === "divider" ? (
        <div>
            <div className={"h-3 w-3"} />
            <Border color="weak" />
            <div className={"h-3 w-3"} />
        </div>
    ) : (
        <Button
            color="clear"
            className={[
                "outline-none",
                //
                "flex items-start",
                "text-13 py-1 px-2 rounded",
                "hover:bg-blue-500",
                //
                "w-full",
                "disabled:text-gray-500",
                "cursor-pointer",
            ].join(" ")}
            onClick={() => item.fn?.()}
            disabled={item.disabled}
            extraStyle={"w-full justify-start"}
        >
            <div className="justify-start">{item.label}</div>
        </Button>
    );
