import * as Radix from "@radix-ui/react-toggle-group";

const Root = (
    props: Radix.ToggleGroupMultipleProps | Radix.ToggleGroupSingleProps
): JSX.Element => {
    return (
        <Radix.Root
            className={[
                //
                "bg-[var(--slate1)]",
                "flex p-0.5 rounded-md",
                "shadow",
            ].join(" ")}
            {...props}
        />
    );
};

const Item = (props: Radix.ToggleGroupItemProps): JSX.Element => {
    return (
        <Radix.Item
            className={[
                //
                "data-on:bg-[var(--slate8)] data-on:shadow",
                "data-on:font-medium",
                "flex-1",
                "text-13 p-2 rounded",
                "text-[var(--slate12)]",
                "active:bg-[var(--gray10)]",
            ].join(" ")}
            {...props}
        />
    );
};

export const ToggleGroup = {
    Root,
    Item,
};
