import * as Radix from "@radix-ui/react-popover";

const Content = (props: Radix.PopoverContentProps): JSX.Element => {
    return (
        <Radix.Portal>
            <Radix.Content
                className={[
                    //
                    "rounded",
                    "bg-[var(--slate2)] shadow-24 rounded-md z-[5]",
                ].join(" ")}
                {...props}
            />
        </Radix.Portal>
    );
};

const Arrow = (props: Radix.PopoverArrowProps): JSX.Element => {
    return (
        <Radix.Arrow
            className={["fill-[var(--slate2)] shadow-lg"].join(" ")}
            {...props}
        />
    );
};

export const Popover = {
    Root: Radix.Root,
    Trigger: Radix.Trigger,
    Content,
    Arrow,
};
