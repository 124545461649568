import * as E from "diagram/model/edit/edit";

export interface RowEdges {
    from: E.EditEdge | null;
    to: E.EditEdge[];
}

export type TableEdges = Map<E.EditRow["id"], RowEdges>;

export type DiagramEdges = Map<E.EditTable["id"], TableEdges>;

export const getDiagramEdges = (edges: E.EditEdge[]): DiagramEdges => {
    const map: DiagramEdges = new Map();
    edges.forEach((edge) => {
        const from: TableEdges = map.get(edge.from.table) ?? new Map();
        edge.from.rows.forEach((row) => {
            const edges: RowEdges = from.get(row) ?? { from: null, to: [] };
            edges.from = edge;
            from.set(row, edges);
        });
        map.set(edge.from.table, from);
        const to: TableEdges = map.get(edge.to.table) ?? new Map();
        edge.to.rows.forEach((row) => {
            const edges: RowEdges = to.get(row) ?? { from: null, to: [] };
            edges.to.push(edge);
            to.set(row, edges);
        });
        map.set(edge.to.table, to);
    });
    return map;
};
