import { EditDiagram, EditRef, EditTable } from "../model/edit/edit";

interface State {
    diagram: EditDiagram;
    done: Map<number, boolean>;
    tableMap: Map<number, EditTable>;
    refMap: Map<number, EditRef[]>;
}

const pushTableToDiagram = (state: State, table: EditTable): void => {
    const { diagram, done, refMap, tableMap } = state;
    if (done.get(table.id) === true) return;
    diagram.tables.push(table);
    refMap.get(table.id)?.forEach((ref) => {
        if (diagram.refs.includes(ref)) return;
        diagram.refs.push(ref);
    });
    done.set(table.id, true);
    (refMap.get(table.id) ?? []).forEach((ref) => {
        const fromTable = tableMap.get(ref.from.table);
        const toTable = tableMap.get(ref.to.table);
        if (fromTable === undefined || toTable === undefined)
            throw Error(`"fromTable" or "toTable" is undefined`);
        pushTableToDiagram(state, fromTable);
        pushTableToDiagram(state, toTable);
    });
};

/**
 * Split a Diagram into a group of diagrams where each diagram only contains
 * nodes that are connected to each other (from one node can go to another).
 */
export const splitDiagram = (bigDiagram: EditDiagram): EditDiagram[] => {
    // Sub diagrams split from bigDiagram
    const diagrams: EditDiagram[] = [];
    // Tables that are put into a sub diagram
    const done = new Map<number, boolean>();

    // Table id to instance for quick reference
    const tableMap = new Map<number, EditTable>();
    bigDiagram.tables.forEach((table) => {
        tableMap.set(table.id, table);
    });

    // Table id to related refs for quick reference
    const refMap = new Map<number, EditRef[]>();
    bigDiagram.refs.forEach((ref) => {
        const [from, to] = [ref.from.table, ref.to.table];
        refMap.set(from, (refMap.get(from) ?? []).concat(ref));
        refMap.set(to, (refMap.get(to) ?? []).concat(ref));
    });

    bigDiagram.tables.forEach((table) => {
        if (done.get(table.id) === true) return;
        const diagram: EditDiagram = {
            ...bigDiagram,
            tables: [],
            refs: [],
            size: { width: 0, height: 0 }, // Will provide later
        };
        pushTableToDiagram({ diagram, done, tableMap, refMap }, table);
        diagrams.push(diagram);
    });

    return diagrams;
};
