import { ProgressCircle } from "app/progress/circle";
import { addHostCallbackFuture } from "host/callbacks";
import React from "react";

export type Progress = boolean | number;

const addHostCallback = addHostCallbackFuture("setProgressIndicator");

export const AppCanvasProgress = () => {
    const [progress, setProgress] = React.useState<Progress>(false);
    const [text, setText] = React.useState<string | undefined>(undefined);

    React.useEffect(() => {
        addHostCallback((progress: Progress, text?: string) => {
            setProgress(progress);
            setText(text);
        });
    }, []);

    if (progress === false) return null;

    return (
        <div
            className={[
                "absolute top-0 left-0 w-full h-full z-[1]",
                "flex items-center justify-center",
                "bg-[var(--slate1)]",
            ].join(" ")}
        >
            <div className={["flex items-center"].join(" ")}>
                <ProgressCircle size={24} />
                {text !== undefined && (
                    <>
                        <div className="w-4 h-4" />
                        <div>{text}</div>
                    </>
                )}
            </div>
        </div>
    );
};
