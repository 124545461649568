import { EditDiagram } from "diagram/model/edit/edit";
import { emptyDiagram } from "diagram/state/state";
import { getNumberId, getStringId } from "utilities/id";
import { Library, LibraryItem } from "./library";

type Reducer = (library: Library) => Library;

/** Create a new diagram with a ready-to-save name */
const add = (): Reducer => (prev) => {
    const ids = prev.all.map((item) => item.id);
    const names = prev.all.map((item) => item.name);
    const item: LibraryItem = {
        id: getNumberId(ids),
        name: getStringId(names, "Untitled"),
        diagram: emptyDiagram,
        saved: false,
        shared: false,
    };
    return {
        current: item.id,
        all: prev.all.concat(item),
    };
};

const addItem =
    (item: LibraryItem): Reducer =>
    (prev) => {
        return {
            current: item.id,
            all: prev.all.concat(item),
        };
    };

const set =
    (target: number, diagram: EditDiagram): Reducer =>
    (prev) => {
        let changed = false;
        const all = prev.all.map((item) => {
            if (item.id !== target) return item;
            if (item.diagram === diagram) return item;
            changed = true;
            return { ...item, diagram };
        });
        if (changed === false) return prev;
        return { ...prev, all };
    };

const save =
    (target: number): Reducer =>
    (prev) => {
        let changed = false;
        const all = prev.all.map((item) => {
            if (item.id !== target) return item;
            if (item.saved === true) return item;
            changed = true;
            return { ...item, saved: true };
        });
        if (changed === false) return prev;
        return { ...prev, all };
    };

const share =
    (target: number): Reducer =>
    (prev) => {
        let changed = false;
        const all = prev.all.map((item) => {
            if (item.id !== target) return item;
            if (item.shared === true) return item;
            changed = true;
            return { ...item, shared: true };
        });
        if (changed === false) return prev;
        return { ...prev, all };
    };

const unshare =
    (target: number): Reducer =>
    (prev) => {
        let changed = false;
        const all = prev.all.map((item) => {
            if (item.id !== target) return item;
            if (item.shared === false) return item;
            changed = true;
            return { ...item, shared: false };
        });
        if (changed === false) return prev;
        return { ...prev, all };
    };

const remove =
    (target: number): Reducer =>
    (prev) => {
        const current = prev.current === target ? null : prev.current;
        const all = prev.all.filter((item) => item.id !== target);
        return { current, all };
    };

const rename =
    (target: number, to: string): Reducer =>
    (prev) => {
        const current = prev.current === target ? target : prev.current;
        const all = prev.all.map((item) => {
            if (item.id !== target) return item;
            return { ...item, name: to, saved: true };
        });
        return { current, all };
    };

const open =
    (id: number): Reducer =>
    (prev) => ({
        current: id,
        all: prev.all,
    });

const duplicate =
    (target: number): Reducer =>
    (prev) => {
        const prevItem = prev.all.find((item) => item.id === target);
        if (prevItem === undefined) throw Error("Item is undefined");
        const newName = getStringId(
            prev.all.map((item) => item.name),
            prevItem.name
        );

        return {
            current: target,
            all: prev.all.concat({
                ...prevItem,
                id: prevItem.id + 1,
                name: newName,
            }),
        };
    };

const setAll =
    (all: LibraryItem[]): Reducer =>
    (prev) => {
        return {
            ...prev,
            current: prev.current ?? all[all.length - 1].id ?? null,
            all,
        };
    };

export const LibraryReducer = {
    remove,
    rename,
    save,
    open,
    add,
    addItem,
    share,
    unshare,
    duplicate,
    set,
    setAll,
};
