import { GRID_CELL } from "geometry/geometry";

/**
 * Number of rows to show before collapsing into a hidden group
 */
export const MAX_ROWS_COUNT = 50;

/**
 * Width of a table by default (non-edit mode). This includes padding and
 * border but no margin
 */
export const TABLE_CLIENT_WIDTH = GRID_CELL * 16;

/**
 * Width of the column area of a table in non-edit mode. This is basically
 * TABLE_CLIENT_WIDTH without the padding. Also equal 2 columns (because we
 * only show 2 columns when table is not expanded)
 */
export const TABLE_BODY_WIDTH = TABLE_CLIENT_WIDTH - 16;

/**
 * Margin on one side of a Table
 */
export const TABLE_MARGIN_SINGLE = GRID_CELL * 3;

/**
 * Node separator
 */
export const TABLE_MARGIN_NODE = GRID_CELL;

/**
 * Total margin on both sides of a Table. In an auto layout, this is also:
 * - The distance between 2 adjacent Tables
 * - The distance from the Diagram's edge to the nearest Table
 */
export const TABLE_MARGIN_DOUBLE = TABLE_MARGIN_SINGLE * 2;

/**
 * Total width a Table should take, including its margin
 */
export const TABLE_MARGIN_WIDTH = TABLE_CLIENT_WIDTH + TABLE_MARGIN_DOUBLE;
