import * as Radix from "@radix-ui/react-select";
import { forwardRef } from "react";

type Ref = HTMLDivElement;
interface Props extends Radix.SelectValueProps {
    backgroundColor?: string;
}

export const SelectValue = forwardRef<Ref, Props>((props, ref) => {
    const { backgroundColor, children, ...value } = props;
    return (
        <Radix.Value
            {...value}
            ref={ref}
            className={[
                //
                backgroundColor && backgroundColor,
            ].join(" ")}
        >
            {children}
        </Radix.Value>
    );
});
