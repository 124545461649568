import { DiagramElements } from "canvas/elements";
import {
    TABLE_MARGIN_DOUBLE,
    TABLE_MARGIN_NODE,
    TABLE_MARGIN_SINGLE,
} from "canvas/table/geometry";
import dagre from "dagre";
import { Position, roundNumber, roundPosition } from "geometry/geometry";
import { EditDiagram } from "../model/edit/edit";

export const applyDagreLayout = (
    elements: DiagramElements,
    diagram: EditDiagram
): EditDiagram => {
    const g = new dagre.graphlib.Graph({ multigraph: true });
    g.setGraph({
        rankdir: "LR",
        nodesep: TABLE_MARGIN_NODE,
        ranksep: TABLE_MARGIN_DOUBLE,
        // Our masonry algorithm requires marginX here to be SINGLE
        marginx: TABLE_MARGIN_SINGLE,
        // marginY however can be anything
        marginy: TABLE_MARGIN_SINGLE,
    });
    g.setDefaultEdgeLabel(() => ({}));

    // nodes
    diagram.tables.forEach((table) => {
        const element = elements.tables.get(table.id)?.element ?? null;
        if (element === null) throw Error(`Table not found ${table.id}`);
        const { clientWidth: width, clientHeight: height } = element;
        g.setNode(table.id.toString(), { label: "", width, height });
    });

    // edges
    diagram.refs.forEach((ref) => {
        g.setEdge(ref.from.table.toString(), ref.to.table.toString());
    });

    dagre.layout(g);

    return {
        ...diagram,
        tables: diagram.tables.map((prev) => {
            const box = g.node(prev.id.toString());
            // Dagre's x y is center, not top left
            const position: Position = roundPosition({
                top: box.y - box.height / 2,
                left: box.x - box.width / 2,
            });
            return { ...prev, position };
        }),
        size: {
            width: roundNumber(g.graph().width!),
            height: roundNumber(g.graph().height!),
        },
    };
};
