import {
    addPoints,
    crossProductPoints,
    multiplyPoint,
    Point,
    subtractPoint,
} from "./point";

export interface Segment {
    A: Point;
    B: Point;
}

/**
 * Return the intersection point between 2 line segments, or null otherwise.
 * When the intersection is a segment instead of a line, consider it as null.
 *
 * The code here comes from https://github.com/pgkelley4/line-segments-intersect
 * which come from http://stackoverflow.com/a/565282/786339. Note that it is
 * heavily modified to fit DiagramPlus's use case only
 */
export const getSegmentIntersection = (
    P: Segment,
    Q: Segment
): Point | null => {
    const r = subtractPoint(P.B, P.A);
    const s = subtractPoint(Q.B, Q.A);

    const uNumerator = crossProductPoints(subtractPoint(Q.A, P.A), r);
    const denominator = crossProductPoints(r, s);

    // Segments are collinear. Technically there can be no overlap, or the
    // intersection will be a segment. However, under DiagramPlus's perspective
    // we don't care about both
    if (uNumerator === 0 && denominator === 0) return null;

    // Segments are paralell.
    if (denominator === 0) return null;

    const u = uNumerator / denominator;
    const t = crossProductPoints(subtractPoint(Q.A, P.A), s) / denominator;

    // Segments are not parallel but do not intersect.
    if (t < 0 || t > 1 || u < 0 || u > 1) return null;

    const addend = multiplyPoint(r, t);
    const intersection = addPoints(P.A, addend);
    return intersection;
};

export const getSegmentLength = (A: Point, B: Point): number => {
    return Math.sqrt(Math.pow(A.x - B.x, 2) + Math.pow(A.y - B.y, 2));
};

/**
 * Split "B" into X and Y where X and Y are on AB line and XB = BY = len. X
 * must be on AB segment, so returns null if not available
*/
export const splitSegment = (
    A: Point,
    B: Point,
    len: number
): null | { X: Point; Y: Point } => {
    const dAB = getSegmentLength(A, B);
    if (dAB < len) return null;
    // B = A + 1 * r
    const r = subtractPoint(B, A);
    // X = A + t * r where t is AX / XB (%)
    const dXB = len;
    const t = 1 - dXB / dAB;
    const X = addPoints(A, multiplyPoint(r, t));
    // Y = A + u * r similar to X
    const u = 1 + dXB / dAB;
    const Y = addPoints(A, multiplyPoint(r, u));
    return { X, Y };
};
