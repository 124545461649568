import { DiagramReducer } from "../reducer";
import { addRef, AddRefPayload } from "./add";
import { removeRef, RemoveRefPayload } from "./remove";
import { selectRef, SelectRefPayload } from "./select";

export type DiagramRefActions =
    | { type: "add-ref"; payload: AddRefPayload }
    | { type: "select-ref"; payload: SelectRefPayload }
    | { type: "remove-ref"; payload: RemoveRefPayload };

export const diagramRefReducer: DiagramReducer = (diagram, action) => {
    switch (action.type) {
        case "add-ref":
            return addRef(diagram, action.payload);
        case "select-ref":
            return selectRef(diagram, action.payload);
        case "remove-ref":
            return removeRef(diagram, action.payload);
        default:
            return diagram;
    }
};
