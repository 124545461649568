import { decodeText } from "utilities/encoding";
import * as Base from "../base/base";
import * as Edit from "../edit/edit";
import { makeEditDiagramFromBase } from "./base";

const importObject = (diagram: unknown): Edit.EditDiagram => {
    if (Base.isBaseDiagramUknown(diagram)) {
        const latest = Base.migrateBaseDiagram(diagram);
        return makeEditDiagramFromBase(latest);
    }
    if (Edit.isEditDiagramUknown(diagram)) {
        return Edit.migrateEditDiagram(diagram);
    }
    throw Error("Diagram is neither Base or Edit type");
};

const importJson = (json: string): Edit.EditDiagram => {
    const object = JSON.parse(json);
    return importObject(object);
};

const importBase64 = (base64: string): Edit.EditDiagram => {
    const json = decodeText(base64);
    return importJson(json);
};

export const parseDiagram = (source: unknown): Edit.EditDiagram => {
    if (typeof source === "string") {
        if (source.startsWith("{")) {
            return importJson(source);
        } else {
            return importBase64(source);
        }
    } else if (typeof source === "object") {
        return importObject(source);
    }
    throw Error("Unknown diagram type");
};
