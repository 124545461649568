import { DiagramReducer } from "../reducer";
import { addRow, AddRowPayload } from "./add";
import { moveRow, MoveRowPayload } from "./move";
import { removeRow, RemoveRowPayload } from "./remove";
import { updateRow, UpdateRowPayload } from "./update";

export type DiagramRowActions =
    | { type: "add-row"; payload: AddRowPayload }
    | { type: "remove-row"; payload: RemoveRowPayload }
    | { type: "update-row"; payload: UpdateRowPayload }
    | { type: "move-row"; payload: MoveRowPayload }

export const diagramRowReducer: DiagramReducer = (diagram, action) => {
    switch (action.type) {
        case "move-row":
            return moveRow(diagram, action.payload);
        case "add-row":
            return addRow(diagram, action.payload);
        case "remove-row":
            return removeRow(diagram, action.payload);
        case "update-row":
            return updateRow(diagram, action.payload);
        default:
            return diagram;
    }
};
