import { Button } from "app/button/button";
import { SharedDialog } from "app/shared/dialog/dialog";
import { SharedPromptLoginDialog } from "app/shared/prompt-login/prompt-login";
import { AuthContext } from "auth/auth";
import { LibraryItem, LibraryProps } from "library/library";
import { useContext, useState } from "react";
import { Icons } from "utilities/icon";

interface Props {
    item: LibraryItem;
    setLibrary: LibraryProps["setLibrary"];
}

export const LibraryItemShare = (props: Props) => {
    const [shareVisible, setShareVisible] = useState(false);
    const [promptLoginVisible, setPromptLoginVisible] = useState(false);
    const { isAuthenticated } = useContext(AuthContext);
    const { item, setLibrary } = props;

    const onClickShare = () => {
        if (isAuthenticated) {
            setShareVisible(true);
        } else {
            setPromptLoginVisible(true);
        }
    };

    return (
        <>
            <Button
                extraStyle="h-[32px] w-[32px]"
                onClick={onClickShare}
                icon={Icons.documentShare}
                color="clear"
            />
            {promptLoginVisible && (
                <SharedPromptLoginDialog
                    open={promptLoginVisible}
                    setOpen={setPromptLoginVisible}
                    onDone={() => setPromptLoginVisible(false)}
                />
            )}
            {shareVisible && (
                <SharedDialog
                    diagram={item}
                    setLibrary={setLibrary}
                    onDone={() => setShareVisible(false)}
                    open={shareVisible}
                    setOpen={setShareVisible}
                />
            )}
        </>
    );
};
