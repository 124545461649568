import { setZoomInPref, setZoomOutPref } from "app/header/zoom/zoom";
import { SelectAllTablesPayload } from "diagram/state/reducer/table/select/all";
import { DiagramHistoryProps } from "diagram/state/state";
import { PreferencesProps } from "preferences/preferences";
import * as React from "react";

interface Props {
    diagramHistory: DiagramHistoryProps["diagramHistory"];
    diagramDispatch: DiagramHistoryProps["diagramHistoryDispatch"];
    setPreferences: PreferencesProps["setPreferences"];
}

type Handler = React.KeyboardEventHandler;

export const onCanvasKeyDown =
    (props: Props): Handler =>
    (event) => {
        const [history, dispatch, setPrefs] = [
            props.diagramHistory,
            props.diagramDispatch,
            props.setPreferences,
        ];
        if (event.target instanceof HTMLInputElement) return;
        if (event.target instanceof HTMLTextAreaElement) return;
        const ctrl = event.ctrlKey || event.metaKey; /* Cmd in Mac */
        switch (event.key) {
            case "Backspace":
            case "Delete": {
                dispatch({ type: "remove-selected-items" });
                break;
            }
            case "=":
            case "-":
                if (!ctrl) break;
                setPrefs(event.key === "-" ? setZoomOutPref : setZoomInPref);
                break;
            case "A":
            case "a": {
                if (!ctrl) break;
                const payload: SelectAllTablesPayload = { selected: true };
                dispatch({ type: "select-all-tables", payload });
                break;
            }
            case "D":
            case "d": {
                if (ctrl) dispatch({ type: "duplicate-selected-table" });
                break;
            }
            case "Z":
            case "z": {
                if (ctrl) {
                    if (event.shiftKey) {
                        if (history.future.length > 0) {
                            dispatch({ type: "redo" });
                        }
                    } else {
                        if (history.past.length > 0) {
                            dispatch({
                                type: "undo",
                            });
                        }
                    }
                }
                break;
            }
            case "Y":
            case "y": {
                if (ctrl) {
                    if (history.future.length > 0) {
                        dispatch({ type: "redo" });
                    }
                }
                break;
            }
            default: {
                return; // Avoid preventDefault when not handled
            }
        }
        event.preventDefault();
    };
