import { Icons } from "utilities/icon";
import { PreferencesContext } from "preferences/preferences";
import * as React from "react";
import { ToggleGroup } from "app/toggle/group";
import { ButtonProps } from "app/button/button";
import { Theme } from "preferences/theme";

interface ThemeOption {
    value: Theme;
    label: string;
    icon?: ButtonProps["icon"];
    key: string;
}

const options: ThemeOption[] = [
    { value: "light", label: "Light", icon: Icons.flash, key: "light" },
    { value: "auto", label: "System", key: "auto" },
    { value: "dark-theme", label: "Dark", icon: Icons.moon, key: "dark" },
];

export const HeaderTheme = () => {
    const context = React.useContext(PreferencesContext);
    const { preferences: prefs, setPreferences: setPrefs } = context;

    return (
        <ToggleGroup.Root
            type="single"
            value={prefs.theme}
            onValueChange={(theme) => {
                const newTheme = options.find(
                    (option) => option.value === theme
                );
                if (newTheme) {
                    setPrefs((prev) => ({ ...prev, theme: newTheme.value }));
                }
            }}
        >
            {options.map((option) => (
                <ToggleGroup.Item value={option.value} key={option.key}>
                    {option.label}
                </ToggleGroup.Item>
            ))}
        </ToggleGroup.Root>
    );
};
