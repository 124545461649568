import { EditRow, EditTable } from "diagram/model/edit/edit";
import { GRID_CELL } from "geometry/geometry";

/**
 * This module prevents lines from overlapping horizontally. This usually
 * happens when multiple Edges point to the same row:
 * BAD:  A ----+----> C
 *             |
 *       B ----+
 *
 * GOOD: A ---------> C1 (C row is bigger)
 *             +----> C2
 *       B ----+
 */

type TID = EditTable["id"];
type RID = EditRow["id"];

interface State {
    countMap: Map<TID, Map<RID, number>>;
}

export type EdgeSplitYState = State;

const STEP = GRID_CELL;

/**
 * Returns the distance that an Edge should move where it points to the row.
 * For example if there are 2 Edges already on that row, this should return
 * -16, which means the next Edge should point 16px above the original Y
 */
const getOffset = (state: State, tableId: TID, rowId: RID): number => {
    const count = state.countMap.get(tableId)?.get(rowId) ?? 0;
    // Alternately point to above and below the current edges
    const side = count % 2 === 0 ? -1 : +1;
    const offset = Math.ceil(count / 2) * STEP;
    return side * offset;
};

const save = (state: State, tableId: TID, rowId: RID): void => {
    const table: Map<RID, number> = state.countMap.get(tableId) ?? new Map();
    table.set(rowId, (table.get(rowId) ?? 0) + 1);
    state.countMap.set(tableId, table);
};

const init = (): State => ({ countMap: new Map() });

export const EdgeSplitY = {
    init,
    getOffset,
    save,
};
