import { DiagramElements } from "canvas/elements";
import * as Edit from "../model/edit/edit";
import { applyDagreLayout } from "./dagre";
import { getMasonryLayout } from "./masonry";
import { mergeDiagram } from "./merge";
import { splitDiagram } from "./split";

// Split -> Dagree -> Masonry -> Merge
export const arrangeDiagram = (
    elements: DiagramElements,
    originalDiagram: Edit.EditDiagram
): Edit.EditDiagram => {
    // Split the diagram into sub diagrams
    let diagrams = splitDiagram(originalDiagram);
    // Arrange each diagram with dagree
    diagrams = diagrams.map((diagram) => applyDagreLayout(elements, diagram));
    // Arrange sub diagrams into a big canvas
    const masonry = getMasonryLayout(diagrams);
    // Merge sub diagrams back into one diagram
    return mergeDiagram({ originalDiagram, masonry, diagrams });
};
