import { ComponentProps, forwardRef } from "react";
import { Icon, IconProps } from "utilities/icon";

export const buttonFocusStyles = [
    //
    "transition-shadow ease-out duration-300",
    // border
    "outline outline-transparent",
    // shadow
    "ring-8 ring-transparent",
].join(" ");

interface Props extends ComponentProps<"button"> {
    icon?: IconProps["icon"];
    iconSize?: number;
    paddingStyle?: string;
    selected?: boolean;
    color: "blue" | "solid" | "gray" | "clear";
    textColor?: string;
    extraStyle?: string;
}

export type { Props as ButtonProps };

const COLORS: Record<
    Props["color"],
    { main: string; selected: string; icon: string }
> = {
    clear: {
        main: "bg-none text-[var(--slate12)] disabled:text-[var(--slate10)] hover:bg-[var(--slate6)] active:bg-[var(--slate7)]",
        selected:
            "bg-[var(--slate8)] hover:bg-[var(--slate9)] active:bg-[var(--slate10)]",
        icon: "text-[var(--slate12)]",
    },
    solid: {
        main: "bg-[var(--slate1)] active:bg-[var(--slate6)] shadow-sm text-[var(--slate12)] disabled:text-[var(--slate4)] border border-[var(--slate7)] hover:bg-[var(--slate4)]",
        selected:
            "bg-[var(--slate8)] hover:bg-[var(--slate9)] active:bg-[var(--slate10)]",
        icon: "text-[var(--slate12)]",
    },
    gray: {
        main: "bg-[var(--slate1)] active:bg-[var(--slate6)] shadow text-[var(--slate12)] disabled:text-[var(--slate9)] hover:bg-[var(--slate5)]",
        selected:
            "bg-[var(--slate8)] hover:bg-[var(--slate9)] active:bg-[var(--slate10)]",
        icon: "text-[var(--slate12)]",
    },
    blue: {
        main: "bg-[var(--blue9)] hover:bg-[var(--blue10)] shadow text-white disabled:text-[var(--slate6)]",
        selected:
            "bg-[var(--blue9)] hover:bg-[var(--blue10)] active:bg-[var(--blue10)] ",
        icon: "text-[var(--slate2)]",
    },
};

export const Button = forwardRef<HTMLButtonElement, Props>((props, ref) => {
    const {
        icon,
        iconSize,
        paddingStyle,
        color: pColor,
        textColor,
        extraStyle,
        children,
        selected,
        ...native
    } = props;
    const color = COLORS[pColor];

    return (
        <button
            ref={ref}
            className={[
                //
                color.main,
                buttonFocusStyles,
                "flex justify-center gap-2 rounded",
                "text-13",
                paddingStyle ? paddingStyle : "p-2",
                textColor,
                selected ? color.selected : "",
                "[-webkit-app-region:no-drag]",
                extraStyle ? extraStyle : "",
            ].join(" ")}
            {...native}
        >
            {icon && <Icon icon={icon} size={iconSize ?? 16} />}
            {children}
        </button>
    );
});
