import React from "react";

export interface UndoState<S> {
    past: S[];
    present: S;
    future: S[];
}

export type UndoAction = { type: "undo" } | { type: "redo" };

export const createUndoInitialState = <S>(initial: S): UndoState<S> => ({
    past: [],
    present: initial,
    future: [],
});

type GenericAction = { type: string };

export const createUndoReducer = <S, A extends GenericAction>(
    orgReducer: React.Reducer<S, A>
) => (prev: UndoState<S>, action: UndoAction | A): UndoState<S> => {
    switch (action.type) {
        case "undo": {
            const [present, ...past] = prev.past;
            const future = [prev.present, ...prev.future];
            return { past, present, future };
        }
        case "redo": {
            const [present, ...future] = prev.future;
            const past = [prev.present, ...prev.past];
            return { past, present, future };
        }
        default: {
            const present = orgReducer(prev.present, action as any);
            if (present === prev.present) return prev;
            let past = [prev.present, ...prev.past];
            past = past.length < 20 ? past : past.slice(0, 20);
            return { past, present, future: [] };
        }
    }
};
