import { EdgeDrag, EdgeDragResult } from "canvas/edge/drag/drag";
import { FieldName } from "canvas/elements";
import { TableNavigate } from "canvas/table/active/visible/rows/navigate";
import { TABLE_BODY_WIDTH } from "canvas/table/geometry";
import { EditColumn, EditEdge } from "diagram/model/edit/edit";
import { RemoveRowPayload } from "diagram/state/reducer/row/remove";
import { DiagramProps } from "diagram/state/state";
import * as React from "react";
import { useDrop } from "react-dnd";
import { useReorderDrag, useReorderDrop } from "../reorder/reorder";
import { RowWrapper, RowWrapperProps } from "../wrapper/wrapper";
import { RowEdge } from "./edge/edge";
import { makeRowElementsRef } from "./element";
import { RowField } from "./field/field";
import { makeRowKeyHandler } from "./key";
import { RowPrimaryKey } from "./primary/primary";
import { RowRemoveButton } from "./remove/remove";

interface Props extends RowWrapperProps {
    rowIndex: number;
    tableExpanded: boolean;
    tableNavigate: TableNavigate;
    columns: EditColumn[];
    columnsExpanded: boolean;
    diagramDispatch: DiagramProps["diagramDispatch"];
    edgeFrom: EditEdge | null;
}

type FieldType = "first" | "second" | "rest";

interface State {
    columnRef: (element: HTMLDivElement | null) => void;
    keyHandler: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}

const renderField =
    (props: Props, state: State, type: FieldType) => (column: EditColumn) => {
        const fieldCls = (() => {
            if (type === "rest") return ["flex-1"].join(" ");
            if (type === "first")
                return [
                    "text-[var(--slate11)] overflow-hidden flex-1 px-2 m-[-4px]",
                ].join(" ");
            return [
                "flex-1 overflow-hidden text-right px-2 m-[-4px]",
                "text-[var(--slate11)]",
            ].join(" ");
        })();
        const width = type === "rest" ? TABLE_BODY_WIDTH / 2 : undefined;
        return (
            <div
                className={[fieldCls].join(" ")}
                style={{ width }}
                key={column.id}
                data-row-field={column.value.name}
                ref={state.columnRef}
                onKeyDown={state.keyHandler}
            >
                <RowField
                    tableId={props.tableId}
                    row={props.row}
                    column={column}
                    diagramDispatch={props.diagramDispatch}
                />
            </div>
        );
    };

const makeRemoveRow = (props: Props) => (name: FieldName) => {
    // Remove
    const [tableId, rowId] = [props.tableId, props.row.id];
    const payload: RemoveRowPayload = { rowId, tableId };
    props.diagramDispatch({ type: "remove-row", payload });
    // Select the row below
    props.tableNavigate(props.rowIndex, name, "down");
};

export const RowActive = (props: Props) => {
    const { tableId, row, elements, rowIndex, tableNavigate } = props;
    const { diagramDispatch } = props;
    const { id: rowId, isPrimary } = row;

    const [rowIsPrimary, setRowIsPrimary] = React.useState(isPrimary);
    const ref = React.useRef<HTMLDivElement>(null);

    const [isEdgeDragOver, edgeDropRef] = useDrop({
        accept: EdgeDrag.dragId,
        drop: (): EdgeDragResult => ({ tableId, rowId }),
        collect: (monitor) => monitor.isOver(),
    });

    const columnRef = React.useMemo(() => {
        return makeRowElementsRef(elements, tableId, rowId);
    }, [elements, tableId, rowId]);
    const removeRow = makeRemoveRow(props);
    const keyHandler = makeRowKeyHandler({ rowIndex, tableNavigate, removeRow }); // prettier-ignore
    const state: State = { columnRef, keyHandler };

    const reorderDrag = useReorderDrag({ tableId, rowIndex });
    const reorderDrop = useReorderDrop({ tableId, rowIndex, diagramDispatch });
    reorderDrop.ref(reorderDrag.ref(edgeDropRef(ref)));

    return (
        <RowWrapper {...props}>
            <div
                className={[
                    "h-full flex items-center",
                    "transition-colors",
                    "hover:bg-[var(--slate5)]",
                    isEdgeDragOver ? "bg-[var(--gray6)]" : "",
                    reorderDrop.isOver
                        ? "shadow-[0_-2px_0px_var(--blue8)]"
                        : "",
                    rowIsPrimary ? "font-semibold" : "",
                ].join(" ")}
                ref={ref}
            >
                <div className="h-3 w-3" />
                {props.tableExpanded && (
                    <div data-row-remove ref={columnRef} onKeyDown={keyHandler}>
                        <RowRemoveButton
                            tableId={props.tableId}
                            rowId={props.row.id}
                            removeRow={removeRow}
                        />
                    </div>
                )}

                {/* The first 2 fields are special, their width are flexible
                but the total must be fixed */}
                <div className={"flex"} style={{ width: TABLE_BODY_WIDTH }}>
                    {renderField(props, state, "first")(props.columns[0])}
                    {renderField(props, state, "second")(props.columns[1])}
                </div>

                {/* The rest are rendered with fixed width of their own */}
                {props.columnsExpanded && (
                    <div className={"flex"}>
                        {props.columns
                            .slice(2)
                            .map(renderField(props, state, "rest"))}
                    </div>
                )}

                {props.tableExpanded && (
                    <div data-row-edge ref={columnRef} onKeyDown={keyHandler}>
                        <RowPrimaryKey
                            tableId={props.tableId}
                            row={row}
                            rowIsPrimary={isPrimary}
                            setRowIsPrimary={setRowIsPrimary}
                            diagramDispatch={props.diagramDispatch}
                        />
                    </div>
                )}

                {props.tableExpanded && (
                    <div data-row-edge ref={columnRef} onKeyDown={keyHandler}>
                        <RowEdge
                            tableId={props.tableId}
                            rowId={props.row.id}
                            diagramDispatch={props.diagramDispatch}
                            edge={props.edgeFrom}
                        />
                    </div>
                )}

                <div className="h-3 w-3" />
            </div>
        </RowWrapper>
    );
};
