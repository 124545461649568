import { EditDiagram } from "diagram/model/edit/edit";
import { emptyDiagram } from "diagram/state/state";
import * as React from "react";

/**
 * This is not meant to be used widely as Diagram may changes very often. For
 * example due to table positions or selections. Moreover, the diagram is
 * usually already accessible at the top of the app tree already.
 *
 * So really the only use case for this context is to access the Diagram from
 * further down the tree (like in row > create edge popup) and it MUST be
 * rendered conditionally.
 */
export const __DiagramContext = React.createContext<EditDiagram>(emptyDiagram);
