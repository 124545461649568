import { DiagramElements, DiagramElementsUtils } from "canvas/elements";
import { EditRow, EditTable } from "diagram/model/edit/edit";
import { GRID_CELL } from "geometry/geometry";
import * as React from "react";
import { ROW_HEIGHT } from "../geometry";

export interface RowWrapperProps {
    row: EditRow;
    edgesToCount: number;
    elements: DiagramElements;
    tableId: number;
}

interface Props extends RowWrapperProps {
    children: React.ReactNode;
}

const makeRef =
    (
        elements: DiagramElements,
        tableId: EditTable["id"],
        rowId: EditRow["id"]
    ) =>
    (element: HTMLDivElement | null) => {
        // Note: Do not throw error here if "table" is undefined, because
        // rows are rendered before their table (rows are children of table
        // so it's expected that the table is not set)
        const table =
            elements.tables.get(tableId) ?? DiagramElementsUtils.newTable();
        if (element === null) {
            table.rows.delete(rowId);
            // When table is deleted, table refs is called before rows refs,
            // so avoid creating new tables here
        } else {
            const row = table.rows.get(rowId) ?? DiagramElementsUtils.newRow();
            row.element = element;
            table.rows.set(rowId, row);
            elements.tables.set(tableId, table);
        }
    };

export const RowWrapper = (props: Props) => {
    // Avoid ref being called on every render
    // See: https://github.com/facebook/react/issues/6249
    const [{ elements, tableId }, rowId] = [props, props.row.id];
    const ref = React.useMemo(() => {
        return makeRef(elements, tableId, rowId);
    }, [elements, tableId, rowId]);

    const height =
        ROW_HEIGHT + Math.max(GRID_CELL * (props.edgesToCount - 1), 0);
    return <div ref={ref} style={{ height }} children={props.children} />;
};
