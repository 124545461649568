import { EditDiagram } from "diagram/model/edit/edit";
import { parseDiagram } from "diagram/model/parse/parse";
import { DiagramReducer } from "../reducer";
import { appendDiagram } from "./append";

interface ImportPayload {
    diagram: unknown;
}

export type AppendDiagramPayload = ImportPayload;
export type ReplaceDiagramPayload = ImportPayload;

/**
 * This guarantees that the diagram in payload replaces the one in state
 * directly, without any modification so its identity is kept
 */
export interface ReplaceAsIsDiagramPayload {
    diagram: EditDiagram;
}

export type DiagramDiagramActions =
    | { type: "append"; payload: AppendDiagramPayload }
    | { type: "replace"; payload: ReplaceDiagramPayload }
    | { type: "replace-as-is"; payload: ReplaceAsIsDiagramPayload };

export const diagramDiagramReducer: DiagramReducer = (prev, action) => {
    switch (action.type) {
        case "replace-as-is":
            return action.payload.diagram;
        case "replace": {
            const next = parseDiagram(action.payload.diagram);
            return next;
        }
        case "append": {
            const next = parseDiagram(action.payload.diagram);
            return appendDiagram(prev, next);
        }
        default:
            return prev;
    }
};
