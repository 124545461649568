import { EditRef, EditRefSide, EditTable } from "diagram/model/edit/edit";
import { DiagramReducer } from "../reducer";

export interface RemoveRowPayload {
    tableId: number;
    rowId: number;
}

type Payload = RemoveRowPayload;

const isSideRelated = (payload: Payload, side: EditRefSide): boolean =>
    side.table === payload.tableId && side.rows.includes(payload.rowId);

const isRelated = (payload: Payload, ref: EditRef): boolean =>
    isSideRelated(payload, ref.from) || isSideRelated(payload, ref.to);

const updateTable = (payload: Payload) => (table: EditTable): EditTable => {
    if (table.id !== payload.tableId) return table;
    const rows = table.value.rows.filter((row) => row.id !== payload.rowId);
    return { ...table, value: { ...table.value, rows } };
};

export const removeRow: DiagramReducer<Payload> = (diagram, payload) => {
    const tables = diagram.tables.map(updateTable(payload));
    let refs = diagram.refs.filter((ref) => !isRelated(payload, ref));
    if (refs.length === diagram.refs.length) refs = diagram.refs;
    return { ...diagram, tables, refs };
};
