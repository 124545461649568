import { Toaster } from "react-hot-toast";
import { ErrorBoundary } from "app/error/error";
import { AuthContext, useAuth } from "auth/auth";
import { PreferencesContext, usePreferences } from "preferences/preferences";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { App } from "./app/app";
import "./index.css";

export const VERSION = 26;

const AuthProvider = AuthContext.Provider;
const PrefsProvider = PreferencesContext.Provider;

const Root = () => {
    const prefs = usePreferences();
    const auth = useAuth();
    let children = <App />;
    children = <PrefsProvider value={prefs} children={children} />;
    children = <AuthProvider value={auth} children={children} />;
    children = <ErrorBoundary children={children} />;
    children = <StrictMode children={children} />;
    children = <BrowserRouter children={children} />;
    return (
        <>
            {children}
            <Toaster />
        </>
    );
};

const element = document.getElementById("root");
if (element === null) throw Error("#root is null");
const root = createRoot(element);
root.render(<Root />);
