import { Position } from "geometry/point";
import * as React from "react";

interface Props {
    setTableMenuPosition: TableMenuState["setTableMenuPosition"];
}

export interface TableMenuState {
    tableMenuPosition: Position | null;
    setTableMenuPosition: React.Dispatch<React.SetStateAction<Position | null>>;
}

export const showTableMenu = (props: Props) => (event: React.MouseEvent) => {
    const { target } = event;
    if (target === null) return;
    if (target instanceof HTMLInputElement) return;
    if (target instanceof HTMLTextAreaElement) return;
    event.preventDefault();
    props.setTableMenuPosition({ top: event.clientY, left: event.clientX });
};
