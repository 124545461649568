import { DiagramElementsUtils, FieldName } from "canvas/elements";
import * as Navigate from "canvas/table/active/visible/rows/navigate";

const directions: Record<string, Navigate.TableNavigateDirection | undefined> =
    {
        ArrowUp: "up",
        ArrowRight: "right",
        ArrowDown: "down",
        ArrowLeft: "left",
    };

type Handler = React.KeyboardEventHandler<HTMLDivElement>;

interface Props {
    rowIndex: number;
    removeRow: (name: FieldName) => void;
    tableNavigate: Navigate.TableNavigate;
}

export const makeRowKeyHandler =
    (props: Props): Handler =>
    (event) => {
        if (event.target instanceof HTMLInputElement) return;
        if (event.target instanceof HTMLTextAreaElement) return;

        switch (event.key) {
            case "Backspace":
            case "Delete": {
                const name = DiagramElementsUtils.getFieldName(
                    event.currentTarget
                );
                props.removeRow(name);
                // Done
                event.stopPropagation();
                event.nativeEvent.stopImmediatePropagation();
                event.preventDefault();
                break;
            }
            case "ArrowUp":
            case "ArrowDown":
            case "ArrowLeft":
            case "ArrowRight": {
                const direction = directions[event.key];
                if (direction === undefined) return;
                const name = DiagramElementsUtils.getFieldName(
                    event.currentTarget
                );
                props.tableNavigate(props.rowIndex, name, direction);
                // Done
                event.preventDefault();
                break;
            }
        }
    };
