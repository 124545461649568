import * as Radix from "@radix-ui/react-checkbox";
import * as Label from "@radix-ui/react-label";
import { Icon, Icons } from "utilities/icon";

export const checkboxDisabledStyles = [
    //
    "text-gray-400",
    "disabled:data-checked:bg-gray-400",
    "disabled:data-unchecked:active:bg-gray-100",
].join(" ");

interface Props extends Radix.CheckboxProps {
    id?: string;
}

export const Checkbox = (props: Props): JSX.Element => {
    const { id, disabled, children, ...root } = props;

    return (
        <div
            className={[
                //
                "flex gap-1 items-center",
                disabled && checkboxDisabledStyles,
            ].join(" ")}
        >
            <Radix.Root
                id={id}
                disabled={disabled}
                {...root}
                className={[
                    "cursor-pointer",
                    //
                    "data-unchecked:bg-[var(--slate7)] data-unchecked:text-[var(--slate10)]",
                    "data-unchecked:active:bg-[var(--slate8)]",
                    "data-checked:bg-[var(--blue9)] data-checked:text-[var(--slate12)]",
                    "data-checked:active:bg-[var(--blue10)] data-checked:text-[var(--slate12)]",
                    "w-4 h-4 rounded",
                    checkboxDisabledStyles,
                ].join(" ")}
            >
                <Radix.Indicator>
                    <Icon
                        icon={Icons.check}
                        size={16}
                        className="w-4 h-4 text-current"
                    />
                </Radix.Indicator>
            </Radix.Root>
            <Label.Root className="text-[var(--slate12)]" htmlFor={id}>
                {children}
            </Label.Root>
        </div>
    );
};
