import { TABLE_BODY_WIDTH } from "canvas/table/geometry";
import { EditTable } from "diagram/model/edit/edit";
import { RenameTablePayload } from "diagram/state/reducer/table/rename";
import { DiagramProps } from "diagram/state/state";
import { Point } from "geometry/point";
import { Input } from "input/input";
import * as React from "react";
import { TableExpandedProps } from "../expand";

interface Props extends TableExpandedProps {
    table: EditTable;
    diagramDispatch: DiagramProps["diagramDispatch"];
}

const dispatchRename = (props: Props, value: string) => {
    if (value === props.table.value.name) return;
    const payload: RenameTablePayload = { id: props.table.id, name: value };
    props.diagramDispatch({ type: "rename-table", payload });
};

const View = (props: Props & { state: State }) => (
    <div
        className={[
            "leading-normal overflow-hidden text-ellipsis whitespace-nowrap",
        ].join(" ")}
        onMouseDown={(event) => {
            props.state.offset.current.x = event.clientX;
            props.state.offset.current.y = event.clientY;
        }}
        onClick={(event) => {
            const { x: x1, y: y1 } = props.state.offset.current;
            const { clientX: x2, clientY: y2 } = event;
            // Only enter edit mode if this is not a drag
            if (x1 === x2 && y1 === y2) props.state.setIsEdit(true);
        }}
        children={props.table.value.name}
    />
);

const Edit = (props: Props & { state: State }) => (
    <Input
        key={props.table.value.name} // uncontrolled
        defaultValue={props.table.value.name}
        onBlur={(event) => {
            dispatchRename(props, event.currentTarget.value);
            props.state.setIsEdit(false);
        }}
        onFocus={() => {
            props.setTableExpanded(false);
        }}
        onKeyUp={(event) => {
            if (event.key === "Enter") {
                dispatchRename(props, event.currentTarget.value);
                props.state.setIsEdit(false);
            }
        }}
        autoFocus
    />
);

interface State {
    setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
    offset: React.MutableRefObject<Point>;
}

export const TableTitle = (props: Props) => {
    // TableTitleInput has 2 states to allow users to drag the table via its
    // title, which is a very common behavior
    const [isEdit, setIsEdit] = React.useState(false);

    // Because it is draggable, this helps us identify if a drag happened or
    // just a click to prevent entering edit mode when it's a drag
    const offset = React.useRef({ x: 0, y: 0 });

    const state: State = { setIsEdit, offset };

    return (
        <div
            className={[
                "p-2 relative",
                props.tableExpanded ? "px-[calc(8px_+_30px)]" : "",
                "random text-[var(--slate12)]",
            ].join(" ")}
        >
            <div
                className={[
                    "transition-transform text-center font-semibold flex-0",
                    props.tableExpanded ? "-translate-y-2" : "",
                ].join(" ")}
                style={{ width: TABLE_BODY_WIDTH }}
            >
                {isEdit ? (
                    <Edit {...props} state={state} />
                ) : (
                    <View {...props} state={state} />
                )}
            </div>
        </div>
    );
};
