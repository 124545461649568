import { EDGE_CREATE_FORM_ID } from "canvas/edge/create/form";
import { EdgeDrag } from "canvas/edge/drag/drag";

const collapse = (state: State, target: unknown) => {
    if (!(target instanceof Element)) return;
    const container = state.containerRef.current;
    if (container === null) throw Error("Table container is null");

    // If still inside Table
    if (container.contains(target)) return;

    // If blur because of EdgeCreatePopup
    const isInsideForm = (element: unknown): boolean => {
        if (!(element instanceof Element)) return false;
        return element.closest(`#${EDGE_CREATE_FORM_ID}`) !== null;
    };
    if (isInsideForm(target)) return;

    // If blur because of EdgeDrag
    const drag = target.closest(`.${EdgeDrag.dragElementClass}`);
    if (drag instanceof HTMLDivElement && drag.dataset["dragging"] === "true")
        return;

    // All else -> turn off Edit mode
    state.setTableExpanded(false);
};

const expand = (state: State) => (event: React.FocusEvent) => {
    const related = event.relatedTarget as Node | null;
    if (event.currentTarget.contains(related)) return;
    if (event.target instanceof HTMLButtonElement) return;
    state.setTableExpanded(true);
};

export interface TableExpandedProps {
    tableExpanded: boolean;
    setTableExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

interface State {
    setTableExpanded: TableExpandedProps["setTableExpanded"];
    containerRef: React.RefObject<HTMLElement>;
}

export type TableExpandedState = State;

export const TableExpanded = {
    collapse,
    expand,
};
