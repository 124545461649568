import { BaseDiagramUnknown } from "./base";
import { BaseDiagramV1 } from "./base-v1";

export interface BaseDiagramV2 extends BaseDiagramV1 {
    type: "base";
    version: 2;
}

export const isBaseDiagramV2 = (
    diagram: BaseDiagramUnknown
): diagram is BaseDiagramV2 => {
    return "version" in diagram && diagram.version === 2;
};

export const migrateBaseDiagramV1toV2 = (v1: BaseDiagramV1): BaseDiagramV2 => ({
    ...v1,
    type: "base",
    version: 2,
});
