import { Icons } from "utilities/icon";
import { Preferences, PreferencesContext } from "preferences/preferences";
import * as React from "react";
import { Button } from "app/button/button";

const toggle = (prefs: Preferences): Preferences => ({
    ...prefs,
    libraryVisible: !prefs.libraryVisible,
});

export const HeaderLibrary = () => {
    const prefs = React.useContext(PreferencesContext);
    const selected = prefs.preferences.libraryVisible;
    return (
        <Button
            onClick={() => void prefs.setPreferences(toggle)}
            selected={selected}
            icon={Icons.menuOpen}
            color="solid"
        />
    );
};
