import { DiagramElements } from "canvas/elements";
import { DiagramProps } from "diagram/state/state";
import * as React from "react";
import { Edge } from "../edge/edge";
import { EdgePaths, EdgePathsMap, getEdgePaths } from "../path/path";
import { EdgeCanvasMarkers } from "./marker/marker";
import { useEdgesCanvasObserve } from "./observe/observe";

// It's good to depend on the whole "diagram" since many common changes in
// Diagram require re-draw (add row, move table, selection)
interface Props extends DiagramProps {
    elements: DiagramElements;
    isCanvasActive: boolean;
}

const emptyPaths: EdgePaths = {
    from: [],
    to: [],
    main: "",
    mainLength: 0,
};

/**
 * Unlike Tables, Edges should be drawn/rendered at once (i.e. not
 * indepentdently) because they highly depend on others
 */
export const EdgesCanvas = (props: Props) => {
    const [paths, setPaths] = React.useState<EdgePathsMap | null>(null);
    const { diagram, diagramDispatch, elements, isCanvasActive } = props;

    const domChanged = useEdgesCanvasObserve(diagram.refs, elements);

    const timeoutId = React.useRef(-1);
    React.useEffect(() => {
        window.clearTimeout(timeoutId.current);
        // To ensure all DOM modifications are done (e.g. tables' translations
        // are reset)
        timeoutId.current = window.setTimeout(() => {
            setPaths(getEdgePaths({ edges: diagram.refs, elements }));
        }, 0);
        return () => window.clearTimeout(timeoutId.current);
    }, [diagram, elements, domChanged]);

    if (paths === null) return null;

    return (
        <svg
            className={["absolute top-0 left-0 z-0 pointer-events-none"].join(
                " "
            )}
            xmlns="http://www.w3.org/2000/svg"
            width={diagram.size.width}
            height={diagram.size.height}
        >
            <EdgeCanvasMarkers />
            {diagram.refs.map((edge) => (
                <Edge
                    key={edge.id}
                    edge={edge}
                    paths={paths.get(edge.id) ?? emptyPaths}
                    elements={elements}
                    isCanvasActive={isCanvasActive}
                    diagramDispatch={diagramDispatch}
                />
            ))}
        </svg>
    );
};
