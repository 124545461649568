import { Icons } from "utilities/icon";
import { DiagramHistoryProps } from "diagram/state/state";
import { ButtonGroup } from "app/button/group";
import { Button } from "app/button/button";

interface Props extends DiagramHistoryProps {}

export const HeaderHistory = (props: Props) => (
    <div className={["border border-[var(--slate7)] rounded"].join(" ")}>
        <ButtonGroup>
            {[
                <Button
                    key="undo"
                    onClick={() =>
                        props.diagramHistoryDispatch({ type: "undo" })
                    }
                    icon={Icons.undo}
                    disabled={props.diagramHistory.past.length === 0}
                    color="clear"
                />,
                <Button
                    key="redo"
                    onClick={() =>
                        props.diagramHistoryDispatch({ type: "redo" })
                    }
                    icon={Icons.redo}
                    disabled={props.diagramHistory.future.length === 0}
                    color="clear"
                />,
            ]}
        </ButtonGroup>
    </div>
);
