import React from "react";
import { EditDiagram } from "../model/edit/edit";
import { parseDiagram } from "../model/parse/parse";
import emptyJson from "../samples/empty.json";
import { DiagramAction, diagramReducer } from "./reducer/reducer";
import * as Undo from "./undo";

export interface DiagramProps {
    diagram: EditDiagram;
    diagramDispatch: React.Dispatch<DiagramAction>;
}

export interface DiagramHistoryProps {
    diagramHistory: Undo.UndoState<EditDiagram>;
    diagramHistoryDispatch: React.Dispatch<DiagramAction | Undo.UndoAction>;
}

const undoReducer = Undo.createUndoReducer(diagramReducer);
export const emptyDiagram = parseDiagram(emptyJson);
const initialDiagram = emptyDiagram

export const useDiagram = (): DiagramHistoryProps => {
    const state = React.useReducer(undoReducer, undefined, () => {
        return Undo.createUndoInitialState(initialDiagram);
    });
    const [diagramHistory, diagramHistoryDispatch] = state;
    return { diagramHistory, diagramHistoryDispatch };
};
