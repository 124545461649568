import { RowEdges, TableEdges } from "canvas/edges-map";
import { DiagramElements } from "canvas/elements";
import { RowActive } from "canvas/row/active/active";
import { groupTableRows } from "canvas/table/rows";
import { EditColumn, EditRow, EditTable } from "diagram/model/edit/edit";
import { DiagramProps } from "diagram/state/state";
import * as React from "react";
import { TableRowsGroup } from "./hidden";
import { makeTableNavigate, TableNavigate } from "./navigate";

interface Props {
    // Self
    table: EditTable;
    tableEdges: TableEdges | null;
    tableExpanded: boolean;
    columnsExpanded: boolean;
    // Diagram
    columns: EditColumn[];
    elements: DiagramElements;
    diagramDispatch: DiagramProps["diagramDispatch"];
}

interface RowProps extends Props {
    row: EditRow;
    rowIndex: number;
    tableNavigate: TableNavigate;
}

const Row = (props: RowProps) => {
    const edges: RowEdges | undefined = props.tableEdges?.get(props.row.id);

    return (
        <RowActive
            // Self
            row={props.row}
            rowIndex={props.rowIndex}
            edgeFrom={edges?.from ?? null}
            edgesToCount={edges?.to.length ?? 0}
            // Table
            tableNavigate={props.tableNavigate}
            tableExpanded={props.tableExpanded}
            tableId={props.table.id}
            columnsExpanded={props.columnsExpanded}
            // Diagram
            elements={props.elements}
            diagramDispatch={props.diagramDispatch}
            columns={props.columns}
        />
    );
};

export const TableVisibleRows = (props: Props) => {
    const { table, tableEdges, columns, elements, diagramDispatch } = props;
    const { id: tableId } = table;

    const [rows] = React.useMemo(() => {
        return groupTableRows(table, tableEdges);
    }, [table, tableEdges]);

    const navigate = React.useMemo(() => {
        return makeTableNavigate({ rows, tableId, columns, elements });
    }, [columns, rows, elements, tableId]);

    return (
        <div>
            {rows.map((item, index) =>
                Array.isArray(item) ? (
                    <TableRowsGroup
                        key={item[0].id}
                        count={item.length}
                        diagramDispatch={diagramDispatch}
                        tableId={tableId}
                    />
                ) : (
                    <Row
                        key={item.id}
                        {...props}
                        row={item}
                        rowIndex={index}
                        tableNavigate={navigate}
                    />
                )
            )}
        </div>
    );
};
