import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { ReactNode } from "react";
import { SetState } from "utilities/set-state";
import { Button } from "app/button/button";

interface AlertButton {
    title: string;
    style: string;
    onClick?: () => void;
}

interface Props {
    title: string;
    description: string;
    buttons?: AlertButton[];
    cancel?: string;
    open?: boolean;
    setOpen?: SetState<boolean>;
    children?: ReactNode;
    onCancel?: () => void;
}

export const Alert = (props: Props) => {
    const { title, description, cancel, buttons, children } = props;
    const { open, setOpen, onCancel: onDone } = props;

    return (
        <AlertDialog.Root open={open} onOpenChange={setOpen}>
            <AlertDialog.Trigger asChild>{children}</AlertDialog.Trigger>
            <AlertDialog.Portal>
                <AlertDialog.Overlay className="bg-black opacity-70 fixed inset-0" />
                <AlertDialog.Content
                    className={[
                        "bg-white rounded-lg p-4 focus:outline-none shadow-lg AlertDialogContent",
                        "fixed top-[50%] left-[50%] min-w-[384px] min-h-[128px] max-w-[384px] max-h-[85%]",
                        "translate-x-[-50%] translate-y-[-50%]",
                    ].join(" ")}
                >
                    <AlertDialog.Title
                        className={[
                            "m-0 text-gray-800 font-medium",
                            "flex justify-center text-center",
                        ].join(" ")}
                    >
                        {title}
                    </AlertDialog.Title>
                    <AlertDialog.Description
                        className={[
                            "mb-2 mt-2",
                            "text-gray-600 text-15",
                            "flex-wrap text-center",
                        ].join(" ")}
                    >
                        {description}
                    </AlertDialog.Description>
                    <div className="grow p-2" />
                    <div className="flex gap-2 justify-end">
                        {cancel && (
                            <AlertDialog.Cancel asChild>
                                <Button
                                    key="cancel-alert"
                                    color={buttons ? "gray" : "blue"}
                                    extraStyle={buttons ? "" : "grow"}
                                    onClick={onDone}
                                >
                                    {cancel}
                                </Button>
                            </AlertDialog.Cancel>
                        )}
                        {buttons && (
                            <div className="flex gap-2 justify-end">
                                {buttons.map((button, index) => (
                                    <AlertDialog.Action asChild>
                                        <Button
                                            key={`${index}`}
                                            color="blue"
                                            extraStyle={button.style}
                                            onClick={button.onClick}
                                        >
                                            {button.title}
                                        </Button>
                                    </AlertDialog.Action>
                                ))}
                                <div className="grow" />
                            </div>
                        )}
                    </div>
                </AlertDialog.Content>
            </AlertDialog.Portal>
        </AlertDialog.Root>
    );
};
