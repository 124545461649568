import { TABLE_MARGIN_DOUBLE as MARGIN_DOUBLE } from "canvas/table/geometry";
import { Position } from "geometry/geometry";
import { EditDiagram, EditTable } from "../model/edit/edit";
import { MasonryMap } from "./masonry";

interface Params {
    originalDiagram: EditDiagram;
    diagrams: EditDiagram[];
    masonry: MasonryMap;
}

interface Size {
    width: number;
    height: number;
}

const getSize = ({ diagrams, masonry }: Params): Size => {
    let [width, height] = [0, 0];
    diagrams.forEach((diagram) => {
        const position = masonry.get(diagram.tables[0].id);
        if (position === undefined) throw Error(`position is undefined`);
        const right = position.left + diagram.size.width;
        width = Math.max(width, right);
        const bottom = position.top + diagram.size.height;
        height = Math.max(height, bottom);
    });

    width = Math.max(window.screen.availWidth + MARGIN_DOUBLE, width);
    height = Math.max(window.screen.availHeight + MARGIN_DOUBLE, height);

    return { width, height };
};

const getTables = (params: Params): EditTable[] => {
    const tables: EditTable[] = [];
    params.diagrams.forEach((diagram) => {
        const translate = params.masonry.get(diagram.tables[0].id);
        if (translate === undefined) throw Error("translate is undefined");
        diagram.tables.forEach((prev) => {
            const position: Position = {
                top: translate.top + prev.position.top,
                left: translate.left + prev.position.left,
            };
            tables.push({ ...prev, position });
        });
    });
    if (tables.length === params.originalDiagram.tables.length) return tables;
    throw Error("new tables and old tables have different length");
};

export const mergeDiagram = (params: Params): EditDiagram => ({
    type: "edit",
    version: params.originalDiagram.version,
    columns: params.originalDiagram.columns,
    tables: getTables(params),
    refs: params.originalDiagram.refs,
    size: getSize(params),
    schema: params.originalDiagram.schema,
});
