import { Button } from "app/button/button";
import { Dialog } from "app/dialog/dialog";
import { AuthContext } from "auth/auth";
import { useContext } from "react";

interface Props {
    onDone?: () => void;
    open?: boolean;
    setOpen?: (open: boolean) => void;
}

export const SharedPromptLoginDialog = (props: Props) => {
    const { loginWithGithub, loginWithGoogle } = useContext(AuthContext);
    const { open, setOpen } = props;

    return (
        <Dialog.Root open={open} onOpenChange={setOpen}>
            <Dialog.Content>
                <>
                    <div className={["p-4 text-center"].join(" ")}>
                        <Dialog.Title className="font-semibold text-17">
                            Sharing a diagram requires logging in
                        </Dialog.Title>
                        <Dialog.Description>
                            <p>You must login to use this feature.</p>
                        </Dialog.Description>
                    </div>

                    <div
                        className={["flex justify-center gap-2 p-4"].join(" ")}
                    >
                        <Dialog.Close asChild>
                            <Button
                                color="blue"
                                children="Login with GitHub"
                                onClick={() => {
                                    loginWithGithub();
                                    props.onDone?.();
                                }}
                            />
                        </Dialog.Close>
                        <Dialog.Close asChild>
                            <Button
                                color="blue"
                                children="Login with Google"
                                onClick={() => {
                                    loginWithGoogle();
                                    props.onDone?.();
                                }}
                            />
                        </Dialog.Close>
                    </div>
                </>
            </Dialog.Content>
        </Dialog.Root>
    );
};
