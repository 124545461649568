import { DiagramElements } from "canvas/elements";
import dragscroll from "dragscroll";
import * as React from "react";

const getEventElement = (event: KeyboardEvent): HTMLDivElement => {
    const element = event.currentTarget;
    if (element instanceof HTMLDivElement) return element;
    throw Error("Element is not div");
};

const onKeyUp = (event: KeyboardEvent) => {
    const element = getEventElement(event);
    element.addEventListener("keydown", onKeyDown);
    element.removeEventListener("keyup", onKeyUp);
    element.classList.remove("cursor-grab");
    dragscroll.reset();
};

const onKeyDown = (event: KeyboardEvent) => {
    if (event.key !== " ") return;
    if (event.target instanceof HTMLInputElement) return;
    if (event.target instanceof HTMLTextAreaElement) return;
    const element = getEventElement(event);
    element.addEventListener("keyup", onKeyUp);
    element.removeEventListener("keydown", onKeyDown);
    element.classList.add("cursor-grab");
    dragscroll.reset();
};

// @TODO: Merge this with onKeyDown handler?
const preventDefault = (event: KeyboardEvent) => {
    if (event.key === " ") {
        if (event.target instanceof HTMLInputElement) return;
        if (event.target instanceof HTMLTextAreaElement) return;
        event.preventDefault();
    }
};

export const useDragToScroll = (elements: DiagramElements): void => {
    React.useEffect(() => {
        const element = elements.viewport;
        if (element === null) throw Error("Element is null");
        element.addEventListener("keydown", preventDefault);
        return () => element.removeEventListener("keydown", preventDefault);
    }, [elements]);
    React.useEffect(() => {
        const element = elements.viewport;
        if (element === null) throw Error("Element is null");
        element.addEventListener("keydown", onKeyDown);
        return () => element.removeEventListener("keydown", onKeyDown);
    }, [elements]);
};
