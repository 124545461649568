import * as React from "react";
import { MenuItem } from "./item";

export interface MenuItemAction {
    label: string;
    fn?: () => void;
    disabled?: boolean;
}

export type MenuItemProps = MenuItemAction | "divider";

interface Props {
    items: MenuItemProps[];
    onEsc?: () => void;
}

export const Menu = (props: Props): JSX.Element => {
    const { onEsc } = props;

    const ref = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (onEsc === undefined) return;
        const listener = (event: MouseEvent) => {
            const element = ref.current;
            if (!(event.target instanceof Node)) return;
            if (element?.contains(event.target)) return;
            onEsc();
        };
        document.addEventListener("click", listener);
        return () => document.removeEventListener("click", listener);
    }, [onEsc]);

    return (
        <div
            className={[
                "p-1 rounded-lg bg-gray-900 text-white shadow-xl",
                "min-w-[100px]",
            ].join(" ")}
            ref={ref}
        >
            <div className={"h-3 w-3"} />
            {props.items.map((item, index) => (
                <MenuItem key={index} item={item} />
            ))}
            <div className={"h-3 w-3"} />
        </div>
    );
};
