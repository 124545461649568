import { EditTable } from "diagram/model/edit/edit";
import { DiagramReducer } from "../reducer";

export interface RenameTablePayload {
    id: number;
    name: string;
}

type Payload = RenameTablePayload;

const rename = (payload: Payload) => (table: EditTable): EditTable => {
    if (table.id !== payload.id) return table;
    return { ...table, value: { ...table.value, name: payload.name } };
};

export const renameTable: DiagramReducer<Payload> = (diagram, payload) => {
    const tables = diagram.tables.map(rename(payload));
    return { ...diagram, tables };
};
