import { DiagramElements } from "canvas/elements";
import { EditEdge, EditTable } from "diagram/model/edit/edit";
import * as G from "geometry/geometry";
import { subtractPosition } from "geometry/geometry";

/*
This module set the temporary classes to apply/unapply the selected style to
tables and refs while dragging. This is temporary because it only applies to
the dom elements without dispatch the actual update to React's state
*/

type Id = EditTable["id"];

interface StateTable {
    id: Id;
    box: G.Box;
    element: HTMLElement;
}

interface StateEdge {
    model: EditEdge;
    element: SVGElement;
}

interface State {
    edges: StateEdge[];
    tables: StateTable[];
}

export type SelectOverlapState = State;

interface Props {
    offset: G.Position;
    elements: DiagramElements;
    edges: EditEdge[];
}

const init = (props: Props): State => ({
    tables: [...props.elements.tables.entries()].map((entry) => {
        const [id, table] = entry;
        const { element } = table;
        if (element === null) throw Error("Table is null");
        const rect = element.getBoundingClientRect();
        const { width, height } = rect;
        const { top, left } = subtractPosition(rect, props.offset);
        const box: G.Box = { width, height, top, left };
        return { id, box, element };
    }),
    edges: props.edges.map((model) => {
        const element = props.elements.edges.get(model.id);
        if (element === undefined) throw Error("Edge is undefined");
        return { model, element };
    }),
});

const CLASS = "temp-selected";
export const TEMP_SELECTED_CLASS = CLASS;

const setStyle = (state: State, target: G.Box): void => {
    // @TODO: Bi-search for faster selection?
    const overlaps: Set<Id> = new Set();
    state.tables.forEach((table) => {
        const isOverlap = G.isBoxesOverlap(target, table.box);
        if (isOverlap) overlaps.add(table.id);
        table.element.classList.toggle(CLASS, isOverlap);
    });
    state.edges.forEach((edge) => {
        const [from, to] = [edge.model.from.table, edge.model.to.table];
        const isOverlap = overlaps.has(from) || overlaps.has(to);
        edge.element.classList.toggle(CLASS, isOverlap);
    });
};

/**
 * We only need to return Tables here as the Diagram reducer will
 * automatically select related edges for us
 */
const getMatched = (state: State, target: G.Box): Id[] => {
    return state.tables
        .filter((table) => G.isBoxesOverlap(table.box, target))
        .map((table) => table.id);
};

const clear = (state: State): void => {
    state.tables.forEach((table) => {
        table.element.classList.remove(CLASS);
    });
    state.edges.forEach((edge) => {
        edge.element.classList.remove(CLASS);
    });
};

export const SelectOverlap = { init, setStyle, getMatched, clear };
