import { DiagramElements } from "canvas/elements";
import { EditEdge } from "diagram/model/edit/edit";
import * as React from "react";
import { useEdgeCanvasTables } from "./memo";

/**
 * EdgesCanvas already depends on "diagram". However, there are local states
 * that may require re-draw edges (such as Table's columnsExpand) that we
 * should observe manually here.
 */
export const useEdgesCanvasObserve = (
    edges: EditEdge[],
    elements: DiagramElements
): boolean => {
    const [changed, setChanged] = React.useState(false);

    // Ad-hoc optimization to avoid creating new observer unnecessarily
    const tables = useEdgeCanvasTables(edges);

    React.useEffect(() => {
        // Watch for Tables' sizes changes
        const observer = new window.ResizeObserver((entry) => {
            setChanged((bool) => !bool);
        });

        tables.forEach((table) => {
            const element = elements.tables.get(table)?.element ?? null;
            if (element === null) throw Error("Table is null");
            observer.observe(element);
        });

        return () => observer.disconnect();
    }, [tables, elements]);
    return changed;
};
