import { CanvasPreviewDrag } from "canvas/canvas/active/drag";
import { TableEdges } from "canvas/edges-map";
import { DiagramElements } from "canvas/elements";
import { EditColumn } from "diagram/model/edit/edit";
import { DiagramProps } from "diagram/state/state";
import * as React from "react";
import { TableWrapper, TableWrapperProps } from "../wrapper/wrapper";
import { TableInvisible } from "./invisible/invisible";
import { TableMenuState } from "./visible/menu/menu";
import { TableVisible } from "./visible/visible";

interface Props extends TableWrapperProps {
    columns: EditColumn[];
    diagramDispatch: DiagramProps["diagramDispatch"];
    previewDrag: CanvasPreviewDrag;
    tableEdges: TableEdges | null;
    setTableMenuPosition: TableMenuState["setTableMenuPosition"];
}

const createObserver = (
    elements: DiagramElements,
    id: number,
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
): (() => void) => {
    const callback: IntersectionObserverCallback = (entries) => {
        entries.forEach((entry) => {
            setVisible(entry.isIntersecting);
        });
    };
    const observer = new IntersectionObserver(callback, {
        threshold: 0,
        root: elements.viewport,
    });
    const table = elements.tables.get(id)?.element ?? null;
    if (table === null) throw Error("Table is null");
    observer.observe(table);
    return () => observer.disconnect();
};

/**
 * Table to render in the canvas editor (no capturing mode). This will render
 * either TableVisible or TableInvisible depend on whether the Table is inside
 * the viewport or not
 */
export const TableActive = (props: Props) => {
    const [visible, setVisible] = React.useState(false);

    const { elements } = props;
    const { id } = props.table;
    React.useEffect(() => {
        const disconnect = createObserver(elements, id, setVisible);
        return disconnect;
    }, [elements, id]);

    return (
        <TableWrapper {...props}>
            {visible ? (
                <TableVisible
                    // Self
                    table={props.table}
                    tableEdges={props.tableEdges}
                    // Diagram
                    setTableMenuPosition={props.setTableMenuPosition}
                    columns={props.columns}
                    diagramDispatch={props.diagramDispatch}
                    previewDrag={props.previewDrag}
                    elements={props.elements}
                />
            ) : (
                <TableInvisible
                    table={props.table}
                    tableEdges={props.tableEdges}
                    elements={props.elements}
                />
            )}
        </TableWrapper>
    );
};
