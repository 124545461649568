import { DiagramReducer } from "../reducer";

export interface RemoveRefPayload {
    id: number;
}

type Reducer = DiagramReducer<RemoveRefPayload>;

export const removeRef: Reducer = (diagram, payload) => {
    const refs = diagram.refs.filter((ref) => ref.id !== payload.id);
    return { ...diagram, refs };
};
