import { EditDiagram } from "diagram/model/edit/edit";
import { stringifyDiagram } from "diagram/model/stringify/stringify";
import * as React from "react";

interface Props {
    diagram: EditDiagram;
}

type Event = React.ClipboardEvent;

// @TODO: Extract this to somewhere?
export const getCanvasCopyText = (prev: EditDiagram) => {
    const diagram = { ...prev };
    diagram.tables = diagram.tables.filter((table) => table.selected);
    const selected = new Set(diagram.tables.map((table) => table.id));
    diagram.refs = diagram.refs.filter((ref) => {
        return selected.has(ref.from.table) && selected.has(ref.to.table);
    });
    return stringifyDiagram(diagram, "edit", "json");
};

export const onCanvasCopy = (props: Props) => (event: Event) => {
    if (event.target instanceof HTMLInputElement) return;
    if (event.target instanceof HTMLAreaElement) return;
    event.preventDefault();
    const text = getCanvasCopyText(props.diagram);
    event.clipboardData?.setData("text/plain", text);
};
