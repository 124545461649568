import { Border } from "border/border";
import { CanvasPreviewDrag } from "canvas/canvas/active/drag";
import { TableEdges } from "canvas/edges-map";
import { DiagramElements } from "canvas/elements";
import { EditColumn, EditTable } from "diagram/model/edit/edit";
import { MoveSelectedTablesPayload } from "diagram/state/reducer/table/move";
import { SelectSomeTablesPayload } from "diagram/state/reducer/table/select/some";
import { DiagramProps } from "diagram/state/state";
import { GRID_CELL, roundNumber } from "geometry/geometry";
import { PreferencesContext } from "preferences/preferences";
import * as React from "react";
import Draggable, { DraggableEventHandler } from "react-draggable";
import { TableAddRow } from "./add/add";
import { TableColumns } from "./columns/columns";
import { TableExpanded, TableExpandedState } from "./expand";
import { showTableMenu, TableMenuState } from "./menu/menu";
import { TableVisibleRows } from "./rows/rows";
import { TableTitle } from "./title/title";
import "./visible.css";

interface Props {
    table: EditTable;
    tableEdges: TableEdges | null;
    // Diagram
    setTableMenuPosition: TableMenuState["setTableMenuPosition"];
    previewDrag: CanvasPreviewDrag;
    elements: DiagramElements;
    columns: EditColumn[];
    diagramDispatch: DiagramProps["diagramDispatch"];
}

const selectTable =
    (props: Props) =>
    (event: MouseEvent): void => {
        if (props.table.selected) return;
        const append = event.shiftKey || event.ctrlKey || event.metaKey;
        const tableIds = new Set([props.table.id]);
        const payload: SelectSomeTablesPayload = { tableIds, append };
        props.diagramDispatch({ type: "select-some-tables", payload });
    };

const dispatchMoves =
    (props: Props): DraggableEventHandler =>
    (_, data) => {
        const [top, left] = [roundNumber(data.y), roundNumber(data.x)];
        if (left === 0 && top === 0) return;
        const payload: MoveSelectedTablesPayload = { top, left };
        props.diagramDispatch({ type: "move-selected-tables", payload });
        props.previewDrag(props.table.id, { top: 0, left: 0 });
    };

const previewMoves =
    (props: Props): DraggableEventHandler =>
    (_, data) => {
        const [top, left] = [roundNumber(data.y), roundNumber(data.x)];
        props.previewDrag(props.table.id, { top, left });
    };

/**
 * Table with full functionalities. To render when it's inside viewport.
 */
export const TableVisible = (props: Props) => {
    const { setTableMenuPosition } = props;

    const containerRef = React.useRef<HTMLDivElement>(null);
    const scale = React.useContext(PreferencesContext).preferences.zoom;
    const [tableExpanded, setTableExpanded] = React.useState(false);
    const [columnsExpanded, setColumnsExpanded] = React.useState(false);

    const state: TableExpandedState = React.useMemo(() => {
        return { setTableExpanded, containerRef };
    }, [setTableExpanded, containerRef]);

    React.useEffect(() => {
        if (tableExpanded === false) return;
        // Safari doesn't have blur events for buttons so we need to work with
        // mouse events attached to document
        // https://stackoverflow.com/q/61245883/6621213
        const listener = (event: MouseEvent) => {
            TableExpanded.collapse(state, event.target);
        };
        document.addEventListener("mouseup", listener);
        return () => document.removeEventListener("mouseup", listener);
    }, [state, tableExpanded]);

    return (
        <Draggable
            position={{ x: 0, y: 0 }}
            grid={[GRID_CELL, GRID_CELL]}
            onDrag={previewMoves(props)}
            onStop={dispatchMoves(props)}
            nodeRef={containerRef}
            scale={scale}
            cancel="input, button, select, .react-draggable-cancel"
            onMouseDown={selectTable(props)}
        >
            <div
                className={[
                    "table-container",
                    "border-solid border-[var(--slate9)] border-[1px] cursor-grab transition-outline",
                    "bg-[var(--slate2)]",
                    "shadow-[0_1px_4px_rgba(0,0,0,0.1)]",
                    props.table.selected
                        ? "outline outline-2 outline-[var(--blue10)]"
                        : "outline-8",
                    tableExpanded ? "-m-[30px]" : "",
                ].join(" ")}
                onContextMenu={showTableMenu({ setTableMenuPosition })}
                ref={containerRef}
            >
                <TableTitle
                    table={props.table}
                    diagramDispatch={props.diagramDispatch}
                    tableExpanded={tableExpanded}
                    setTableExpanded={setTableExpanded}
                />
                <Border color="weak" />
                <div
                    className={"relative"}
                    onFocus={TableExpanded.expand(state)}
                >
                    <div className="h-3 w-3" />
                    {/* Manually fix to align edges to grid */}
                    <div style={{ height: 3 }} />
                    {tableExpanded && (
                        <div
                            className={[
                                "absolute bottom-[calc(100%_-_8px)] left-0 w-full z-1",
                            ].join(" ")}
                        >
                            <TableColumns
                                columns={props.columns}
                                columnsExpanded={columnsExpanded}
                                setColumnsExpanded={setColumnsExpanded}
                            />
                        </div>
                    )}
                    <TableVisibleRows
                        // Self
                        table={props.table}
                        tableEdges={props.tableEdges}
                        columnsExpanded={columnsExpanded}
                        tableExpanded={tableExpanded}
                        // Diagram
                        columns={props.columns}
                        elements={props.elements}
                        diagramDispatch={props.diagramDispatch}
                    />
                    {/* Manually fix to keep Table height matches grid */}
                    <div style={{ height: 4 }} />
                    {(tableExpanded || props.table.value.rows.length === 0) && (
                        <TableAddRow
                            table={props.table}
                            diagramDispatch={props.diagramDispatch}
                            columns={props.columns}
                            elements={props.elements}
                        />
                    )}
                    <div className="h-3 w-3" />
                </div>
            </div>
        </Draggable>
    );
};
