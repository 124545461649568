import { AuthContext } from "auth/auth";
import { Border } from "border/border";
import { DiagramElementsUtils } from "canvas/elements";
import { __DiagramContext } from "diagram/context/context";
import { DiagramProps, useDiagram } from "diagram/state/state";
import { isAppHost } from "host/host";
import { useLibrary } from "library/library";
import { PreferencesContext } from "preferences/preferences";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { AppCanvas } from "./canvas/canvas";
import { AppCanvasEmpty } from "./canvas/empty/empty";
import { AppCanvasProgress } from "./canvas/progress/progress";
import { useDiagramHostCallbacks } from "./effects/host";
import { useDiagramSync } from "./effects/sync";
import { Footer } from "./footer/footer";
import { Header } from "./header/header";
import { AppLibrary } from "./library/library";
import { NotFound } from "./not-found/not-found";
import { ProgressCircle } from "./progress/circle";
import { AppResizer } from "./resizer/resizer";
import { SharedCanvas } from "./shared/canvas/canvas";

const initialElements = DiagramElementsUtils.newDiagram();

export const App = () => {
    const prefs = React.useContext(PreferencesContext).preferences;

    const { isLoadingAuth } = React.useContext(AuthContext);

    // Diagram
    const { diagramHistory, diagramHistoryDispatch } = useDiagram();
    const pastDiagram = diagramHistory.past[0];
    const diagram = diagramHistory.present;
    const diagramDispatch = diagramHistoryDispatch;
    const diagramProps: DiagramProps = { diagram, diagramDispatch };

    // Elements
    const elementsRef = React.useRef(initialElements);
    const elements = elementsRef.current;

    // Library
    const libraryElement = React.useRef<HTMLDivElement>(null);
    const libraryProps = useLibrary();
    const { library, setLibrary } = libraryProps;

    const diagramSync = useDiagramSync(pastDiagram, diagramProps, libraryProps);
    const { savedDiagram } = diagramSync;
    useDiagramHostCallbacks({ savedDiagram, elements, diagramDispatch });

    const renderLibrary = prefs.libraryVisible && (
        <>
            <div
                className={["flex-0 overflow-hidden max-w-[80vh]"].join(" ")}
                ref={libraryElement}
                style={{ width: prefs.libraryWidth }}
            >
                <AppLibrary library={library} setLibrary={setLibrary} />
            </div>
            <AppResizer libraryElement={libraryElement} />
        </>
    );

    const renderCanvas = isLoadingAuth ? (
        <div className={["flex-0 m-auto"].join("")}>
            {ProgressCircle({ size: 32 })}
        </div>
    ) : (
        <div className={["flex-1 overflow-hidden relative"].join("")}>
            <AppCanvas
                elements={elements}
                diagram={diagram}
                diagramHistory={diagramHistory}
                diagramDispatch={diagramDispatch}
            />
            {/* Note that Empty and Progress are just an overlays, not prevent
            the Canvas from rendering. This is expected because the Diagram
            technically still exists and its DOM should be accessible */}
            {library.current === null && <AppCanvasEmpty />}
            <AppCanvasProgress />
        </div>
    );

    const app = (
        <div
            className={["flex h-screen flex-col", "bg-[var(--slate1)]"].join(
                " "
            )}
        >
            <div className={["flex-0"].join(" ")}>
                <Header
                    setLibrary={setLibrary}
                    diagramHistory={diagramHistory}
                    diagramHistoryDispatch={diagramHistoryDispatch}
                    elements={elements}
                />
            </div>
            <Border color="strong" />
            <div className={["flex-1 overflow-hidden flex"].join(" ")}>
                {renderLibrary}
                {renderCanvas}
            </div>
            {!isAppHost && prefs.footerVisible && (
                <>
                    <Border color="strong" />
                    <div
                        className={["flex-0"].join(" ")}
                        children={<Footer />}
                    />
                </>
            )}
        </div>
    );

    const routes = (
        <Routes>
            <Route path="/" element={app} />
            <Route path="/s/:uniqueToken" element={<SharedCanvas />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );

    // eslint-disable-next-line
    return <__DiagramContext.Provider value={diagram} children={routes} />;
};
