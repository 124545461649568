import { Button } from "app/button/button";
import { useNavigate } from "react-router-dom";
import { Icons } from "utilities/icon";

export const NotFound = () => {
    const navigate = useNavigate();

    const returnToDiagramplus = () => {
        navigate("/");
    };

    return (
        <>
            <div
                className={[
                    "flex items-center justify-center text-center",
                ].join(" ")}
            >
                <div>
                    Invalid route.
                    <Button
                        color="solid"
                        icon={Icons.home}
                        onClick={returnToDiagramplus}
                        children="Return to Diagramplus"
                    />
                </div>
            </div>
        </>
    );
};
