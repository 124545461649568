import { Button } from "app/button/button";
import { Border } from "border/border";
import { DiagramAction } from "diagram/state/reducer/reducer";
import { DiagramProps } from "diagram/state/state";
import * as React from "react";

interface RowsProps {
    count: number;
    tableId: number;
    diagramDispatch: DiagramProps["diagramDispatch"];
}

export const TableRowsGroup = (props: RowsProps) => (
    <div className={["bg-[var(--slate1)]"].join(" ")}>
        <Border color="weak" />
        <div style={{ marginTop: -1 }} />
        <Button
            color="solid"
            onClick={() => {
                props.diagramDispatch({
                    type: "expand-table-rows",
                    payload: { id: props.tableId, expanded: true },
                });
            }}
            children={`${props.count} columns hidden`}
        />
        <div style={{ marginTop: -1 }} />
        <Border color="weak" />
    </div>
);

interface ButtonProps {
    tableId: number;
    diagramDispatch: React.Dispatch<DiagramAction>;
}

export const TableCollapseButton = (props: ButtonProps) => (
    <Button
        color="solid"
        onClick={() => {
            props.diagramDispatch({
                type: "expand-table-rows",
                payload: { id: props.tableId, expanded: false },
            });
        }}
        children="Hide columns"
    />
);
