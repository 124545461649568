import { BaseRow } from "diagram/model/base/base";
import { EditDiagram } from "diagram/model/edit/edit";
import { makeBaseDiagramFromEdit } from "diagram/model/stringify/base";

interface TableReference {
    fromTable: string;
    fromColumns: string[];
    toTable: string;
    toColumns: string[];
}

const disclaimerString = `-- Generated by Diagramplus
-- https://diagramplus.com
--
-- This file is generated by Diagramplus and is not fully representative of the tables in the database.
-- It's still missing: indices, triggers. Do not use it as a backup.
-- It is recommended to use this file as a reference and to manually verify the generated SQL.
--`;

const buildPostgreSQLCreateTable = (
    tableName: string,
    tableFields: Array<BaseRow>
) => {
    const lowerTableName = tableName.toLowerCase();
    var primaryKeys = new Array<string>();
    var generatedSQL = "";
    generatedSQL = `CREATE TABLE "${lowerTableName}"`;

    var createTableStatement = "";
    for (const [i, row] of tableFields.entries()) {
        const nullString = row.nullable ? "NULL" : "NOT NULL";
        const name = row.name?.toLowerCase();
        const type = row.type;
        const isPrimary = row.isPrimary === "true";

        if (isPrimary && name) {
            primaryKeys.push(name);
        }
        createTableStatement += `    `;
        createTableStatement += `"${name}" ${type} ${nullString}`;

        // Insert primary key statement if last row
        if (i === tableFields.length - 1) {
            if (primaryKeys.length > 0) {
                var primaryKeyString = primaryKeys.join('", "');
                primaryKeyString = `"${primaryKeyString}"`;
                primaryKeyString = `(${primaryKeyString})`;

                createTableStatement += `,`;
                createTableStatement += `\n`;
                createTableStatement += `    `;
                createTableStatement += `PRIMARY KEY`;
                createTableStatement += ` ${primaryKeyString}\n`;
            } else {
                createTableStatement += `\n`;
            }
        } else {
            createTableStatement += `,`;
            createTableStatement += `\n`;
        }
    }
    generatedSQL = `${generatedSQL} (\n${createTableStatement});\n\n`;
    return generatedSQL;
};

const buildPostgreSQLAlterTableForeignKey = (references: TableReference[]) => {
    var generatedSQL = "";
    for (const ref of references) {
        var fromForeignKeyString = ref.fromColumns.join('", "');
        fromForeignKeyString = `"${fromForeignKeyString}"`;
        fromForeignKeyString = `(${fromForeignKeyString})`;

        var toForeignKeyString = ref.toColumns.join('", "');
        toForeignKeyString = `"${toForeignKeyString}"`;
        toForeignKeyString = `(${toForeignKeyString})`;

        generatedSQL += `ALTER TABLE `;
        generatedSQL += `"${ref.fromTable}"`;
        generatedSQL += ` ADD FOREIGN KEY `;
        generatedSQL += `${fromForeignKeyString}`;
        generatedSQL += ` REFERENCES `;
        generatedSQL += `"${ref.toTable}" `;
        generatedSQL += `${toForeignKeyString}`;
        generatedSQL += `;\n\n`;
    }
    return generatedSQL;
};

const buildMySQLCreateTable = (
    tableName: string,
    tableFields: Array<BaseRow>
) => {
    const lowerTableName = tableName.toLowerCase();
    var primaryKeys = new Array<string>();
    var generatedSQL = "";
    generatedSQL = `CREATE TABLE \`${lowerTableName}\``;

    var createTableStatement = "";
    for (const [i, row] of tableFields.entries()) {
        const nullString = row.nullable ? "NULL" : "NOT NULL";
        const name = row.name?.toLowerCase();
        const type = row.type;
        const isPrimary = row.isPrimary === "true";

        if (isPrimary && name) {
            primaryKeys.push(name);
        }
        createTableStatement += `    `;
        createTableStatement += `\`${name}\` ${type} ${nullString}`;

        // Insert primary key statement if last row
        if (i === tableFields.length - 1) {
            if (primaryKeys.length > 0) {
                var primaryKeyString = primaryKeys.join("`, `");
                primaryKeyString = `\`${primaryKeyString}\``;
                primaryKeyString = `(${primaryKeyString})`;

                createTableStatement += `,`;
                createTableStatement += `\n`;
                createTableStatement += `    `;
                createTableStatement += `PRIMARY KEY`;
                createTableStatement += ` ${primaryKeyString}\n`;
            } else {
                createTableStatement += `\n`;
            }
        } else {
            createTableStatement += `,`;
            createTableStatement += `\n`;
        }
    }
    generatedSQL = `${generatedSQL} (\n${createTableStatement});\n\n`;
    return generatedSQL;
};

const buildMySQLAlterTableForeignKey = (references: TableReference[]) => {
    var generatedSQL = "";
    for (const ref of references) {
        var fromForeignKeyString = ref.fromColumns.join("`, `");
        fromForeignKeyString = `\`${fromForeignKeyString}\``;
        fromForeignKeyString = `(${fromForeignKeyString})`;

        var toForeignKeyString = ref.toColumns.join("`, `");
        toForeignKeyString = `\`${toForeignKeyString}\``;
        toForeignKeyString = `(${toForeignKeyString})`;

        generatedSQL += `ALTER TABLE `;
        generatedSQL += `\`${ref.fromTable}\``;
        generatedSQL += ` ADD FOREIGN KEY `;
        generatedSQL += `${fromForeignKeyString}`;
        generatedSQL += ` REFERENCES `;
        generatedSQL += `\`${ref.toTable}\``;
        generatedSQL += `${toForeignKeyString}`;
        generatedSQL += `;\n\n`;
    }
    return generatedSQL;
};

const buildSQLServerCreateTable = (
    tableName: string,
    tableFields: Array<BaseRow>
) => {
    const lowerTableName = tableName.toLowerCase();
    var primaryKeys = new Array<string>();
    var generatedSQL = "";
    generatedSQL = `CREATE TABLE [${lowerTableName}]`;

    var createTableStatement = "";
    for (const [i, row] of tableFields.entries()) {
        const nullString = row.nullable ? "NULL" : "NOT NULL";
        const name = row.name?.toLowerCase();
        const type = row.type;
        const isPrimary = row.isPrimary === "true";

        if (isPrimary && name) {
            primaryKeys.push(name);
        }
        createTableStatement += `    `;
        createTableStatement += `[${name}] ${type} ${nullString}`;

        // Insert primary key statement if last row
        if (i === tableFields.length - 1) {
            if (primaryKeys.length > 0) {
                var primaryKeyString = primaryKeys.join("], [");
                primaryKeyString = `[${primaryKeyString}]`;
                primaryKeyString = `(${primaryKeyString})`;

                createTableStatement += `,`;
                createTableStatement += `\n`;
                createTableStatement += `    `;
                createTableStatement += `PRIMARY KEY`;
                createTableStatement += ` ${primaryKeyString}\n`;
            } else {
                createTableStatement += `\n`;
            }
        } else {
            createTableStatement += `,`;
            createTableStatement += `\n`;
        }
    }
    generatedSQL = `${generatedSQL} (\n${createTableStatement});\n\n`;
    return generatedSQL;
};

const buildSQLServerAlterTableForeignKey = (references: TableReference[]) => {
    var generatedSQL = "";
    for (const ref of references) {
        var fromForeignKeyString = ref.fromColumns.join("], [");
        fromForeignKeyString = `[${fromForeignKeyString}]`;
        fromForeignKeyString = `(${fromForeignKeyString})`;

        var toForeignKeyString = ref.toColumns.join("], [");
        toForeignKeyString = `[${toForeignKeyString}]`;
        toForeignKeyString = `(${toForeignKeyString})`;

        generatedSQL += `ALTER TABLE `;
        generatedSQL += `[${ref.fromTable}]`;
        generatedSQL += ` ADD FOREIGN KEY `;
        generatedSQL += `${fromForeignKeyString}`;
        generatedSQL += ` REFERENCES `;
        generatedSQL += `[${ref.toTable}] `;
        generatedSQL += `${toForeignKeyString}`;
        generatedSQL += `;\n\n`;
    }
    return generatedSQL;
};

const exportToPostgreSQL = (diagram: EditDiagram) => {
    const data = makeBaseDiagramFromEdit(diagram);
    const tables = new Map<string, Array<BaseRow>>();
    const references = new Array<TableReference>();

    for (const table of data.items) {
        const fields = new Array<BaseRow>();
        for (const row of table.rows) {
            if (row.name && row.type) {
                fields.push(row);
            }
        }
        tables.set(table.name, fields);
    }

    for (const ref of data.refs) {
        const fromTable = tables.get(ref.from.name);
        const toTable = tables.get(ref.to.name);

        const fromTableName = ref.from.name.toLowerCase();
        const toTableName = ref.to.name.toLowerCase();

        if (fromTable && toTable) {
            var fromColumns = new Array<string>();
            var toColumns = new Array<string>();
            for (const row of ref.from.rows) {
                const fromColumn = fromTable.find((r) => r.id === row);
                if (fromColumn && fromColumn.name) {
                    fromColumns.push(fromColumn.name);
                }
            }
            for (const row of ref.to.rows) {
                const toColumn = toTable.find((r) => r.id === row);
                if (toColumn && toColumn.name) {
                    toColumns.push(toColumn.name);
                }
            }
            references.push({
                fromTable: fromTableName,
                fromColumns: fromColumns,
                toTable: toTableName,
                toColumns: toColumns,
            });
        }
    }

    var generatedMigrationSQL = "";
    generatedMigrationSQL += disclaimerString;
    generatedMigrationSQL += `\n\n`;

    for (const [tableName, tableFields] of tables) {
        const createTableSQL = buildPostgreSQLCreateTable(
            tableName,
            tableFields
        );
        generatedMigrationSQL += createTableSQL;
    }

    const foreignKeySQL = buildPostgreSQLAlterTableForeignKey(references);
    generatedMigrationSQL += foreignKeySQL;

    return generatedMigrationSQL;
};

const exportToMySQL = (diagram: EditDiagram) => {
    const data = makeBaseDiagramFromEdit(diagram);
    const tables = new Map<string, Array<BaseRow>>();
    const references = new Array<TableReference>();

    for (const table of data.items) {
        const fields = new Array<BaseRow>();
        for (const row of table.rows) {
            if (row.name && row.type) {
                fields.push(row);
            }
        }
        tables.set(table.name, fields);
    }

    for (const ref of data.refs) {
        const fromTable = tables.get(ref.from.name);
        const toTable = tables.get(ref.to.name);

        const fromTableName = ref.from.name.toLowerCase();
        const toTableName = ref.to.name.toLowerCase();

        if (fromTable && toTable) {
            var fromColumns = new Array<string>();
            var toColumns = new Array<string>();
            for (const row of ref.from.rows) {
                const fromColumn = fromTable.find((r) => r.id === row);
                if (fromColumn && fromColumn.name) {
                    fromColumns.push(fromColumn.name);
                }
            }
            for (const row of ref.to.rows) {
                const toColumn = toTable.find((r) => r.id === row);
                if (toColumn && toColumn.name) {
                    toColumns.push(toColumn.name);
                }
            }
            references.push({
                fromTable: fromTableName,
                fromColumns: fromColumns,
                toTable: toTableName,
                toColumns: toColumns,
            });
        }
    }

    var generatedMigrationSQL = "";
    generatedMigrationSQL += disclaimerString;
    generatedMigrationSQL += `\n\n`;

    for (const [tableName, tableFields] of tables) {
        const createTableSQL = buildMySQLCreateTable(tableName, tableFields);
        generatedMigrationSQL += createTableSQL;
    }

    const foreignKeySQL = buildMySQLAlterTableForeignKey(references);
    generatedMigrationSQL += foreignKeySQL;

    return generatedMigrationSQL;
};

const exportToSQLServer = (diagram: EditDiagram) => {
    const data = makeBaseDiagramFromEdit(diagram);
    const tables = new Map<string, Array<BaseRow>>();
    const references = new Array<TableReference>();

    for (const table of data.items) {
        const fields = new Array<BaseRow>();
        for (const row of table.rows) {
            if (row.name && row.type) {
                fields.push(row);
            }
        }
        tables.set(table.name, fields);
    }

    for (const ref of data.refs) {
        const fromTable = tables.get(ref.from.name);
        const toTable = tables.get(ref.to.name);

        const fromTableName = ref.from.name.toLowerCase();
        const toTableName = ref.to.name.toLowerCase();

        if (fromTable && toTable) {
            var fromColumns = new Array<string>();
            var toColumns = new Array<string>();
            for (const row of ref.from.rows) {
                const fromColumn = fromTable.find((r) => r.id === row);
                if (fromColumn && fromColumn.name) {
                    fromColumns.push(fromColumn.name);
                }
            }
            for (const row of ref.to.rows) {
                const toColumn = toTable.find((r) => r.id === row);
                if (toColumn && toColumn.name) {
                    toColumns.push(toColumn.name);
                }
            }
            references.push({
                fromTable: fromTableName,
                fromColumns: fromColumns,
                toTable: toTableName,
                toColumns: toColumns,
            });
        }
    }

    var generatedMigrationSQL = "";
    generatedMigrationSQL += disclaimerString;
    generatedMigrationSQL += `\n\n`;

    for (const [tableName, tableFields] of tables) {
        const createTableSQL = buildSQLServerCreateTable(
            tableName,
            tableFields
        );
        generatedMigrationSQL += createTableSQL;
    }

    const foreignKeySQL = buildSQLServerAlterTableForeignKey(references);
    generatedMigrationSQL += foreignKeySQL;

    return generatedMigrationSQL;
};

export const Exporter = {
    exportToPostgreSQL,
    exportToMySQL,
    exportToSQLServer,
};
