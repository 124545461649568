import { FooterLink } from "../link/link";
import logo from "./logo.png";

export const FooterCopyright = () => (
    <div
        className={[
            "flex items-center lg:text-right text-left py-2",
            "text-[var(--slate11)]",
        ].join(" ")}
    >
        <p className="leading-normal">
            <span>© 2023 </span>
            <FooterLink href="https://tableplus.com">
                Table Plus Inc.
            </FooterLink>
            <span>
                1100-1959 Upper Water Street Halifax NS Canada. All rights
                reserved
            </span>
        </p>
        <div className="h-4 w-4" />
        <FooterLink href="https://tableplus.com">
            <img
                className="h-[32px]"
                alt="TablePlus logo"
                src={logo}
                width="auto"
                height={32}
            />
        </FooterLink>
    </div>
);
