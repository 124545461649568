import * as V1 from "./edit-v1";
import * as V2 from "./edit-v2";
import * as V3 from "./edit-v3";
import * as V4 from "./edit-v4";

export type EditDiagramUnknown =
    | V1.EditDiagramV1
    | V2.EditDiagramV2
    | V3.EditDiagramV3
    | V4.EditDiagramV4;
export type EditDiagramLatest = V4.EditDiagramV4;

export type EditDiagram = EditDiagramLatest;
export type EditRow = V4.EditRowV4;
export type EditColumn = V1.EditColumnV1;
export type EditTable = V4.EditTableV4;
export type EditTableValue = V4.EditTableValueV2;
export type EditRef = V3.EditRefV3;
export type EditRefSide = V1.EditRefSideV1;
export type EditEdge = V3.EditRefV3;
export type EditEdgeSide = V1.EditEdgeSideV1;
export type EditSelectable = V3.EditSelectable;

export const migrateEditDiagram = (
    diagram: EditDiagramUnknown
): EditDiagramLatest => {
    if (V1.isEditDiagramV1(diagram)) {
        const v2 = V2.migrateEditDiagramV1toV2(diagram);
        const v3 = V3.migrateEditDiagramV2toV3(v2);
        return V4.migrateEditDiagramV3toV4(v3);
    }
    if (V2.isEditDiagramV2(diagram)) {
        const v3 = V3.migrateEditDiagramV2toV3(diagram);
        return V4.migrateEditDiagramV3toV4(v3);
    }
    if (V3.isEditDiagramV3(diagram)) {
        return V4.migrateEditDiagramV3toV4(diagram);
    }
    if (V4.isEditDiagramV4(diagram)) {
        return diagram;
    }
    throw Error("Unknown edit diagram version");
};

export const isEditDiagramUknown = (
    diagram: unknown
): diagram is EditDiagramUnknown => {
    if (typeof diagram !== "object") return false;
    // Starting from V2, EditDiagram.type === "edit"
    if ((diagram as V2.EditDiagramV2).type === "edit") return true; // V2
    // In V1 we need to depend on an ad-hoc check
    if ((diagram as V1.EditDiagramV1).tables !== undefined) return true; // V1
    return false;
};
