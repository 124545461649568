import { EditDiagram } from "diagram/model/edit/edit";
import { parseDiagram } from "diagram/model/parse/parse";
import airlines from "diagram/samples/airlines.json";
import { isAppHost } from "host/host";
import * as React from "react";
import { LibraryReducer } from "./reducer";
import { CurrentStorage } from "./storage/current";
import { DocStorage } from "./storage/doc";
import { SetState } from "utilities/set-state";
import { Backend } from "backend/backend";
import { AuthContext } from "auth/auth";
import { getNumberId } from "utilities/id";
import { PreferencesContext } from "preferences/preferences";
import { dialogConfirm } from "app/dialog/confirm";

export interface LibraryItem {
    id: number;
    name: string;
    saved: boolean;
    shared: boolean;
    diagram: EditDiagram;
}

export interface OldLibraryItem {
    name: string;
    saved: boolean;
    diagram: EditDiagram;
}

export interface Library {
    /**
     * Currently opened file ID. Should be included in @property "all", or null
     * when no file is opened.
     */
    current: number | null;
    /**
     * All files managed by this Library. May include @property current or not
     * depend on whether it's saved or not
     */
    all: LibraryItem[];
}

const loadInitial = async (isAuthenticated: boolean): Promise<Library> => {
    var all = isAuthenticated
        ? await Backend.getDiagrams()
        : await DocStorage.list();
    // In case of app, always start with a new, selected diagram
    if (isAppHost) {
        let library: Library = { current: null, all };
        return LibraryReducer.add()(library);
    }

    // Check if there are old diagrams
    const localStorageDiagrams = await DocStorage.listOldDiagrams();

    if (localStorageDiagrams.length > 0) {
        // Migrate old diagrams if exists
        var migrateDiagrams: LibraryItem[] = all;
        for (let diagram of localStorageDiagrams) {
            var migrateDiagramIDs = migrateDiagrams.map((item) => item.id);
            if (migrateDiagrams.length === 0) {
                migrateDiagramIDs = [0];
            }
            const newDiagram = {
                id: getNumberId(migrateDiagramIDs),
                shared: false,
                ...diagram,
            };
            migrateDiagrams.push(newDiagram);
        }
        for (let diagram of migrateDiagrams) {
            await DocStorage.set(diagram);
        }
    }
    await DocStorage.clearOldDiagrams();

    // In case of web:
    // If there is no diagram then show the sample
    if (all.length === 0) {
        if (!isAuthenticated) {
            const diagram = parseDiagram(airlines);
            const sample: LibraryItem = {
                id: getNumberId(all.map((item) => item.id)),
                diagram,
                name: "Untitled",
                saved: false,
                shared: false,
            };
            return { current: sample.id, all: [sample] };
        }
    }

    // Else just try to load the previous diagram
    let currentIDString = await CurrentStorage.get();
    var current: number | null = parseInt(currentIDString ?? "");

    if (all.length > 0) {
        if (!all.find((i) => i.id === current)) current = all[0].id;
    } else {
        current = null;
    }
    return { current, all };
};

export const useLibrary = (): LibraryProps => {
    const savedCurrent = React.useRef<Library["current"]>(null);
    const [currentName, setCurrentName] = React.useState<string | null>("");
    const { preferences, setPreferences } =
        React.useContext(PreferencesContext);
    const [didAskToImport, setDidAskToImport] = React.useState<boolean>(
        preferences.didAskToImport
    );

    const [library, setLibrary] = React.useState<Library>({
        current: null,
        all: [],
    });

    const { current } = library;
    const { isAuthenticated, isLoadingAuth } = React.useContext(AuthContext);

    const importFromStorageInitial = async () => {
        const all = await DocStorage.list();

        if (all.length > 0) {
            const yes = await dialogConfirm(
                "Would you like to import your diagrams from storage into the cloud?"
            );
            setDidAskToImport(true);
            if (yes) {
                // Save all diagrams from library
                for (let item of all) {
                    await Backend.createDiagram(item.name, item.diagram);
                }
                // Wipe imported diagrams
                await DocStorage.clear();

                // Reload diagrams from cloud
                const importedDiagrams = await Backend.getDiagrams();
                setLibrary(LibraryReducer.setAll(importedDiagrams));
            }
        }
    };

    React.useEffect(() => {
        const currentName = library.all.find((i) => i.id === current)?.name;
        setCurrentName(currentName ?? null);
    }, [current, library]);

    // Import from storage on initial load
    React.useEffect(() => {
        if (isAuthenticated && !preferences.didAskToImport) {
            importFromStorageInitial();
            setDidAskToImport(true);
        }
    }, [isAuthenticated, preferences.didAskToImport]);

    React.useEffect(() => {
        if (!isAuthenticated) {
            setPreferences((prev) => ({ ...prev, didAskToImport: false }));
        }
    }, [isAuthenticated, setPreferences]);

    React.useEffect(() => {
        setPreferences((prev) => ({ ...prev, didAskToImport: didAskToImport }));
    }, [didAskToImport, setPreferences]);

    // Load initial
    React.useEffect(() => {
        const fetchData = async () => {
            if (!isLoadingAuth) {
                const library = await loadInitial(isAuthenticated);
                setLibrary(library);
            }
        };
        fetchData();
    }, [isAuthenticated, isLoadingAuth]);

    // Save current document id
    React.useEffect(() => {
        if (isAppHost) return;
        if (savedCurrent.current === current) return;

        const currentIDString = current === null ? null : current.toString();
        CurrentStorage.set(currentIDString);
        savedCurrent.current = current;
    }, [current]);

    // Update browser's title
    React.useEffect(() => {
        const titleSuffix = "Diagram by TablePlus";
        const prefix = currentName === null ? "" : `${currentName} | `;
        document.title = `${prefix}${titleSuffix}`;
    }, [current, currentName]);

    return { library, setLibrary };
};

export interface LibraryProps {
    library: Library;
    setLibrary: SetState<Library>;
}
