import {
    DiagramElements,
    DiagramElementsUtils,
    FieldName,
    RowElements,
} from "canvas/elements";
import { TableGroupedRows } from "canvas/table/rows";
import { EditColumn, EditRow, EditTable } from "diagram/model/edit/edit";

const { edgeFieldName, removeFieldName } = DiagramElementsUtils;

export type TableNavigateDirection = "up" | "right" | "down" | "left";

export type TableNavigate = (
    rowIndex: number,
    fieldName: FieldName,
    direction: TableNavigateDirection
) => void;

interface Props {
    tableId: EditTable["id"];
    elements: DiagramElements;
    columns: EditColumn[];
    rows: TableGroupedRows;
}

export const focusChild = (element: Element) => {
    const focusable = element.querySelector("select,input,button,[tabindex]");
    if (!(focusable instanceof HTMLElement)) throw Error("No focusable child");
    focusable.focus();
};

const getNextRow = (
    rows: TableGroupedRows,
    direction: "up" | "down",
    idx: number
): EditRow | null => {
    let i = idx;
    const sign = direction === "down" ? +1 : -1;
    while (i >= 0) {
        i = i + sign * 1;
        const row = rows[i];
        if (row === undefined) return null; // Out of range
        if (Array.isArray(row)) continue; // Skipping groups
        return row;
    }
    return null;
};

const getNextField = (
    rowElements: RowElements,
    columns: EditColumn[],
    name: FieldName,
    direction: "left" | "right"
): FieldName => {
    const idx = ((): number => {
        if (name === removeFieldName) return -1;
        if (name === edgeFieldName) return columns.length;
        const idx = columns.findIndex((c) => c.value.name === name);
        if (idx > -1) return idx;
        throw Error(`Column "${name as string}" not found`);
    })();
    const nextIdx = idx + (direction === "right" ? +1 : -1) * 1;
    if (nextIdx < 0) return removeFieldName;
    if (nextIdx > columns.length - 1) return edgeFieldName;
    // Fallback to another column if it's not connected to the document
    const nextName = columns[nextIdx].value.name;
    const element = rowElements.fields.get(nextName);
    if (element === undefined || !element.isConnected)
        return direction === "right" ? edgeFieldName : columns[1].value.name;
    return nextName;
};

export const makeTableNavigate =
    (props: Props): TableNavigate =>
    (currRowIdx, currFieldName, direction) => {
        const { tableId, rows, elements, columns } = props;

        const currRow = rows[currRowIdx];
        if (Array.isArray(currRow)) throw Error("Cannot navigate from a group");

        let nextRow: EditRow | null = currRow;
        let nextFieldName: FieldName | null = currFieldName;

        const tableElements = elements.tables.get(tableId);
        if (tableElements === undefined)
            throw Error("TableElements is undefined");

        switch (direction) {
            // Change the field, keep the row
            case "right":
            case "left": {
                const rEs = tableElements.rows.get(currRow.id);
                if (rEs === undefined) throw Error("RowElements is undefined");
                const name = currFieldName;
                nextFieldName = getNextField(rEs, columns, name, direction);
                break;
            }
            // Change the row, keep the field
            case "up":
            case "down": {
                nextRow = getNextRow(rows, direction, currRowIdx);
                break;
            }
            default:
                return; // Avoid prevent default if not handled
        }

        if (nextRow === null || nextFieldName === null) return; // Can't go anywhere
        if (nextRow === currRow && nextFieldName === currFieldName) return; // Nothing changed

        // Focus on the next field
        const nextRows = tableElements.rows.get(nextRow.id);
        const nextTarget = nextRows?.fields.get(nextFieldName) ?? undefined;
        if (nextTarget === undefined) throw Error("Next target is undefined");
        focusChild(nextTarget);
    };
