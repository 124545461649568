import { EditRef } from "diagram/model/edit/edit";
import { DiagramReducer } from "../../reducer";

export interface RemoveTablePayload {
    id: number;
}

type Payload = RemoveTablePayload;

const isRelated = (payload: Payload, ref: EditRef): boolean =>
    ref.from.table === payload.id || ref.to.table === payload.id;

export const removeTable: DiagramReducer<Payload> = (diagram, payload) => {
    const tables = diagram.tables.filter((table) => table.id !== payload.id);
    const refs = diagram.refs.filter((ref) => !isRelated(payload, ref));
    return { ...diagram, tables, refs };
};
