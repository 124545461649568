import { Icons } from "utilities/icon";
import { EditDiagram, EditTable } from "diagram/model/edit/edit";
import * as React from "react";
import { Border } from "border/border";
import { Input } from "input/input";
import { Button } from "app/button/button";

type Reducer = (rows: string[]) => string[];

interface Props {
    diagram: EditDiagram;
    table: EditTable | null;
    rows: string[];
    setRows: (reducer: Reducer) => void;
}

const addRow = (): Reducer => {
    return (rows) => rows.concat("");
};

const removeRow = (index: number): Reducer => {
    return (rows) => rows.filter((_r, i) => i !== index);
};

const setRow = (index: number, row: string): Reducer => {
    return (rows) => rows.map((r, i) => (i === index ? row : r));
};

const getSuggestions = (props: Props): string[] => {
    if (props.table === null) return [];
    const nameKey = props.diagram.columns[0].value.name;
    return props.table.value.rows
        .map((row) => row.value[nameKey] as string)
        .filter((row) => !props.rows.includes(row));
};

const dispatchRemove = (props: Props, index: number) => {
    return (event: React.MouseEvent) => {
        props.setRows(removeRow(index));
        event.stopPropagation();
        // Because "click outside to close popup" is handled outside of React's
        // event system. See https://stackoverflow.com/q/24415631/6621213
        event.nativeEvent.stopImmediatePropagation();
    };
};

export const EdgeCreateRows = (props: Props) => (
    <div>
        <datalist id={`${props.table?.id}-rows`}>
            {getSuggestions(props).map((name) => (
                <option key={name} value={name} />
            ))}
        </datalist>
        {props.rows.map((row, index) => (
            <div className={"flex"} key={index}>
                <div className={"w-[120px]"}>
                    <Input
                        value={row}
                        onValueChange={(value) => {
                            props.setRows(setRow(index, value));
                        }}
                        list={`${props.table?.id}-rows`}
                        placeholder="Column"
                    />
                </div>
                <div className={"block flex-0 h-3 w-3"} />
                <Border color="weak" />
                <div className={"block flex-0 h-3 w-3"} />
                {index === 0 ? (
                    <Button
                        color="clear"
                        onClick={() => void props.setRows(addRow())}
                        icon={Icons.add}
                        extraStyle="text-[var(--green10)]"
                    />
                ) : (
                    <Button
                        color="clear"
                        onClick={dispatchRemove(props, index)}
                        icon={Icons.remove}
                        extraStyle={"text-[var(--red10)]"}
                    />
                )}
            </div>
        ))}
    </div>
);
