import { MenuItemProps } from "app/button/menu";

export const getHeaderImportStorageItems = (
    importFromStorage: () => void,
    isEnabled: boolean
): MenuItemProps[] => [
    {
        label: `Import from storage`,
        fn: () => importFromStorage(),
        disabled: false,
    },
];
