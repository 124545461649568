import { EditEdge, EditTable } from "diagram/model/edit/edit";
import * as React from "react";

type TID = EditTable["id"];

const isSame = (a: TID[], b: TID[]): boolean => {
    return a.every((id, index) => id === b[index]);
};

/**
 * Returns an array of Table Ids whose identity is not changed if the values
 * are the same. This is an ad-hoc implementation to avoid re-creating
 * observers when user only select something
 */
export const useEdgeCanvasTables = (edges: EditEdge[]): TID[] => {
    const prev = React.useRef<TID[]>([]);
    const tables = React.useMemo(() => {
        const current: TID[] = edges.reduce((arr, edge) => {
            arr.push(edge.from.table, edge.to.table);
            return arr;
        }, [] as TID[]);
        if (isSame(current, prev.current) === false) prev.current = current;
        return prev.current;
    }, [edges]);
    return tables;
};
