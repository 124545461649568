import { Position, Size } from "geometry/geometry";
import { BaseColumnV1, BaseRowV1 } from "../base/base-v1";
import { EditDiagramUnknown } from "./edit";

export interface EditColumnV1 {
    id: number;
    value: BaseColumnV1;
}

export interface EditRowV1 {
    value: BaseRowV1;
    id: number;
}

export interface EditTableValueV1 {
    id: string;
    name: string;
    schema?: string;
    rows: EditRowV1[];
}

export interface EditTableV1 {
    id: number;
    value: EditTableValueV1;
    position: Position;
    isRowsExpand: boolean;
}

export interface EditRefSideV1 {
    table: number;
    rows: number[];
}

export interface EditRefV1 {
    id: number;
    from: EditRefSideV1;
    to: EditRefSideV1;
}

// Alias to avoid conflict with React's "ref" naming
export type EditEdgeV1 = EditRefV1;
export type EditEdgeSideV1 = EditRefSideV1;

export interface EditDiagramV1 {
    columns: EditColumnV1[];
    tables: EditTableV1[];
    refs: EditRefV1[];
    size: Size;
    schema: string;
}

export const isEditDiagramV1 = (
    diagram: EditDiagramUnknown
): diagram is EditDiagramV1 => {
    return "version" in diagram === false;
};
