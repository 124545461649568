import { Button } from "app/button/button";
import { AuthContext } from "auth/auth";
import { Backend } from "backend/backend";
import { Input } from "input/input";
import { LibraryReducer } from "library/reducer";
import { DocStorage } from "library/storage/doc";
import * as React from "react";
import { Icon, Icons } from "utilities/icon";
import { LibraryItemProps } from "../item";

interface Props extends LibraryItemProps {
    isEdit: boolean;
    setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LibraryItemName = (props: Props) => {
    const { isAuthenticated } = React.useContext(AuthContext);

    const open = (props: Props): void => {
        if (props.isCurrent) return;
        props.setLibrary(LibraryReducer.open(props.item.id));
    };

    const rename = async (props: Props, to: string): Promise<void> => {
        const target = props.item.id;
        const from = props.item.name;
        if (from === to) return;
        if (props.item.saved === false) {
            const savedItem = { ...props.item, name: to, saved: true };
            if (isAuthenticated) {
                await Backend.createDiagram(savedItem.name, savedItem.diagram);
            } else {
                await DocStorage.set(savedItem);
            }
        } else {
            if (isAuthenticated) {
                const renamedItem = { ...props.item, name: to };
                await Backend.updateDiagram(renamedItem);
            } else {
                await DocStorage.rename(props.item, to);
            }
        }
        props.setLibrary(LibraryReducer.rename(target, to));
    };

    if (props.isEdit) {
        return (
            <div className="h-[32px] px-1 justify-center flex flex-col">
                <Input
                    paddingStyle="py-0.5 px-1"
                    defaultValue={props.item.name}
                    // Don't use onKeyDown here as the Enter will also trigger the
                    // auto-focused "OK" button in the confirm dialog
                    onKeyUp={async (event) => {
                        if (event.key !== "Enter") return;
                        await rename(props, event.currentTarget.value);
                        props.setIsEdit(false);
                    }}
                    onBlur={async (event) => {
                        await rename(props, event.currentTarget.value);
                        props.setIsEdit(false);
                    }}
                    autoFocus
                />
            </div>
        );
    } else {
        const className = [
            "group flex-[1_1] text-ellipsis overflow-hidden",
            "transition-none whitespace-nowrap text-left cursor-pointer",
            "h-[32px] px-2 rounded",
            props.isCurrent ? "" : "",
            props.item.saved === false ? "italic" : "",
        ].join(" ");
        return (
            <div className={["flex items-center"].join(" ")}>
                {props.item.shared && (
                    <div className={"px-[10px]"}>
                        <Icon icon={Icons.people} size={16} />
                    </div>
                )}
                <Button
                    className={className}
                    color="clear"
                    onClick={() => void open(props)}
                    onDoubleClick={() => void props.setIsEdit(true)}
                    title="Click to select, Double-click to rename"
                    textColor="text-[var(--slate12)]"
                >
                    {props.item.name}
                    {props.item.saved === false && (
                        <span className="text-[var(--gray10)]"> - unsaved</span>
                    )}
                </Button>
            </div>
        );
    }
};
