import { GRID_CELL } from "./geometry";

export interface Point {
    x: number;
    y: number;
}

export interface Position {
    top: number;
    left: number;
}

export const roundNumber = (num: number): number =>
    Math.round(num / GRID_CELL) * GRID_CELL;

export const roundPoint = (point: Point): Point => ({
    x: roundNumber(point.x),
    y: roundNumber(point.y),
});

export const roundPosition = (position: Position): Position => ({
    top: roundNumber(position.top),
    left: roundNumber(position.left),
});

export const subtractPosition = (a: Position, b: Position): Position => ({
    top: a.top - b.top,
    left: a.left - b.left,
});

export const subtractPoint = (P: Point, Q: Point): Point => ({
    x: P.x - Q.x,
    y: P.y - Q.y,
});

/** Rect and Point share the same interface but avoid using one for another */
export const getPointFromDOMRect = (rect: DOMRect): Point => ({
    x: rect.x,
    y: rect.y,
});

export const getPathPoint = (point: Point): string => `${point.x} ${point.y}`;

/** Add the second point to the first. */
export const addPoints = (P: Point, Q: Point): Point => ({
    x: P.x + Q.x,
    y: P.y + Q.y,
});

/** Multiply point by a number */
export const multiplyPoint = (P: Point, i: number): Point => ({
    x: P.x * i,
    y: P.y * i,
});

/** Calculate the cross product of the two points. */
export const crossProductPoints = (P: Point, Q: Point): number => {
    return P.x * Q.y - P.y * Q.x;
};

export const isSamePoint = (P: Point, Q: Point): boolean => {
    return P.x === Q.x && P.y === Q.y;
};

export const pointToPosition = (P: Point): Position => ({
    top: P.y,
    left: P.x,
});
