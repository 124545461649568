import { EditTable } from "diagram/model/edit/edit";
import { MoveRowPayload } from "diagram/state/reducer/row/move";
import { DiagramProps } from "diagram/state/state";
import * as DnD from "react-dnd";

const TYPE: "reorder" = "reorder";

interface ReorderItem {
    tableId: EditTable["id"];
    rowIndex: number;
}

type Ref = DnD.DragElementWrapper<DnD.DragSourceOptions>;

export const useReorderDrag = (
    props: Omit<ReorderItem, "type">
): { ref: Ref } => {
    const [, ref] = DnD.useDrag<ReorderItem, never, never>({
        type: TYPE,
        item: { ...props },
    });
    return { ref };
};

interface DropProps {
    diagramDispatch: DiagramProps["diagramDispatch"];
    rowIndex: number;
    tableId: EditTable["id"];
}

export const useReorderDrop = (
    props: DropProps
): { ref: Ref; isOver: boolean } => {
    const [isOver, ref] = DnD.useDrop({
        accept: TYPE,
        canDrop: (item: ReorderItem) => item.tableId === props.tableId,
        drop: (item: ReorderItem) => {
            const payload: MoveRowPayload = {
                fromIndex: item.rowIndex,
                toIndex: props.rowIndex,
                tableId: props.tableId,
            };
            props.diagramDispatch({ type: "move-row", payload });
        },
        collect: (monitor) => {
            const item = monitor.getItem() as null | ReorderItem;
            return monitor.isOver() && item?.tableId === props.tableId;
        },
    });
    return { isOver, ref };
};
