import { Icons } from "utilities/icon";
import * as Export from "app/header/export/text";
import { LibraryReducer } from "library/reducer";
import { DocStorage } from "library/storage/doc";
import { LibraryItemProps } from "../item";
import { Backend } from "backend/backend";
import { AuthContext } from "auth/auth";
import { useContext } from "react";
import { getStringId } from "utilities/id";
import { ButtonMenu, MenuItemProps } from "app/button/menu";
import { dialogConfirm } from "app/dialog/confirm";

const toFile = async (props: Props): Promise<void> =>
    Export.exportDiagramToFile(props.item.diagram, props.item.name);

const toClipboard = async (props: Props): Promise<void> =>
    Export.exportDiagramToClipboard(props.item.diagram);

interface Props extends LibraryItemProps {
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
}

export const LibraryItemMenu = (props: Props) => {
    const { isAuthenticated } = useContext(AuthContext);
    const { open, onOpenChange } = props;

    const duplicate = async (props: Props): Promise<void> => {
        const target = props.item.id;
        if (props.item.saved) {
            if (isAuthenticated) {
                const dupedItem = {
                    ...props.item,
                    name: `${getStringId(
                        props.all.map((item) => item.name),
                        props.item.name
                    )}`,
                };
                await Backend.createDiagram(dupedItem.name, dupedItem.diagram);
                const updatedDiagrams = await Backend.getDiagrams();
                props.setLibrary(LibraryReducer.setAll(updatedDiagrams));
            } else {
                await DocStorage.duplicate(props.item);
                props.setLibrary(LibraryReducer.duplicate(target));
            }
        }
    };

    const remove = async (props: Props): Promise<void> => {
        const yes = await dialogConfirm(
            [
                `Are you sure you want to delete "${props.item.name}"?`,
                "This action is NOT reversible.",
            ].join("\n")
        );
        if (yes === false) return;

        if (props.item.saved) {
            if (isAuthenticated) {
                await Backend.deleteDiagram(props.item);
            } else {
                await DocStorage.remove(props.item.id);
            }
        }
        props.setLibrary(LibraryReducer.remove(props.item.id));
    };

    const items: MenuItemProps[] = [
        { label: "Save to file…", fn: () => toFile(props) },
        { label: "Copy to clipboard", fn: () => toClipboard(props) },
        "divider",
        { label: "Duplicate", fn: () => duplicate(props) },
        "divider",
        { label: "Delete…", fn: () => remove(props) },
    ];
    return (
        <ButtonMenu
            open={open}
            onOpenChange={onOpenChange}
            button={{
                extraStyle: "h-[32px] w-[32px]",
                icon: Icons.more,
                color: "clear",
            }}
            items={items}
        />
    );
};
