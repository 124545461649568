import { Icons } from "utilities/icon";
import { LibraryItem as LibraryItemI, LibraryProps } from "library/library";
import * as React from "react";
import { LibraryItemMenu } from "./menu/menu";
import { LibraryItemName } from "./name/name";
import { LibraryItemShare } from "./share/share";
import { Button } from "app/button/button";

export interface LibraryItemProps {
    item: LibraryItemI;
    isCurrent: boolean;
    all: LibraryProps["library"]["all"];
    setLibrary: LibraryProps["setLibrary"];
}

export const LibraryItem = (props: LibraryItemProps) => {
    const [isEdit, setIsEdit] = React.useState<boolean>(false);
    const { item, setLibrary } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <div
            className={[
                "flex",
                props.isCurrent ? "bg-[var(--slate7)]" : "",
                "group",
            ].join(" ")}
        >
            <div
                className={[
                    "flex-[1_1_0px] overflow-hidden text-[var(--slate12)]",
                ].join(" ")}
            >
                <LibraryItemName
                    {...props}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                />
            </div>
            <div
                className={[
                    "group-hover:flex group-focus-within:flex group-focus:flex group-open:flex",
                    open ? "flex" : "hidden",
                    "text-[var(--slate11)]",
                ].join(" ")}
            >
                <LibraryItemShare item={item} setLibrary={setLibrary} />
                <Button
                    // Don't toggle the isEdit boolean here because the input
                    // already close the edit mode on blur
                    onClick={() => void setIsEdit(true)}
                    icon={Icons.edit}
                    color="clear"
                    extraStyle="h-[32px] w-[32px]"
                    selected={isEdit}
                />
                <div
                    onClick={() => {
                        setOpen(!open);
                    }}
                >
                    <LibraryItemMenu
                        open={open}
                        onOpenChange={setOpen}
                        {...props}
                    />
                </div>
            </div>
        </div>
    );
};
