import { BaseDiagramUnknown } from "./base";
import { BaseItemV1, BaseRowV1 } from "./base-v1";
import { BaseDiagramV2 } from "./base-v2";

export interface BaseDiagramV3
    extends Omit<BaseDiagramV2, "version" | "items"> {
    type: "base";
    version: 3;
    items: BaseItemV3[];
}

export interface BaseItemV3 extends Omit<BaseItemV1, "rows"> {
    rows: BaseRowV3[];
}

export interface BaseRowV3 extends BaseRowV1 {
    isPrimary: string;
}

export const isBaseDiagramV3 = (
    diagram: BaseDiagramUnknown
): diagram is BaseDiagramV3 => {
    return "version" in diagram && diagram.version === 3;
};

export const migrateBaseDiagramV2toV3 = (v2: BaseDiagramV2): BaseDiagramV3 => ({
    ...v2,
    type: "base",
    version: 3,
    items: v2.items.map((item) => ({
        ...item,
        rows: item.rows.map((row) => ({
            ...row,
            isPrimary: "false",
        })),
    })),
});
