import { EditDiagramUnknown } from "./edit";
import { EditRefV1, EditTableV1 } from "./edit-v1";
import { EditDiagramV2 } from "./edit-v2";

export interface EditDiagramV3
    extends Omit<EditDiagramV2, "version" | "tables"> {
    version: 3;
    tables: EditTableV3[];
    refs: EditRefV3[];
}

export const isEditDiagramV3 = (
    diagram: EditDiagramUnknown
): diagram is EditDiagramV3 => {
    return "version" in diagram && diagram.version === 3;
};

export interface EditSelectable {
    selected: boolean;
}

export interface EditTableV3
    extends Omit<EditTableV1, "isRowsExpand">,
        EditSelectable {
    rowsExpanded: boolean;
}

export interface EditRefV3 extends EditRefV1, EditSelectable {}

export const migrateEditDiagramV2toV3 = (v2: EditDiagramV2): EditDiagramV3 => ({
    ...v2,
    version: 3,
    tables: v2.tables.map((table) => ({
        ...table,
        rowsExpanded: table.isRowsExpand,
        selected: false,
    })),
    refs: v2.refs.map((ref) => ({
        ...ref,
        selected: false,
    })),
});
