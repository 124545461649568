import { DiagramElements } from "canvas/elements";
import { EditEdge } from "diagram/model/edit/edit";
import { getEdgeEnds } from "./ends/ends";
import { EdgeSplitY, EdgeSplitYState } from "./ends/split-y";
import { EdgeCross, EdgeCrossState } from "./main/cross";
import { getEdgeMain } from "./main/main";
import { EdgeSplitX, EdgeSplitXState } from "./main/split-x";

/** All paths necessary to render an Edge */
export interface EdgePaths {
    main: string;
    mainLength: number;
    from: string[];
    to: string[];
}

interface Props {
    elements: DiagramElements;
    edges: EditEdge[];
}

export type EdgePathsMap = Map<EditEdge["id"], EdgePaths>;

/** Mutable state for visual optimization */
export interface EdgePathState {
    splitY: EdgeSplitYState;
    splitX: EdgeSplitXState;
    cross: EdgeCrossState;
}

export const getEdgePaths = (props: Props): EdgePathsMap => {
    const { elements, edges } = props;
    const state: EdgePathState = {
        splitY: EdgeSplitY.init(),
        splitX: EdgeSplitX.init(),
        cross: EdgeCross.init(),
    };

    const map: EdgePathsMap = new Map();
    edges.forEach((edge) => {
        const edgeEnds = getEdgeEnds({ state, elements, edge });
        const [main, mainLength] = getEdgeMain({ state, edgeEnds });
        const [from, to] = [edgeEnds.from.paths, edgeEnds.to.paths];
        map.set(edge.id, { from, main, mainLength, to });
    });
    return map;
};
