import * as V1 from "./base-v1";
import * as V2 from "./base-v2";
import * as V3 from "./base-v3";

export type BaseDiagramUnknown =
    | V1.BaseDiagramV1
    | V2.BaseDiagramV2
    | V3.BaseDiagramV3;
export type BaseDiagramLatest = V3.BaseDiagramV3;

export type BaseDiagram = BaseDiagramLatest;
export type BaseRow = V3.BaseRowV3;
export type BaseColumn = V1.BaseColumnV1;
export type BaseItem = V3.BaseItemV3;
export type BaseRef = V1.BaseRefV1;
export type BaseRefSide = V1.BaseRefSideV1;

export const migrateBaseDiagram = (
    unknown: BaseDiagramUnknown
): BaseDiagramLatest => {
    if (V1.isBaseDiagramV1(unknown)) {
        const v2 = V2.migrateBaseDiagramV1toV2(unknown);
        return V3.migrateBaseDiagramV2toV3(v2);
    }
    if (V2.isBaseDiagramV2(unknown)) {
        return V3.migrateBaseDiagramV2toV3(unknown);
    }
    if (V3.isBaseDiagramV3(unknown)) {
        return unknown;
    }
    throw Error("Unknown base diagram version");
};

export const isBaseDiagramUknown = (
    diagram: unknown
): diagram is BaseDiagramUnknown => {
    if (typeof diagram !== "object") return false;
    // Starting from V2, BaseDiagram.type === "base"
    if ((diagram as V2.BaseDiagramV2).type === "base") return true; // V2
    // In V1 we need to depend on an ad-hoc check
    if ((diagram as V1.BaseDiagramV1).items !== undefined) return true; // V1
    return false;
};
