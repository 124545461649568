import { GRID_CELL } from "geometry/geometry";
import { Segment } from "geometry/line";

/**
 * This module prevents lines from overlapping vertically. This usually
 * happens when multiple Edges coming from the same table and to the same table
 * BAD:  A ----+
 *       B ----+
 *             |
 *             +----> C
 *             +----> D     It isn't clear whether A or B point to C
 */

interface State {
    /** Map from an "x" value to vertical Segments that are on that "x" */
    segmentsMap: Map<number, Segment[]>;
}

export type EdgeSplitXState = State;

const STEP = GRID_CELL / 1;

/** Can insert S1 into "arr" without overlapping with any Segment in "arr" */
const canInsert = (arr: Segment[], S1: Segment): boolean => {
    // @TODO: Use binary search for better performance?
    for (let i = 0; i < arr.length; i++) {
        const S2 = arr[i];
        // This is a simplified overlap check because we already known these
        // segments are all vertical and has the same x
        let b1 = S1.A.y - S2.A.y < 0;
        let b2 = S1.A.y - S2.B.y < 0;
        let b3 = S1.B.y - S2.A.y < 0;
        let b4 = S1.B.y - S2.B.y < 0;
        // S1 is on other side of S2 ---> no overlap
        if (b1 === b2 && b2 === b3 && b3 === b4) continue;
        return false; // short return when overlap
    }
    return true;
};

/**
 * Returns the distance that a Segment should be shifted horizontally to avoid
 * overlap.
 */
const getOffset = (state: State, S: Segment): number => {
    if (S.A.x !== S.B.x) throw Error("Segment is not vertical");
    let x = S.A.x;
    while (x > 0) {
        const segments = state.segmentsMap.get(x) ?? [];
        if (canInsert(segments, S)) return x;
        x = x - STEP; // Try on the left
    }
    return x;
};

const save = (state: State, S: Segment): void => {
    if (S.A.x !== S.B.x) throw Error("Segment is not vertical");
    const segments = state.segmentsMap.get(S.A.x) ?? [];
    segments.push(S);
    state.segmentsMap.set(S.A.x, segments);
};

const init = (): State => ({ segmentsMap: new Map() });

export const EdgeSplitX = {
    init,
    getOffset,
    save,
};
