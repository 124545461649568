import * as Base from "../base/base";
import * as Edit from "../edit/edit";

// Prepared maps from table's [name, schema] to EDIT ids to speed up EditRef
// conversion
type Ids = { table: number; rows: Map<string, number> };
type IdsMap = Map<string, Ids>; // string here is "<schema> . <name>"

const makeIdsMap = (
    fallbackSchema: string,
    tables: Edit.EditTable[]
): IdsMap => {
    const idsMap = new Map<string, Ids>();
    tables.forEach((table) => {
        const rows = new Map<string, number>();
        table.value.rows.forEach((row) => void rows.set(row.value.id, row.id));
        const schema = table.value.schema ?? fallbackSchema;
        const tuple = `${schema} . ${table.value.name}`;
        idsMap.set(tuple, { table: table.id, rows });
    });
    return idsMap;
};

const makeRefSide = (
    fallbackSchema: string,
    map: IdsMap,
    side: Base.BaseRefSide
): Edit.EditRefSide => {
    const schema = side.schema ?? fallbackSchema;
    const ids = map.get(`${schema} . ${side.name}`);
    if (ids === undefined) throw Error(`Table Ids "${side.name}" not found`);
    const rows = side.rows.map((editId) => {
        const realId = ids.rows.get(editId);
        if (realId === undefined) throw Error(`Row id "${editId}" not found`);
        return realId;
    });
    return { table: ids.table, rows };
};

const makeTable = (base: Base.BaseItem, index: number): Edit.EditTable => {
    const rows = base.rows.map((value, id) => ({
        id,
        value,
        isPrimary: value.isPrimary === "true",
    }));
    const { name, id, schema } = base;
    return {
        value: { rows, name, id, schema },
        // For performance reason, new tables should be rendered outside of the
        // viewport so they will all use TableInvisible until get layout-ed
        position: { top: -500, left: -500 },
        id: index,
        rowsExpanded: false,
        selected: false,
    };
};

const makeColumn = (base: Base.BaseColumn, index: number): Edit.EditColumn => {
    return { id: index, value: base };
};

const makeRef = (schema: string, idsMap: IdsMap) => {
    return (base: Base.BaseRef, index: number): Edit.EditRef => ({
        id: index,
        from: makeRefSide(schema, idsMap, base.from),
        to: makeRefSide(schema, idsMap, base.to),
        selected: false,
    });
};

export const makeEditDiagramFromBase = (
    base: Base.BaseDiagram
): Edit.EditDiagram => {
    const schema = base.schema;
    const tables: Edit.EditTable[] = base.items.map(makeTable);
    const edit: Edit.EditDiagram = {
        type: "edit",
        version: 4,
        schema,
        tables,
        refs: (() => {
            const idsMap = makeIdsMap(schema, tables);
            return base.refs.map(makeRef(schema, idsMap));
        })(),
        columns: base.columns.map(makeColumn),
        size: { width: 1000, height: 1000 },
    };
    return edit;
};
