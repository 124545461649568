/**
 * Whether the browser's focus is just leaving from an Input edit view. This
 * helps 2 things:
 * - Only set the next input string to edit mode if coming from another edit
 *   input via "Tab" key
 * - Allow keeping the "edit" flag when travelling through non-editable widgets
 *   like the remove button
 */
const ref = { current: false, timeout: 0 };

export const isJustLeftEdit = (): boolean => ref.current;

export const setJustLeftEdit = (): void => {
    window.clearTimeout(ref.timeout);
    ref.current = true;
    ref.timeout = window.setTimeout(() => {
        ref.current = false;
    }, 1000);
};
