interface Props {
    size: number;
}

export type { Props as ProgressCircleProps };

const R = 45;
// prettier-ignore
const SPINNER_TRACK = `M 50,50 m 0,-${R} a ${R},${R} 0 1 1 0,${R * 2} a ${R},${R} 0 1 1 0,-${R * 2}`;
const PATH_LENGTH = 280;
const STROKE_WIDTH = 4;
const MIN_STROKE_WIDTH = 16;
const SIZE_LARGE = 100;

const getViewBox = (strokeWidth: number) => {
    const radius = R + strokeWidth / 2;
    const viewBoxX = (50 - radius).toFixed(2);
    const viewBoxWidth = (radius * 2).toFixed(2);
    return `${viewBoxX} ${viewBoxX} ${viewBoxWidth} ${viewBoxWidth}`;
};

const getStroke = (props: Props) => {
    const width = Math.min(
        MIN_STROKE_WIDTH,
        (STROKE_WIDTH * SIZE_LARGE) / props.size
    );
    const value = 0.25;
    const offset = PATH_LENGTH - PATH_LENGTH * value;
    return { width, offset };
};

export const ProgressCircle = (props: Props): JSX.Element => {
    const stroke = getStroke(props);

    return (
        <span className={"block"}>
            <svg
                className={["block", "animate-spin"].join(" ")}
                width={props.size}
                height={props.size}
                strokeWidth={stroke.width}
                viewBox={getViewBox(stroke.width)}
            >
                <path
                    className={["stroke-black opacity-25 fill-none"].join(" ")}
                    d={SPINNER_TRACK}
                />
                <path
                    className={["stroke-white fill-none origin-center"].join(
                        " "
                    )}
                    strokeLinejoin="round"
                    d={SPINNER_TRACK}
                    pathLength={PATH_LENGTH}
                    strokeDasharray={`${PATH_LENGTH} ${PATH_LENGTH}`}
                    strokeDashoffset={stroke.offset}
                />
            </svg>
        </span>
    );
};
