import { addHostCallback } from "host/callbacks";
import React from "react";
import { Preferences, PreferencesProps } from "./preferences";

export type Theme = "light" | "dark-theme" | "auto";

const DARK_MODE_QUERY = "(prefers-color-scheme: dark)";

const resolveTheme = (theme: Theme): "light" | "dark-theme" => {
    if (theme === "light" || theme === "dark-theme") return theme;
    const isDark = window.matchMedia(DARK_MODE_QUERY).matches;
    return isDark ? "dark-theme" : "light";
};

export const allThemes: Theme[] = ["light", "dark-theme", "auto"];

const classes = document.documentElement.classList;

const applyTheme = (theme: Theme): void => {
    const resolved = resolveTheme(theme);
    if (classes.contains(resolved)) return;
    classes.remove("light");
    classes.remove("dark-theme");
    classes.add(resolved);
};

const getTheme = (text: string): Theme => {
    const theme = text as Theme;
    if (allThemes.includes(theme)) return theme;
    throw Error(`Theme is unknown: ${theme}`);
};

// INITIAL

// Theme change is significant so we try to avoid it as much as possible by
// setting the theme as soon as possible and use it in React's state
// initialization
export let initialTheme: null | Theme = null;

// Don't use addHostCallbackAsync here because we want to apply theme as soon
// as possible so don't queue the argument
addHostCallback("setTheme", (text: string) => {
    const theme = getTheme(text);
    applyTheme(theme); // Set immediately
    initialTheme = theme; // Save for React's initialization
});

const setTheme =
    (text: string) =>
    (prev: Preferences): Preferences => ({
        ...prev,
        theme: getTheme(text),
    });

export const useThemePreference = (props: PreferencesProps) => {
    const { theme } = props.preferences;
    const { setPreferences } = props;

    React.useEffect(() => {
        applyTheme(theme);
    }, [theme]);

    // Watch system for theme change in case of "auto"
    React.useEffect(() => {
        if (theme !== "auto") return;
        const medias = window.matchMedia(DARK_MODE_QUERY);
        const listener = (event: MediaQueryListEvent): void => {
            applyTheme(event.matches ? "dark-theme" : "light");
        };
        medias.addEventListener("change", listener);
        return () => medias.removeEventListener("change", listener);
    }, [theme]);

    React.useEffect(() => {
        addHostCallback("setTheme", (text: string): void => {
            // Don't need to applyTheme manually here since the effect is
            // already registered
            setPreferences(setTheme(text));
        });
    }, [setPreferences]);
};
