import { Select } from "app/select/select";

interface Props {
    value: string;
    setValue: (value: string) => void;
}

const TRUE_PREFIX = ["T", "t", "1", "Y", "y"]; // true, 1, yes

const isBoolean = (text: string): boolean =>
    TRUE_PREFIX.includes(text.charAt(0));

export const RowInputBoolean = (props: Props) => {
    const { value } = props;

    return (
        <div className={["w-[104px]"].join(" ")}>
            <div className="transition-none">
                <Select.Root
                    value={isBoolean(value) ? value.toUpperCase() : "FALSE"}
                    onValueChange={(bool) => {
                        props.setValue(bool);
                    }}
                    hasArrow={true}
                    valueElement={
                        <Select.Value asChild>
                            <div
                                className={[
                                    "flex flex-1 justify-center items-center text-[var(--slate12)]",
                                ].join(" ")}
                            >
                                {isBoolean(value)
                                    ? value.toUpperCase()
                                    : "FALSE"}
                            </div>
                        </Select.Value>
                    }
                    triggerBgColor="white"
                >
                    <Select.Item key={"true"} value={"TRUE"}>
                        {"TRUE"}
                    </Select.Item>
                    <Select.Item key={"false"} value={"FALSE"}>
                        {"FALSE"}
                    </Select.Item>
                </Select.Root>
            </div>
        </div>
    );
};
