import { Icons } from "utilities/icon";
import { LibraryReducer } from "library/reducer";
import { AppLibraryProps } from "../library";
import { Backend } from "backend/backend";
import { AuthContext } from "auth/auth";
import { useContext } from "react";
import { emptyDiagram } from "diagram/state/state";
import { getStringId } from "utilities/id";
import { Button } from "app/button/button";

export const LibraryCreate = (props: AppLibraryProps) => {
    const { library, setLibrary } = props;
    const { isAuthenticated } = useContext(AuthContext);

    const createNewDiagram = async () => {
        if (isAuthenticated) {
            const names = library.all.map((item) => item.name);
            const name = getStringId(names, "Untitled");
            const diagram = emptyDiagram;
            const createdDiagramID = await Backend.createDiagram(name, diagram);

            const newLibraryItem = {
                id: createdDiagramID,
                name,
                saved: true,
                shared: false,
                diagram,
            };
            setLibrary(LibraryReducer.addItem(newLibraryItem));
        } else {
            setLibrary(LibraryReducer.add());
        }
    };

    return (
        <Button
            onClick={createNewDiagram}
            children="Diagram"
            icon={Icons.plus}
            color="solid"
        />
    );
};
