import { SelectRoot, SelectRootProps } from "./root";
import { SelectItem } from "./item";
import * as Radix from "@radix-ui/react-select";
import { SelectValue } from "./value";

export type { SelectRootProps as SelectProps };

const Separator = (props: Radix.SelectSeparatorProps): JSX.Element => {
    const { ...native } = props;
    return (
        <div className="px-2 py-1">
            <Radix.Separator className="border-t border-gray-700" {...native} />
        </div>
    );
};

export const Select = {
    Root: SelectRoot,
    Item: SelectItem,
    Value: SelectValue,
    Separator,
};
