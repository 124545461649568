import { Border } from "border/border";
import { TableEdges } from "canvas/edges-map";
import { RowInactive } from "canvas/row/inactive/inactive";
import { EditColumn, EditRow } from "diagram/model/edit/edit";
import { groupTableRows } from "../rows";
import { TableWrapper, TableWrapperProps } from "../wrapper/wrapper";

interface Props extends TableWrapperProps {
    tableEdges: TableEdges | null;
    columns: EditColumn[];
}

const className = [
    "bg-[var(--slate2)]",
    "border-[var(--slate9)] border-[1px] border-solid",
    "text-[var(--slate12)]",
    "shadow-[0_1px_4px_rgba(0,0,0,0.1)]",
].join(" ");

interface RowProps extends Props {
    row: EditRow;
}

const Row = (props: RowProps) => (
    <RowInactive
        key={props.row.id}
        edgesToCount={props.tableEdges?.get(props.row.id)?.to.length ?? 0}
        elements={props.elements}
        tableId={props.table.id}
        row={props.row}
        columns={props.columns}
    />
);

/**
 *  Readonly Table, to render in screenshot mode
 */
export const TableInactive = (props: Props) => {
    const [rows] = groupTableRows(props.table, props.tableEdges);
    return (
        <TableWrapper {...props}>
            <div className={className}>
                <div
                    className={[
                        "leading-normal text-center font-semibold p-3",
                    ].join(" ")}
                >
                    {props.table.value.name}
                </div>
                <Border color="weak" />
                <div className={"py-3"}>
                    {rows.map((item) =>
                        Array.isArray(item) ? (
                            <div
                                className={["text-center leading-normal"].join(
                                    " "
                                )}
                                key={item[0].id}
                                children={`${item.length} columns hidden`}
                            />
                        ) : (
                            <Row key={item.id} {...props} row={item} />
                        )
                    )}
                </div>
            </div>
        </TableWrapper>
    );
};
