import { EditEdge, EditRow, EditTable } from "diagram/model/edit/edit";

const removeFieldName: symbol = Symbol("delete");
const edgeFieldName: symbol = Symbol("edge");

export type FieldName = symbol | string;

/** To avoid creating many refs, we will depend on dataset instead */
const getFieldName = (target: HTMLDivElement): FieldName => {
    if (target.dataset.rowRemove === "true") {
        return DiagramElementsUtils.removeFieldName;
    } else if (target.dataset.rowEdge === "true") {
        return DiagramElementsUtils.edgeFieldName;
    } else {
        const column = target.dataset.rowField;
        if (column === undefined) throw Error("No data-row-field");
        return column;
    }
};

export interface RowElements {
    element: HTMLDivElement | null;
    fields: Map<FieldName, HTMLDivElement>;
}

const newRow = (): RowElements => ({ element: null, fields: new Map() });

export interface TableElements {
    element: HTMLDivElement | null;
    rows: Map<EditRow["id"], RowElements>;
}

const newTable = (): TableElements => ({ element: null, rows: new Map() });

export interface DiagramElements {
    viewport: HTMLDivElement | null;
    canvas: HTMLDivElement | null;
    tables: Map<EditTable["id"], TableElements>;
    edges: Map<EditEdge["id"], SVGGElement>;
}

const newDiagram = (): DiagramElements => ({
    viewport: null,
    canvas: null,
    tables: new Map(),
    edges: new Map(),
});

export const DiagramElementsUtils = {
    newDiagram,
    newRow,
    newTable,
    edgeFieldName,
    removeFieldName,
    getFieldName,
};
