import { DiagramElements } from "canvas/elements";
import { arrangeDiagram } from "diagram/arrange/arrange";
import { EditDiagram } from "diagram/model/edit/edit";
import { parseDiagram } from "diagram/model/parse/parse";
import { DiagramProps } from "diagram/state/state";
import { addHostCallbackFuture } from "host/callbacks";
import React from "react";

const registerJson = addHostCallbackFuture("importDiagramJSON");
const registerObject = addHostCallbackFuture("importDiagramObject");
const registerBase64 = addHostCallbackFuture("importDiagramBase64");

interface Props {
    savedDiagram: React.MutableRefObject<EditDiagram | null>;
    elements: DiagramElements;
    diagramDispatch: DiagramProps["diagramDispatch"];
}

export const useDiagramHostCallbacks = (props: Props) => {
    const { savedDiagram, elements, diagramDispatch } = props;
    React.useEffect(() => {
        const importDiagram = (source: unknown): void => {
            (async () => {
                await new Promise((r) => window.setTimeout(r, 100));
                // To prevent auto-saving when importing, we MUST do all the
                // parsing and layout here and only replace the diagram in the
                // state so its identity is kept.
                let diagram = parseDiagram(source);
                // Mark this as saved to prevent auto-saving when replace
                savedDiagram.current = diagram;
                diagramDispatch({
                    type: "replace-as-is",
                    payload: { diagram },
                });
                await new Promise((r) => window.setTimeout(r, 100));
                // Wait for React to render so "elements" is updated, then we
                // calculate the layout
                diagram = arrangeDiagram(elements, diagram);
                savedDiagram.current = diagram; // Also need to marked this again
                diagramDispatch({
                    type: "replace-as-is",
                    payload: { diagram },
                });
            })();
        };
        // Having 3 separated methods is only for backware-compatibility. The
        // importDiagram can handle any format
        registerJson(importDiagram);
        registerObject(importDiagram);
        registerBase64(importDiagram);
    }, [diagramDispatch, elements, savedDiagram]);
};
