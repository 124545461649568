export interface EdgeDragResult {
    tableId: number;
    rowId: number;
}

export interface EdgeDragModel {
    type: "row";
    tableId: number;
    rowId: number;
    elementRef: React.RefObject<HTMLDivElement>;
}

export const EdgeDrag = {
    dragId: "drag",
    dragElementClass: "edge-drag-button",
};
