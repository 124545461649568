import { FooterClose } from "./close/close";
import { FooterCopyright } from "./copyright/copyright";
import { FooterLinks } from "./links/links";

export const Footer = () => (
    <div
        className={[
            "px-2 relative lg:flex lg:justify-between md:block",
            "bg-[var(--slate2)]",
        ].join(" ")}
    >
        <FooterClose />
        <div
            className={["p-2 flex justify-center"].join(" ")}
            children={<FooterLinks />}
        />
        <div
            className={["p-2 flex justify-center"].join(" ")}
            children={<FooterCopyright />}
        />
    </div>
);
