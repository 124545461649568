import { postMessageToHost } from "./message";

export const HostSqlDrivers = [
    "MySQL",
    "MariaDB",
    "SQLite",
    "PostgreSQL",
    "Cassandra",
    "Snowflake",
    "MicrosoftSQLServer",
] as const;

export type HostSqlDriver = typeof HostSqlDrivers[number];

// Interface defined by TablePlus app
interface HostSqlOptions {
    data: object;
    driver: HostSqlDriver;
}

export const exportSqlToHost = (options: HostSqlOptions): void => {
    postMessageToHost("exportSQL", options);
};
