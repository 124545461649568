import { Border } from "border/border";
import { DiagramElements } from "canvas/elements";
import { DiagramHistoryProps } from "diagram/state/state";
import { isAppHost } from "host/host";
import { LibraryProps } from "library/library";
import * as React from "react";
import { HeaderAnimation } from "./animation/animation";
import { HeaderArrange } from "./arrange/arrange";
import { HeaderExport } from "./export/export";
import { HeaderFooter } from "./footer/footer";
import { HeaderHistory } from "./history/history";
import { HeaderImport } from "./import/import";
import { HeaderLibrary } from "./library/library";
import { HeaderLogin } from "./login/login";
import { HeaderMore } from "./more/more";
import { HeaderReport } from "./report/report";
import { HeaderSearch } from "./search/search";
import { headerHostStyle } from "./styles/host";
import { HeaderTable } from "./table/table";
import { HeaderTheme } from "./theme/theme";
import { HeaderZoom } from "./zoom/zoom";

const sMain = [headerHostStyle.main].join(" ");
const sMore = [headerHostStyle.more].join(" ");

interface Props extends DiagramHistoryProps {
    elements: DiagramElements;
    setLibrary: LibraryProps["setLibrary"];
}

const EditControls = (props: Props) => (
    <>
        <div
            className={[
                "px-2 h-[64px] flex items-center",
                headerHostStyle.box,
            ].join(" ")}
        >
            <HeaderImport
                setLibrary={props.setLibrary}
                elements={props.elements}
                diagramDispatch={props.diagramHistoryDispatch}
            />
        </div>
        <div
            className={[
                "px-2 h-[64px] flex items-center",
                headerHostStyle.box,
            ].join(" ")}
        >
            <HeaderExport diagram={props.diagramHistory.present} />
        </div>
        <div
            className={[
                "px-2 h-[64px] flex items-center",
                headerHostStyle.box,
            ].join(" ")}
        >
            <HeaderTable
                diagram={props.diagramHistory.present}
                diagramDispatch={props.diagramHistoryDispatch}
                elements={props.elements}
            />
        </div>
        <div
            className={[
                "px-2 h-[64px] flex items-center",
                headerHostStyle.box,
            ].join(" ")}
        >
            <HeaderHistory
                diagramHistory={props.diagramHistory}
                diagramHistoryDispatch={props.diagramHistoryDispatch}
            />
        </div>
    </>
);
const ViewControls = (props: Props) => {
    return (
        <>
            <div
                className={[
                    "px-2 h-[64px] flex items-center",
                    headerHostStyle.box,
                ].join(" ")}
            >
                <HeaderArrange
                    diagram={props.diagramHistory.present}
                    diagramDispatch={props.diagramHistoryDispatch}
                    elements={props.elements}
                />
            </div>
            <div
                className={[
                    "px-2 h-[64px] flex items-center",
                    headerHostStyle.box,
                ].join(" ")}
            >
                <HeaderZoom />
            </div>
            <div
                className={[
                    "px-2 h-[64px] flex items-center",
                    headerHostStyle.box,
                ].join(" ")}
            >
                <HeaderLogin />
            </div>
        </>
    );
};

export const Header = (props: Props) => {
    const [more, setMore] = React.useState(false);
    return (
        <div
            className={[headerHostStyle.container, "bg-[var(--slate2)]"].join(
                " "
            )}
        >
            <div className={[sMain, "flex items-center px-2"].join(" ")}>
                <div
                    className={[
                        "px-2 h-[64px] flex items-center",
                        headerHostStyle.box,
                    ].join(" ")}
                >
                    <HeaderLibrary />
                </div>
                <div className={["lg:flex md:hidden sm:hidden"].join(" ")}>
                    <EditControls {...props} />
                </div>
                <div
                    className={[
                        "px-2 h-[64px] flex items-center",
                        headerHostStyle.box,
                        "flex-1",
                    ].join(" ")}
                >
                    <HeaderSearch
                        diagram={props.diagramHistory.present}
                        diagramDispatch={props.diagramHistoryDispatch}
                        elements={props.elements}
                    />
                </div>
                <div className={["lg:flex md:flex sm:hidden"].join(" ")}>
                    <ViewControls {...props} />
                </div>
                <div
                    className={[
                        "px-2 h-[64px] flex items-center",
                        headerHostStyle.box,
                    ].join(" ")}
                >
                    <HeaderMore more={more} setMore={setMore} />
                </div>
            </div>
            {more && (
                <>
                    <Border color="weak" />
                    <div
                        className={["flex items-center px-2", sMore].join(" ")}
                    >
                        <div
                            className={["lg:hidden md:flex sm:flex"].join(" ")}
                        >
                            <EditControls {...props} />
                        </div>
                        <div
                            className={["lg:hidden md:hidden sm:flex"].join(
                                " "
                            )}
                        >
                            <ViewControls {...props} />
                        </div>
                        {isAppHost === false && (
                            <div
                                className={[
                                    "px-2 h-[64px] flex items-center",
                                    headerHostStyle.box,
                                ].join(" ")}
                                children={<HeaderTheme />}
                            />
                        )}
                        <div
                            className={[
                                "px-2 h-[64px] flex items-center",
                                headerHostStyle.box,
                            ].join(" ")}
                            children={<HeaderAnimation />}
                        />
                        <div
                            className={[
                                "px-2 h-[64px] flex items-center",
                                headerHostStyle.box,
                            ].join(" ")}
                            children={<HeaderFooter />}
                        />
                        <div className="block flex-[1_0_0px]" />
                        <div
                            className={[
                                "px-2 h-[64px] flex items-center",
                                headerHostStyle.box,
                            ].join(" ")}
                            children={<HeaderReport />}
                        />
                    </div>
                </>
            )}
        </div>
    );
};
