import { TABLE_BODY_WIDTH } from "canvas/table/geometry";
import { EditColumn } from "diagram/model/edit/edit";
import * as React from "react";
import { Button } from "app/button/button";
import { IconComponent, Icons } from "utilities/icon";

interface Props {
    columns: EditColumn[];
    columnsExpanded: boolean;
    setColumnsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

const renderColumn = (column: EditColumn, index: number) => (
    <div
        key={column.id}
        className={[
            "px-2",
            index === 1 ? "text-right" : "",
            "text-[var(--slate12)]",
        ].join(" ")}
        style={{ width: TABLE_BODY_WIDTH / 2 }}
        children={column.value.name}
    />
);

const getExpandIcon = (isExpand: Boolean): IconComponent =>
    isExpand ? Icons.doubleChevronLeft : Icons.doubleChevronRight;

export const TableColumns = (props: Props) => (
    <div
        className={[
            "bg-[var(--slate7)]",
            "border-[var(--gray7)]",
            "animate-top-slide-in leading-5 flex border-none border-[1px]",
            "text-[var(--slate11)]",
        ].join(" ")}
    >
        <div className="block flex-0 h-2 w-2" />

        {/* Padding, should be the same as the "delete" button on row */}
        <div className={["h-6 px-2 w-fit"].join(" ")}>
            <div className="px-3" />
        </div>

        {props.columns
            .slice(0, props.columnsExpanded ? undefined : 2)
            .map(renderColumn)}

        <div className={["h-6 px-2 w-fit"].join(" ")}>PK</div>

        {/* Should be the same as the "drag" button on row */}
        <Button
            className="px-3.5"
            color="clear"
            onClick={() => props.setColumnsExpanded((b) => !b)}
            icon={getExpandIcon(props.columnsExpanded)}
            iconSize={12}
        />
        <div className="block flex-0 h-2 w-2" />
    </div>
);
