import { TABLE_MARGIN_WIDTH } from "canvas/table/geometry";
import * as Edit from "diagram/model/edit/edit";
import { Position } from "geometry/geometry";
import { getNumberId, getStringId } from "utilities/id";
import { DiagramReducer } from "../../reducer";
import { getDefaultRow } from "../../row/add";
import { selectAllTables } from "../select/all";
import { getNewTablePosition } from "./position";

export interface AddTablePayload {
    id: number | null;
    position: Position | null;
}

type Payload = AddTablePayload;

const getTableId = (diagram: Edit.EditDiagram, payload: Payload): number => {
    const ids: number[] = diagram.tables.map((table) => table.id);
    if (payload.id === null) return getNumberId(ids);
    if (ids.includes(payload.id)) throw Error(`Table id ${payload.id} is used`);
    return payload.id;
};

export const addTable: DiagramReducer<Payload> = (prev, payload) => {
    // Adding new table will clear all previous selection
    const diagram = selectAllTables(prev, { selected: false });
    // Value
    const valueIds = diagram.tables.map((table) => table.value.id);
    const name = getStringId(valueIds, "table");
    const row = getDefaultRow(null, diagram.columns, []);
    // Table
    const table: Edit.EditTable = {
        id: getTableId(diagram, payload),
        value: { name, id: name, schema: "new", rows: [row] },
        position: payload.position ?? getNewTablePosition(diagram),
        rowsExpanded: false,
        selected: true,
    };
    // Diagram
    const width = Math.max(
        diagram.size.width,
        table.position.left + TABLE_MARGIN_WIDTH
    );
    return {
        ...diagram,
        tables: diagram.tables.concat(table),
        size: { ...diagram.size, width },
    };
};
