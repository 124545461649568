import { AuthContext } from "auth/auth";
import { Backend } from "backend/backend";
import { EditDiagram } from "diagram/model/edit/edit";
import { ReplaceAsIsDiagramPayload } from "diagram/state/reducer/diagram/diagram";
import { DiagramProps } from "diagram/state/state";
import { diff } from "just-diff";
import { LibraryProps } from "library/library";
import { LibraryReducer } from "library/reducer";
import { DocStorage } from "library/storage/doc";
import * as React from "react";

export const useDiagramSync = (
    pastDiagram: EditDiagram,
    { diagram, diagramDispatch }: DiagramProps,
    { library, setLibrary }: LibraryProps
): {
    savedDiagram: React.MutableRefObject<EditDiagram | null>;
} => {
    const libraryDiagram = React.useRef<EditDiagram | null>(null);
    const storageDiagram = React.useRef<EditDiagram | null>(null);

    const { current, all } = library;
    const currentItem = library.all.find((i) => i.id === current);

    const { isAuthenticated } = React.useContext(AuthContext);

    // Save current diagram to storage
    React.useEffect(() => {
        if (current === null) return;
        if (storageDiagram.current === null) return;
        if (storageDiagram.current === diagram) return;
        if (currentItem === undefined) return;
        if (pastDiagram === undefined) return;

        const diagramDiff = diff(pastDiagram, diagram);
        var noSignificantChanges = false;

        for (var i = 0; i < diagramDiff.length; i++) {
            // If the only change is the "selected" property, don't save
            const changedProperty = diagramDiff[i].path[2];
            if (changedProperty === "selected") {
                noSignificantChanges = true;
            }
        }
        if (noSignificantChanges) {
            return;
        }
        const savedItem = { ...currentItem, diagram };
        if (isAuthenticated) {
            Backend.updateDiagram(savedItem);
        } else {
            DocStorage.set(savedItem);
        }
        setLibrary(LibraryReducer.save(current));
        storageDiagram.current = diagram;
    }, [
        pastDiagram,
        current,
        diagram,
        setLibrary,
        currentItem,
        isAuthenticated,
    ]);

    // Save current diagram to library
    React.useEffect(() => {
        if (current === null) return;
        if (libraryDiagram.current === null) return;
        if (libraryDiagram.current === diagram) return;
        libraryDiagram.current = diagram;
        setLibrary(LibraryReducer.set(current, diagram));
    }, [diagram, current, setLibrary]);

    // Load current diagram from library
    React.useEffect(() => {
        if (current === null) return;
        const item = all.find((i) => i.id === current);
        if (item === undefined) throw Error("Item is undefined");
        const next = item.diagram;
        libraryDiagram.current = next;
        storageDiagram.current = next;
        const payload: ReplaceAsIsDiagramPayload = { diagram: next };
        diagramDispatch({ type: "replace-as-is", payload });
    }, [current, all, diagramDispatch]);

    // Save current diagram via diagram changes
    // Note: Due to the use of savedDiagram ref, this effect MUST be run before
    // the "load" effect
    // React.useEffect(() => {
    //     if (current === null) return;
    //     setLibrary(LibraryReducer.save(current, diagram));
    //     if (savedDiagram.current === null) return; // Not loaded yet
    //     if (savedDiagram.current === diagram) return;
    //     savedDiagram.current = diagram;
    //     const text = stringifyDiagram(diagram, "edit", "json");
    //     DocStorage.set(current, text);
    // }, [diagram, current, setLibrary]);

    // Load current diagram
    // React.useEffect(() => {
    //     if (library.current === null) return;
    //     const item = library.all.find((i) => i.name === library.current);
    //     if (item === undefined) throw Error("Item is undefined");
    //     const next = item.diagram;
    //     savedDiagram.current = next;
    //     const payload: ReplaceAsIsDiagramPayload = { diagram: next };
    //     diagramDispatch({ type: "replace-as-is", payload });
    // }, [library, diagramDispatch]);

    return { savedDiagram: storageDiagram };
};
