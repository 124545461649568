import { Icons } from "utilities/icon";
import { EdgeCreateForm } from "canvas/edge/create/form";
import { EdgeDrag, EdgeDragModel, EdgeDragResult } from "canvas/edge/drag/drag";
import { EditEdge } from "diagram/model/edit/edit";
import { AddRefPayload } from "diagram/state/reducer/ref/add";
import { RemoveRefPayload } from "diagram/state/reducer/ref/remove";
import { DiagramProps } from "diagram/state/state";
import * as React from "react";
import { DragSourceMonitor, useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { Button } from "app/button/button";
import { Popover } from "app/popover/popover";

interface Props {
    tableId: number;
    rowId: number;
    diagramDispatch: DiagramProps["diagramDispatch"];
    edge: EditEdge | null;
}

const removeEdge = (props: Props) => {
    if (props.edge === null) return;
    const payload: RemoveRefPayload = { id: props.edge.id };
    props.diagramDispatch({ type: "remove-ref", payload });
};

const getIsDragging = (props: Props, monitor: DragSourceMonitor): boolean => {
    if (monitor.isDragging() === false) return false;
    if (monitor.getItemType() !== EdgeDrag.dragId) return false;
    const { tableId, rowId } = monitor.getItem() as EdgeDragModel;
    return props.tableId === tableId && props.rowId === rowId;
};

const addEdge = (props: Props, monitor: DragSourceMonitor) => {
    const result = monitor.getDropResult() as null | EdgeDragResult;
    if (result === null) return;
    const payload: AddRefPayload = {
        from: { table: props.tableId, rows: [props.rowId] },
        to: { table: result.tableId, rows: [result.rowId] },
    };
    props.diagramDispatch({ type: "add-ref", payload });
};

export const RowEdge = (props: Props) => {
    const { rowId, tableId } = props;

    const elementRef = React.useRef<HTMLDivElement>(null);

    const [popoverIsOpen, setPopoverIsOpen] = React.useState(false);

    const [isDragging, dragRef, previewRef] = useDrag({
        type: EdgeDrag.dragId,
        item: () => {
            removeEdge(props);
            return { rowId, tableId };
        },
        end: (_item, monitor) => addEdge(props, monitor),
        collect: (monitor) => getIsDragging(props, monitor),
    });

    React.useEffect(() => void previewRef(getEmptyImage()), [previewRef]);
    React.useEffect(() => void dragRef(elementRef), [elementRef, dragRef]);

    const isActive = isDragging || props.edge !== null;
    return (
        // This is not completely great yet... for some reason we can't drag
        // from the button's padding area. That means only the icon area is
        // draggable
        <div
            className={[
                "block animate-right-slide-in",
                EdgeDrag.dragElementClass,
            ].join(" ")}
            ref={elementRef}
            data-dragging={isDragging}
        >
            <Popover.Root open={popoverIsOpen} onOpenChange={setPopoverIsOpen}>
                <Popover.Trigger asChild>
                    <div>
                        <Button
                            onClick={() => setPopoverIsOpen(true)}
                            color="clear"
                            selected={popoverIsOpen}
                            icon={isActive ? Icons.selection : Icons.circle}
                            iconSize={12}
                            extraStyle="m-1 text-[var(--green10)]"
                        />
                    </div>
                </Popover.Trigger>
                <Popover.Content side="right" sideOffset={0}>
                    <EdgeCreateForm
                        defaultFrom={{
                            table: props.tableId,
                            rows: [props.rowId],
                        }}
                        diagramDispatch={props.diagramDispatch}
                        edge={props.edge}
                    />
                    <Popover.Arrow />
                </Popover.Content>
            </Popover.Root>
        </div>
    );
};
