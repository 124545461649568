import { Icons } from "utilities/icon";
import { Preferences, PreferencesContext } from "preferences/preferences";
import * as React from "react";
import { Button } from "app/button/button";

const closeFooter = (prev: Preferences): Preferences => ({
    ...prev,
    footerVisible: false,
});

export const FooterClose = () => {
    const { setPreferences } = React.useContext(PreferencesContext);

    return (
        <Button
            color="clear"
            onClick={() => setPreferences(closeFooter)}
            icon={Icons.chevronDown}
            extraStyle="absolute top-0 left-0 w-full h-3"
            paddingStyle="px-2"
            iconSize={12}
        />
    );
};
