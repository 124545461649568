import axios from "axios";
import { Config } from "config/config";
import { EditDiagram } from "diagram/model/edit/edit";
import { parseDiagram } from "diagram/model/parse/parse";
import { stringifyDiagram } from "diagram/model/stringify/stringify";
import { LibraryItem } from "library/library";
import { toast } from "react-hot-toast";

export const instance = axios.create({
    baseURL: Config.BASE_URL,
    withCredentials: true,
});

instance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        if (error.response) {
            const res = error.response.data;
            const message = res["Message"];
            toast.error(message);
        } else {
            toast.error(`An error occured: ${error.message}`);
        }
        if (
            error.response.status === 403 ||
            error.response.status === 500 ||
            error.response.status === 404
        ) {
            window.location.href = "/";
        }
    }
);

export type DiagramID = number | null;

const getDiagrams = async () => {
    const data = await instance.get("/api/diagrams").then((response) => {
        const data = response.data["Data"];
        if (data.length === 0) {
            return [];
        }

        const diagrams: LibraryItem[] = data.diagrams.map((diagram: any) => {
            const parsedDiagram = parseDiagram(diagram.content);

            return {
                id: diagram.id,
                name: diagram.name,
                saved: true,
                shared: diagram.shared,
                diagram: parsedDiagram,
            };
        });
        return diagrams;
    });

    return data ?? [];
};

const updateDiagram = async (diagram: LibraryItem) => {
    const data = {
        name: diagram.name,
        id: diagram.id,
        content: stringifyDiagram(diagram.diagram, "edit", "json"),
    };

    await instance.put(`/api/diagrams/${diagram.id}`, data);
};

const getSharedDiagram = async (uniqueToken: string) => {
    const sharedDiagram: LibraryItem = await instance
        .get(`/share/${uniqueToken}`)
        .then((response) => {
            const data = response.data["Data"];
            const id = data.ID;
            const name = data.Name;
            const diagramText = data.Content;
            const shared = data.Shared;

            const parsedDiagram = parseDiagram(diagramText);

            return {
                id: id,
                name: name,
                saved: true,
                diagram: parsedDiagram,
                shared: shared,
            };
        });

    return sharedDiagram;
};

const getSharedDiagramURL = async (diagram: LibraryItem) => {
    const sharedURL: string = await instance
        .get(`/api/share/${diagram.id}`)
        .then((response) => {
            const data = response.data["Data"];
            const url = data.url;
            return url;
        });
    return sharedURL;
};

const shareDiagram = async (diagram: LibraryItem) => {
    const sharedURL: string = await instance
        .post(`/api/share/${diagram.id}`)
        .then((response) => {
            const data = response.data["Data"];
            const url = data.url;
            return url;
        });
    return sharedURL;
};

const createDiagram = async (name: string, diagram: EditDiagram) => {
    const data = {
        name: name,
        content: stringifyDiagram(diagram, "edit", "json"),
    };

    const createdDiagramID: number = await instance
        .post(`/api/diagrams/`, data)
        .then((response) => {
            const data = response.data["Data"];
            const newDiagramID = data.ID;
            return newDiagramID;
        });

    return createdDiagramID;
};

const unshareDiagram = async (diagram: LibraryItem) => {
    await instance.delete(`/api/share/${diagram.id}`);
};

const deleteDiagram = async (diagram: LibraryItem) => {
    await instance.delete(`/api/diagrams/${diagram.id}`);
};

export const Backend = {
    getDiagrams,
    getSharedDiagram,
    getSharedDiagramURL,
    shareDiagram,
    unshareDiagram,
    updateDiagram,
    createDiagram,
    deleteDiagram,
};
