import { TABLE_MARGIN_WIDTH } from "canvas/table/geometry";
import { EditDiagram } from "diagram/model/edit/edit";
import { Position, Size } from "geometry/geometry";

interface AppendPosition {
    top: number;
    leftIndex: number;
}

export interface MasonryNode {
    id: number;
    size: Size;
}

export type MasonryMap = Map<number, Position>;

const area = (node: MasonryNode) => node.size.height * node.size.width;

// Try to make a 2:1 rectangle
const getColumnCount = (nodes: MasonryNode[]): number => {
    // Should be bigger than the biggest node's width
    const minWidth = nodes.reduce((max, node) => {
        return max > node.size.width ? max : node.size.width;
    }, -Infinity);
    // Total area
    const totalArea = nodes.reduce((sum, node) => sum + area(node), 0);
    const expectedWidth = Math.sqrt(totalArea / 2) * 2;
    const width = Math.max(expectedWidth, minWidth);
    // 1 column is TABLE_OFFSET_WIDTH px wide
    return Math.ceil(width / TABLE_MARGIN_WIDTH);
};

export const getMasonryLayout = (diagrams: EditDiagram[]): MasonryMap => {
    const nodes: MasonryNode[] = diagrams.map((diagram) => ({
        id: diagram.tables[0].id,
        size: diagram.size,
    }));

    const columnsCount = getColumnCount(nodes);
    const tops = Array(columnsCount).fill(0);
    const positions = new Map<number, Position>();

    // Max top is the actual top if a target of "width" is put at "start"
    const getRangeMaxTop = (start: number, columns: number): number => {
        if (start + columns > tops.length) return Infinity;
        let max = -Infinity;
        for (let i = 0; i < columns; i++) {
            const top = tops[start + i];
            max = Math.max(top, max);
        }
        return max;
    };

    const getAppendPosition = (columns: number): AppendPosition => {
        // Actual top if put target at "index"
        const candidates = tops.map((_top, index) => {
            const height = getRangeMaxTop(index, columns);
            return { leftIndex: index, top: height };
        });
        // Pick the one with smallest top
        return candidates.reduce((prev, current) =>
            current.top < prev.top ? current : prev
        );
    };

    const appendTarget = (target: MasonryNode) => {
        const columns = target.size.width / TABLE_MARGIN_WIDTH;
        const position = getAppendPosition(columns);
        // Update "tops" map
        const newHeight = position.top + target.size.height;
        for (let i = 0; i < columns; i++) {
            tops[position.leftIndex + i] = newHeight;
        }
        // Set position
        positions.set(target.id, {
            left: position.leftIndex * TABLE_MARGIN_WIDTH,
            top: position.top,
        });
    };

    nodes
        .sort((a, b) => area(b) - area(a)) // Put big table first
        .forEach(appendTarget);
    return positions;
};
