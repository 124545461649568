import { TableEdges } from "canvas/edges-map";
import { DiagramElements } from "canvas/elements";
import { RowBlank } from "canvas/row/blank/blank";
import { ROW_HEIGHT } from "canvas/row/geometry";
import { TABLE_CLIENT_WIDTH } from "canvas/table/geometry";
import { groupTableRows } from "canvas/table/rows";
import { EditRow, EditTable } from "diagram/model/edit/edit";
import * as React from "react";

interface Props {
    table: EditTable;
    tableEdges: TableEdges | null;
    elements: DiagramElements;
}

const getItemKey = (item: EditRow | EditRow[]): number =>
    Array.isArray(item) ? item[0].id : item.id;

/**
 * Table to render when it's out of the viewport. It doesn't need to have any
 * visual, just need to have correct sizes (since it will still be used to
 * calculate layout and draw edges)
 */
export const TableInvisible = (props: Props) => {
    const [rows] = groupTableRows(props.table, props.tableEdges);
    const getToCount = (row: EditRow): number =>
        props.tableEdges?.get(row.id)?.to.length ?? 0;
    return (
        <div
            className={["bg-[var(--slate2)]"].join(" ")}
            // The padding is to mimic the border of "visible" style
            style={{ width: TABLE_CLIENT_WIDTH + 2, padding: "0 1px" }}
        >
            {/* Padding manually measured to match "visible" style. This is the
            distance from TableVisibleRows to the top of TableVisible */}
            <div style={{ height: 53 }} />
            {rows.map((item) =>
                Array.isArray(item) ? (
                    <div key={item[0].id} style={{ height: ROW_HEIGHT }} />
                ) : (
                    <RowBlank
                        key={getItemKey(item)}
                        row={item}
                        edgesToCount={getToCount(item)}
                        elements={props.elements}
                        tableId={props.table.id}
                    />
                )
            )}
            {/* Padding manually measured to match "visible" style. This is the
            distance from TableVisibleRows to the bottom of TableVisible */}
            <div style={{ height: 13 }} />
        </div>
    );
};
