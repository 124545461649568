import { AppendDiagramPayload } from "diagram/state/reducer/diagram/diagram";
import { SelectAllTablesPayload } from "diagram/state/reducer/table/select/all";
import { DiagramProps } from "diagram/state/state";
import * as React from "react";

interface Props {
    diagramDispatch: DiagramProps["diagramDispatch"];
}

type Event = React.ClipboardEvent;

export const onCanvasPaste = (props: Props) => (event: Event) => {
    if (event.target instanceof HTMLInputElement) return;
    if (event.target instanceof HTMLAreaElement) return;
    event.preventDefault();

    const diagram = event.clipboardData?.getData("text/plain");
    if (!diagram) return; // Intentionally check for just falsy

    // Clear current selection
    (() => {
        const payload: SelectAllTablesPayload = { selected: false };
        props.diagramDispatch({ type: "select-all-tables", payload });
    })();

    // Append the new diagram
    (() => {
        const payload: AppendDiagramPayload = { diagram };
        props.diagramDispatch({ type: "append", payload });
    })();
};
