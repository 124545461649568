import { DiagramElements } from "canvas/elements";
import { EditEdge } from "diagram/model/edit/edit";
import { SelectRefPayload } from "diagram/state/reducer/ref/select";
import { DiagramProps } from "diagram/state/state";
import { PreferencesContext } from "preferences/preferences";
import * as React from "react";
import { EdgePaths } from "../path/path";
import "./edge.css";

interface Props {
    edge: EditEdge;
    paths: EdgePaths;
    elements: DiagramElements;
    isCanvasActive: boolean;
    diagramDispatch: DiagramProps["diagramDispatch"];
}

const makeRef =
    (elements: DiagramElements, id: EditEdge["id"]) =>
    (element: SVGGElement | null) => {
        if (element === null) {
            elements.edges.delete(id);
        } else {
            elements.edges.set(id, element);
        }
    };

const select = (props: Props) => {
    return (event: React.MouseEvent): void => {
        const append = event.metaKey || event.ctrlKey || event.shiftKey;
        const payload: SelectRefPayload = { id: props.edge.id, append };
        props.diagramDispatch({ type: "select-ref", payload });
    };
};

// Doesn't need to separate Edge into "active" and "inactive" components
// because they are mostly the same, so not much performance gain
export const Edge = (props: Props) => {
    // Avoid ref being called on every render
    // See: https://github.com/facebook/react/issues/6249
    const [{ elements }, { id }] = [props, props.edge];
    const ref = React.useMemo(() => makeRef(elements, id), [elements, id]);

    const { edgeAnimation } = React.useContext(PreferencesContext).preferences;

    const edgeColor =
        props.edge.selected && props.isCanvasActive
            ? "stroke-2 stroke-[var(--blue10)]"
            : "stroke-1 stroke-[var(--slate10)]";

    // The paths here are all named so they can be updated directly in user
    // drag handler. See canvas/drag for detail.
    return (
        <g ref={ref}>
            {props.paths.from.map((d, idx) => (
                <path
                    key={idx}
                    className={["path", "fill-none", edgeColor].join(" ")}
                    markerEnd="url(#edge-from)"
                    d={d}
                />
            ))}
            {props.paths.to.map((d, idx) => (
                <path
                    key={idx}
                    className={["path", "fill-none", edgeColor].join(" ")}
                    markerEnd="url(#edge-to)"
                    d={d}
                />
            ))}
            <path
                className={["path", "fill-none", edgeColor].join(" ")}
                d={props.paths.main}
            />
            {props.edge.selected && edgeAnimation && (
                <circle
                    className={[
                        props.edge.selected ? "fill-[var(--blue10)]" : "",
                        "dot",
                    ].join(" ")}
                    r="4"
                >
                    <animateMotion
                        // "0s" is not a valid value for "dur"
                        dur={`${Math.max(props.paths.mainLength / 100, 1)}s`}
                        repeatCount="indefinite"
                        path={props.paths.main}
                    />
                </circle>
            )}
            <path
                className={
                    "stroke-[20px] stroke-transparent fill-transparent cursor-pointer"
                }
                pointerEvents="stroke"
                d={props.paths.main}
                onClick={select(props)}
            />
        </g>
    );
};
