import { EdgesCanvas } from "canvas/edge/canvas/canvas";
import { getDiagramEdges } from "canvas/edges-map";
import { DiagramElements } from "canvas/elements";
import { TableInactive } from "canvas/table/inactive/inactive";
import { EditDiagram } from "diagram/model/edit/edit";
import { PreferencesContext } from "preferences/preferences";
import * as React from "react";
import { getCanvasStyle } from "../active/active";

interface Props {
    diagram: EditDiagram;
    elements: DiagramElements;
}

export const CanvasInactive = (props: Props) => {
    const prefs = React.useContext(PreferencesContext).preferences;
    const edgesMap = getDiagramEdges(props.diagram.refs);
    return (
        <div
            className={"relative"}
            style={getCanvasStyle(prefs.zoom, props.diagram.size)}
            ref={(element) => void (props.elements.canvas = element)}
        >
            {/* Separated backgrounds to ensure they are included in
            screenshot mode */}
            <div
                className={[
                    "absolute top-0 left-0 w-full h-full z-0",
                    "-outline-offset-1",
                    "shadow-[var(--slate1)]",
                    "bg-[var(--slate3)]",
                    "outline-8",
                ].join(" ")}
            />
            <div
                className={[
                    "absolute top-0 left-0 w-full h-full z-0",
                    "bg-radial-gradient bg-[length:16px_16px]",
                ].join(" ")}
            />
            <EdgesCanvas
                diagram={props.diagram}
                diagramDispatch={() => {}}
                elements={props.elements}
                isCanvasActive={false}
            />
            {props.diagram.tables.map((table) => (
                <TableInactive
                    key={table.id}
                    table={table}
                    tableEdges={edgesMap.get(table.id) ?? null}
                    elements={props.elements}
                    columns={props.diagram.columns}
                />
            ))}
        </div>
    );
};
