import { Icons } from "utilities/icon";
import { DiagramElements } from "canvas/elements";
import { parseDiagram } from "diagram/model/parse/parse";
import { AutoArrangePayload } from "diagram/state/reducer/arrange/auto";
import * as Replace from "diagram/state/reducer/diagram/diagram";
import { DiagramAction } from "diagram/state/reducer/reducer";
import * as React from "react";
import { getHeaderImportSampleItems } from "./sample";
import { getHeaderImportTextItems } from "./text";
import { getHeaderImportStorageItems } from "./storage";
import { DocStorage } from "library/storage/doc";
import { Backend } from "backend/backend";
import { LibraryProps } from "library/library";
import { LibraryReducer } from "library/reducer";
import { AuthContext } from "auth/auth";
import { ButtonMenu, MenuItemProps } from "app/button/menu";
import { Alert } from "app/alert/alert";
import { isAppHost } from "host/host";
import { Dialog } from "app/dialog/dialog";
import { Button } from "app/button/button";
import { dialogConfirm } from "app/dialog/confirm";
interface Props {
    setLibrary: LibraryProps["setLibrary"];
    elements: DiagramElements;
    diagramDispatch: React.Dispatch<DiagramAction>;
}

export const HeaderImport = (props: Props) => {
    const { isAuthenticated } = React.useContext(AuthContext);
    const [alertDescription, setAlertDescription] = React.useState<string>("");
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false);

    const [instructionsOpen, setInstructionsOpen] =
        React.useState<boolean>(false);

    const alert = (description: string) => {
        setAlertDescription(description);
        setAlertOpen(true);
    };

    const showInstruction = (message: string) => {
        setInstructionsOpen(true);
    };

    const askToImportFromStorage = async () => {
        // Are there diagrams in local storage?
        const diagramsInLocalStorage = await DocStorage.list();
        if (diagramsInLocalStorage.length > 0) {
            const yes = await dialogConfirm(
                [`Would you like to import diagrams onto the cloud?`].join("\n")
            );

            if (yes) {
                await importFromStorage();
            }
        } else {
            alert("There are no diagrams in local storage to import.");
        }
    };

    const importFromStorage = async () => {
        // Save all diagrams from the library
        const allItems = await DocStorage.list();
        for (let item of allItems) {
            await Backend.createDiagram(item.name, item.diagram);
        }

        // Wipe all imported diagrams
        await DocStorage.clear();

        // Then fetch them back again from DB, refreshing the current state
        const diagrams = await Backend.getDiagrams();

        if (diagrams.length > 0) {
            props.setLibrary(LibraryReducer.setAll(diagrams));
        }
    };

    const replaceThenArrange = (
        elements: DiagramElements,
        diagramDispatch: React.Dispatch<DiagramAction>,
        source: unknown
    ) => {
        let diagram = parseDiagram(source);
        // Need to dispatch the diagram so we have the DOM updated to auto
        // arrange after that
        (() => {
            const payload: Replace.ReplaceDiagramPayload = { diagram };
            diagramDispatch({ type: "replace", payload });
        })();
        // Now we can arrange as the new tables are rendered
        window.setTimeout(() => {
            const payload: AutoArrangePayload = { elements };
            diagramDispatch({ type: "auto-arrange", payload });
        }, 0);
    };

    const Instruction = () => (
        <p>
            <span>Please download the </span>
            <a
                className="underline font-semibold"
                href="https://tableplus.com"
                children="TablePlus"
            />
            <span> app and install the Diagram plugin </span>
            <span>(Cmd + L on Mac or Ctrl + L on Windows).</span>
        </p>
    );

    const showInstallHostAlert = (message: string) => {
        if (isAppHost) throw Error("Already inside an AppHost");
        showInstruction(message);
    };

    const getHeaderImportSqlItems = (): MenuItemProps[] => {
        if (isAppHost) return [];
        const fn = (): void => {
            showInstallHostAlert(
                "Generating diagram from databases require the TablePlus app"
            );
        };
        return [{ label: "Generate from database…", fn }];
    };

    const replace = replaceThenArrange.bind(
        null,
        props.elements,
        props.diagramDispatch
    );
    const items: MenuItemProps[] = [
        ...getHeaderImportTextItems(replace),
        ...getHeaderImportStorageItems(async () => {
            askToImportFromStorage();
        }, isAuthenticated),
        "divider",
        ...getHeaderImportSampleItems(replace),
    ];
    const sql = getHeaderImportSqlItems();
    if (sql.length > 0) items.push("divider", ...sql);
    return (
        <>
            <ButtonMenu
                button={{
                    icon: Icons.import,
                    children: "Import",
                    color: "solid",
                }}
                items={items}
            />
            <Alert
                key="ask-to-import"
                title=""
                description={alertDescription}
                cancel="Done"
                open={alertOpen}
                setOpen={setAlertOpen}
            />
            <Dialog.Root
                open={instructionsOpen}
                onOpenChange={setInstructionsOpen}
            >
                <Dialog.Content>
                    <>
                        <div
                            className={["px-2 flex", "justify-between"].join(
                                " "
                            )}
                        >
                            <div className="flex flex-col justify-center py-2 mx-3">
                                <Dialog.Title className="font-semibold text-17">
                                    Generating diagram from databases require
                                    the TablePlus app
                                </Dialog.Title>
                            </div>

                            <Dialog.Close>
                                <div>
                                    <Button color="clear" icon={Icons.close} />
                                </div>
                            </Dialog.Close>
                        </div>

                        <div className={["p-2 mx-3"].join(" ")}>
                            <Dialog.Description>
                                <Instruction />
                            </Dialog.Description>
                        </div>
                    </>
                </Dialog.Content>
            </Dialog.Root>
        </>
    );
};
