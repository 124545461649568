import { encodeText } from "utilities/encoding";
import { EditDiagram } from "../edit/edit";
import { makeBaseDiagramFromEdit as makeBase } from "./base";

export const stringifyDiagram = (
    diagram: EditDiagram,
    level: "base" | "edit",
    target: "json" | "base64"
): string => {
    const obj: object = level === "edit" ? diagram : makeBase(diagram);
    const json: string = JSON.stringify(obj);
    const text: string = target === "json" ? json : encodeText(json);
    return text;
};
