import { DiagramElements } from "canvas/elements";
import { arrangeDiagram } from "diagram/arrange/arrange";
import { DiagramReducer } from "../reducer";

export interface AutoArrangePayload {
    elements: DiagramElements;
}

type Reducer = DiagramReducer<AutoArrangePayload>;

export const autoArrangeDiagram: Reducer = (diagram, payload) => {
    return arrangeDiagram(payload.elements, diagram);
};
