import { MenuItemProps } from "app/button/menu";
import { diagramSamples } from "diagram/samples/samples";

export const getHeaderImportSampleItems = (
    replace: (source: unknown) => void
): MenuItemProps[] =>
    diagramSamples.map((sample) => ({
        label: `Load the ${sample.title} sample`,
        fn: () => replace(sample.source),
    }));
