import * as Radix from "@radix-ui/react-dropdown-menu";

interface ItemProps extends Radix.DropdownMenuItemProps {
    extraStyle?: string;
}

const Item = (props: ItemProps): JSX.Element => {
    const { extraStyle, ...native } = props;
    return (
        <Radix.Item
            className={[
                //
                "flex items-center gap-1",
                "outline-none",
                //
                "text-13 py-1 px-2 rounded",
                "data-highlighted:bg-blue-500",
                //
                "data-disabled:text-gray-500",
                "cursor-pointer",
                extraStyle ? extraStyle : "",
            ].join(" ")}
            {...native}
        />
    );
};

const Content = (props: Radix.DropdownMenuContentProps): JSX.Element => {
    return (
        <Radix.Portal>
            <Radix.Content
                className={[
                    //
                    "p-1 rounded-lg bg-gray-900 text-white shadow-xl",
                    "min-w-[100px]",
                ].join(" ")}
                {...props}
            />
        </Radix.Portal>
    );
};

export const DropdownMenu = {
    Root: Radix.Root,
    Trigger: Radix.Trigger,
    Content,
    Item,
    Sub: Radix.Sub,
    Portal: Radix.Portal,
    SubTrigger: Radix.SubTrigger,
    Arrow: Radix.Arrow,
    SubContent: Radix.SubContent,
    Separator: Radix.Separator,
};
