import * as Radix from "@radix-ui/react-select";
import { forwardRef } from "react";
import { Icon, Icons } from "utilities/icon";

type Ref = HTMLDivElement;

interface SelectItemProps extends Radix.SelectItemProps {
    hasCheck?: boolean;
}

export const SelectItem = forwardRef<Ref, SelectItemProps>((props, ref) => {
    const { hasCheck, children, ...item } = props;
    return (
        <Radix.Item
            {...item}
            ref={ref}
            className={[
                "flex items-center gap-1",
                "outline-none",
                //
                "text-13 py-1 px-2 rounded",
                "data-highlighted:bg-[var(--blue10)]",
                //
                "data-disabled:text-[var(--slate9)]",
                "react-draggable-cancel",
                "cursor-pointer",
                // Prevents Firefox select bug where it will select the elements
                // behind the select.
                // https://github.com/radix-ui/primitives/issues/1488
                "select-none",
            ].join(" ")}
        >
            {(hasCheck ?? true) && (
                <div className="w-4 h-4">
                    <Radix.ItemIndicator>
                        <Icon
                            icon={Icons.check}
                            size={16}
                            className="w-4 h-4"
                        />
                    </Radix.ItemIndicator>
                </div>
            )}
            <Radix.ItemText>{children}</Radix.ItemText>
        </Radix.Item>
    );
});
