import { Icons } from "utilities/icon";
import { Preferences, PreferencesContext } from "preferences/preferences";
import * as React from "react";
import { ButtonGroup } from "app/button/group";
import { Button } from "app/button/button";
import { Select } from "app/select/select";

const STEP = 0.25;

const zoomOptions = [...Array(8).keys()].map((_v, index) => {
    const value = (index + 1) * STEP;
    return { label: `${value * 100}%`, value, id: value.toString() };
});

const normalizeZoom = (zoom: number): number =>
    Math.min(
        Math.max(zoomOptions[0].value, zoom),
        zoomOptions[zoomOptions.length - 1].value
    );

export const setZoomInPref = (prev: Preferences): Preferences => ({
    ...prev,
    zoom: normalizeZoom(prev.zoom + STEP),
});

export const setZoomOutPref = (prev: Preferences): Preferences => ({
    ...prev,
    zoom: normalizeZoom(prev.zoom - STEP),
});

const setZoom =
    (value: number) =>
    (prev: Preferences): Preferences => ({
        ...prev,
        zoom: normalizeZoom(value),
    });

export const HeaderZoom = () => {
    const prefs = React.useContext(PreferencesContext);
    const { setPreferences: setPrefs } = prefs;
    const { zoom } = prefs.preferences;
    return (
        <div className={["rounded border border-[var(--slate7)]"].join(" ")}>
            <ButtonGroup key="zoom-group" skipChildTypeCheck={true}>
                {[
                    <Button
                        key="zoom-out"
                        icon={Icons.zoomOut}
                        onClick={() => setPrefs(setZoomOutPref)}
                        color="clear"
                    />,
                    <div
                        key="zoom-select"
                        className="w-20 rounded p-1 hover:shadow hover:bg-[var(--slate4)] text-[var(--slate12)]"
                    >
                        <Select.Root
                            value={zoom.toString()}
                            onValueChange={(value) => {
                                setPrefs(setZoom(parseFloat(value)));
                            }}
                            hasArrow={true}
                            valueElement={
                                <Select.Value asChild>
                                    <div
                                        className={[
                                            "flex flex-1 justify-center items-center text-[var(--slate12)]",
                                        ].join(" ")}
                                    >
                                        {zoom * 100}%
                                    </div>
                                </Select.Value>
                            }
                            triggerBgColor="white"
                        >
                            {zoomOptions.map((zoomOption) => (
                                <Select.Item
                                    key={zoomOption.value}
                                    value={zoomOption.value.toString()}
                                >
                                    {zoomOption.label}
                                </Select.Item>
                            ))}
                        </Select.Root>
                    </div>,

                    <Button
                        key="zoom-out"
                        icon={Icons.zoomIn}
                        onClick={() => setPrefs(setZoomInPref)}
                        color="clear"
                    />,
                ]}
            </ButtonGroup>
        </div>
    );
};
