import { TABLE_MARGIN_SINGLE, TABLE_MARGIN_WIDTH } from "canvas/table/geometry";
import { EditDiagram } from "diagram/model/edit/edit";
import { Position } from "geometry/geometry";

/**
 * Returns a position where it's ok to put new Table(s) (i.e. without
 * overlapping the existing ones) and the Diagram's new size (since it may
 * need to increase if necessary)
 */
export const getNewTablePosition = (diagram: EditDiagram): Position => {
    // @TODO: At the moment we always put the new Table on the right side. This
    // should be improved to use existing empty spaces
    const maxLeft = diagram.tables.reduce((prev, table) => {
        return Math.max(prev, table.position.left);
    }, 0);
    return { left: maxLeft + TABLE_MARGIN_WIDTH, top: TABLE_MARGIN_SINGLE };
};
