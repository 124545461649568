import * as Radix from "@radix-ui/react-select";
import { buttonFocusStyles } from "app/button/button";
import { forwardRef, ReactNode } from "react";
import { Icon, Icons } from "utilities/icon";

interface Props extends Radix.SelectProps {
    id?: string;
    children: ReactNode;
    valueElement?: ReactNode;
    fill?: boolean;
    triggerBgColor?: string;
    hasArrow?: boolean;
    triggerPadding?: string;
}

export type { Props as SelectRootProps };

type Ref = HTMLButtonElement;

export const SelectRoot = forwardRef<Ref, Props>((props, ref) => {
    const {
        id,
        children,
        valueElement,
        fill,
        triggerBgColor,
        hasArrow,
        triggerPadding,
        ...root
    } = props;
    return (
        <Radix.Root {...root}>
            <Radix.Trigger
                id={id}
                ref={ref}
                className={[
                    buttonFocusStyles,
                    "block text-13 rounded",
                    fill ? "w-full" : "w-fit",
                    //
                    "flex flex-1 items-center justify-between gap-2",
                    triggerBgColor ?? "bg-[var(--slate2)]",
                    triggerPadding ?? "py-1 px-2",
                ].join(" ")}
            >
                {valueElement ?? <Radix.Value />}
                {hasArrow && (
                    <Radix.Icon asChild>
                        <div>
                            <Icon
                                icon={Icons.chevronDown}
                                size={16}
                                className="w-4 h-4 fill-[var(--slate12)]"
                            />
                        </div>
                    </Radix.Icon>
                )}
            </Radix.Trigger>
            <Radix.Portal>
                <Radix.Content
                    className={[
                        //
                        "px-1 rounded-lg z-[5]",
                        "bg-gray-900 text-white",
                        "shadow-xl",
                    ].join(" ")}
                >
                    <Radix.ScrollUpButton>
                        <div className="flex justify-center">
                            <Icon
                                icon={Icons.chevronUp}
                                size={16}
                                className="w-4 h-4"
                            />
                        </div>
                    </Radix.ScrollUpButton>
                    <Radix.Viewport className="py-1">{children}</Radix.Viewport>
                    <Radix.ScrollDownButton>
                        <div className="flex justify-center">
                            <Icon
                                icon={Icons.chevronDown}
                                size={16}
                                className="w-4 h-4"
                            />
                        </div>
                    </Radix.ScrollDownButton>
                </Radix.Content>
            </Radix.Portal>
        </Radix.Root>
    );
});
