const CURRENT_KEY = "dg/current";

const get = async (): Promise<string | null> => {
    return window.localStorage.getItem(CURRENT_KEY);
};

const set = async (value: string | null): Promise<void> => {
    if (value === null) {
        window.localStorage.removeItem(CURRENT_KEY);
    } else {
        window.localStorage.setItem(CURRENT_KEY, value);
    }
};

export const CurrentStorage = { get, set };
