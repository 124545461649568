import { BaseDiagramUnknown } from "./base";

export interface BaseColumnV1 {
    id: string;
    name: string;
    type: string;
}

export interface BaseRowV1 {
    id: string;
    [key: string]: string | undefined;
}

export interface BaseItemV1 {
    id: string;
    name: string;
    schema?: string;
    rows: BaseRowV1[];
}

export interface BaseRefSideV1 {
    name: string;
    schema?: string;
    rows: string[];
}

export interface BaseRefV1 {
    from: BaseRefSideV1;
    to: BaseRefSideV1;
}

export interface BaseDiagramV1 {
    columns: BaseColumnV1[];
    items: BaseItemV1[];
    refs: BaseRefV1[];
    schema: string;
    $schema?: string;
}

export const isBaseDiagramV1 = (
    diagram: BaseDiagramUnknown
): diagram is BaseDiagramV1 => {
    return "version" in diagram === false;
};
