export const EDGE_FROM_MARKER_ID = "edge-from";
export const EDGE_TO_MARKER_ID = "edge-to";

const From = () => (
    <marker
        id={EDGE_FROM_MARKER_ID}
        viewBox="0 0 10 10"
        refX="4"
        refY="5"
        markerWidth="10"
        markerHeight="10"
        markerUnits="userSpaceOnUse"
    >
        <circle
            className={[
                "container stroke-1 fill-[var(--slate2)]",
                "transition-[stroke-width,stroke] duration-200",
                "stroke-[var(--slate9)]",
            ].join(" ")}
            cx="5"
            cy="5"
            r="4"
        />
    </marker>
);

const To = () => (
    <marker
        id={EDGE_TO_MARKER_ID}
        viewBox="0 0 10 10"
        refX="10"
        refY="5"
        markerWidth="10"
        markerHeight="10"
        markerUnits="userSpaceOnUse"
        orient="auto-start-reverse"
    >
        <polygon
            className={[
                "container stroke-1 fill-[var(--slate2)]",
                "transition-[stroke-width,stroke] duration-200",
                "stroke-[var(--slate9)]",
            ].join(" ")}
            points="1,1 9,5 1,9"
        />
    </marker>
);

export const EdgeCanvasMarkers = () => (
    <defs>
        <From />
        <To />
    </defs>
);
