import * as Radix from "@radix-ui/react-dialog";

import { forwardRef } from "react";

interface ContentProps extends Radix.DialogContentProps {
    onClickOverlay?: () => void;
    extraStyles?: string;
}

const Content = forwardRef<HTMLDivElement, ContentProps>(
    ({ children, ...props }, forwardedRef) => (
        <Radix.Portal>
            <div
                className={[
                    //
                    "fixed inset-0 isolate z-[5]",
                    "flex items-center justify-center",
                ].join(" ")}
            >
                <Radix.Overlay
                    className={[
                        //
                        "z-[5] absolute inset-0",
                        "bg-gray-900/25",
                    ].join(" ")}
                    onClick={props.onClickOverlay}
                />

                <Radix.Content
                    {...props}
                    ref={forwardedRef}
                    className={[
                        //
                        "z-10",
                        "bg-[var(--slate4)] shadow-24 rounded-lg overflow-hidden",
                        "text-[var(--slate12)]",
                        props.extraStyles,
                    ].join(" ")}
                >
                    {children}
                </Radix.Content>
            </div>
        </Radix.Portal>
    )
);

const Title = (props: Radix.DialogTitleProps) => {
    return (
        <Radix.Title
            {...props}
            className={[
                //
                "text-2xl font-semibold",
            ].join(" ")}
        />
    );
};

export const Dialog = {
    Root: Radix.Root,
    Content,
    Close: Radix.Close,
    Trigger: Radix.Trigger,
    Title,
    Description: Radix.Description,
};
