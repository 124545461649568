import { dialogPrompt } from "app/dialog/prompt";

const defaultFallbackMessage: React.ReactNode = [
    "Please copy from the textbox below:",
    "Your system doesn't allow us to write to your clipboard automatically",
];

/**
 * Return "true" if text is written to clipboard, "false" if the fallback
 * dialog is shown
 */
export const copyToClipboard = async (
    text: string,
    fallbackMessage?: React.ReactNode
): Promise<boolean> => {
    try {
        await navigator.clipboard.writeText(text);
        return true;
    } catch (error) {
        const message = fallbackMessage ?? defaultFallbackMessage;
        const rows = 10;
        await dialogPrompt(rows, message, text);
        return false;
    }
};
