import { EdgesCanvas } from "canvas/edge/canvas/canvas";
import { EdgeDragIndicator } from "canvas/edge/drag/indicator";
import { getDiagramEdges } from "canvas/edges-map";
import { DiagramElements } from "canvas/elements";
import { TableActive } from "canvas/table/active/active";
import { TableMenuState } from "canvas/table/active/visible/menu/menu";
import { DiagramProps } from "diagram/state/state";
import { Size } from "geometry/geometry";
import { host } from "host/host";
import { PreferencesContext } from "preferences/preferences";
import * as React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { makeCanvasPreviewDrag } from "./drag";
import { CanvasSelect } from "./select/select";

interface Props extends DiagramProps {
    elements: DiagramElements;
    setTableMenuPosition: TableMenuState["setTableMenuPosition"];
}

// @TODO: Why can't type this with React.CSSProperties?
export const getCanvasStyle = (zoom: number, { width, height }: Size) => {
    const halfMargin = (zoom - 1) / 2;
    return {
        width: width,
        height: height,
        margin: `${height * halfMargin}px ${width * halfMargin}px`,
        transform: zoom !== 1 ? `scale(${zoom})` : "",
        fontSize: host === "mac" ? 12 : undefined,
    };
};

export const CanvasActive = (props: Props) => {
    const { diagram, elements } = props;
    const { tables, refs: edges } = diagram;

    const prefs = React.useContext(PreferencesContext).preferences;

    const previewDrag = React.useMemo(() => {
        const ids = tables.filter((t) => t.selected).map((t) => t.id);
        return makeCanvasPreviewDrag({ tables: ids, edges, elements });
    }, [tables, edges, elements]);

    const edgesMap = React.useMemo(() => getDiagramEdges(edges), [edges]);

    const body = (
        <div
            className={"relative"}
            style={getCanvasStyle(prefs.zoom, diagram.size)}
            ref={(element) => void (props.elements.canvas = element)}
        >
            <div
                className={[
                    "absolute top-0 left-0 w-full h-full z-0",
                    "bg-[var(--slate3)]",
                ].join(" ")}
            />
            <div
                className={[
                    "absolute top-0 left-0 w-full h-full z-0",
                    "bg-radial-gradient bg-[length:16px_16px]",
                ].join(" ")}
            />
            <CanvasSelect
                elements={elements}
                edges={edges}
                diagramDispatch={props.diagramDispatch}
            />
            <EdgesCanvas
                diagram={diagram}
                diagramDispatch={props.diagramDispatch}
                elements={elements}
                isCanvasActive={true}
            />
            {props.diagram.tables.map((table) => (
                <TableActive
                    // Self
                    key={table.id}
                    table={table}
                    tableEdges={edgesMap.get(table.id) ?? null}
                    // Common
                    setTableMenuPosition={props.setTableMenuPosition}
                    elements={elements}
                    columns={diagram.columns}
                    diagramDispatch={props.diagramDispatch}
                    previewDrag={previewDrag}
                />
            ))}
            <EdgeDragIndicator elements={elements} />
        </div>
    );

    return <DndProvider backend={HTML5Backend}>{body}</DndProvider>;
};
