/*
Encoding and decoding here means between:
- a unicode string (e.g. "tiếng việt")
- its bytes representation in base64 (e.g. "dGnhur9uZyB2aeG7h3Q=")
*/

const encoder = new TextEncoder();

/** Unicode version of btoa */
export const encodeText = (unicode: string): string => {
    const bytes: Uint8Array = encoder.encode(unicode);
    // Can't use Uint8Array.map here because its return type is Uint8Array
    const chars: string[] = new Array(bytes.length);
    bytes.forEach((byte) => void chars.push(String.fromCharCode(byte)));
    return btoa(chars.join(""));
};

const decoder = new TextDecoder();

const toCharCode = (char: string): number => char.charCodeAt(0);

/** Unicode version of atob */
export const decodeText = (base64: string): string => {
    const bytes = Uint8Array.from(atob(base64), toCharCode);
    return decoder.decode(bytes);
};
