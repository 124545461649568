import { EditTable } from "diagram/model/edit/edit";
import { DiagramReducer } from "../../reducer";

export interface ExpandAllTablesRowsPayload {
    expanded: boolean;
}

type Reducer = DiagramReducer<ExpandAllTablesRowsPayload>;

export const expandAllTablesRows: Reducer = (diagram, payload) => {
    const tables: EditTable[] = diagram.tables.map((table) => {
        return { ...table, rowsExpanded: payload.expanded };
    });
    return { ...diagram, tables };
};
