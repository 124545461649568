import { DiagramReducer } from "../reducer";

type Reducer = DiagramReducer<undefined>;

export const removeSelectedItems: Reducer = (diagram) => {
    // Remove tables
    const selectedArr = diagram.tables
        .filter((table) => table.selected)
        .map((table) => table.id);
    const selected: Set<number> = new Set(selectedArr);
    const tables = diagram.tables.filter((table) => !table.selected);
    // Remove refs
    const refs = diagram.refs.filter((ref) => {
        const [from, to] = [ref.from.table, ref.to.table];
        if (selected.has(from) && selected.has(to)) return false;
        if (ref.selected) return false;
        return true;
    });
    return { ...diagram, tables, refs };
};
