export const EdgeCreateHeader = () => (
    <div
        className={[
            "py-2 px-0 text-center",
            "bg-[var(--slate1)] text-[var(--slate12)]",
        ].join(" ")}
    >
        <span className="font-bold">Create reference</span>
    </div>
);
