import { DropdownMenuArrow } from "@radix-ui/react-dropdown-menu";
import { DropdownMenu } from "app/dropdown/dropdown";
import { Icon, Icons } from "utilities/icon";
import { Button, ButtonProps } from "./button";

export interface MenuItemAction {
    label: string;
    fn?: () => void;
    disabled?: boolean;
}

export interface MenuSubItemAction {
    label: string;
    disabled?: boolean;
    items: MenuItemAction[];
}

export type MenuItemProps = MenuItemAction | MenuSubItemAction | "divider";

function isMenuSubItem(object: any): object is MenuSubItemAction {
    return "items" in object;
}

interface Props {
    items: MenuItemProps[];
    button: Omit<ButtonProps, "onClick">;
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
}

export const ButtonMenu = (props: Props) => {
    const { items, button, open, onOpenChange } = props;
    return (
        <DropdownMenu.Root open={open} onOpenChange={onOpenChange}>
            <DropdownMenu.Trigger asChild>
                <div>
                    <Button
                        extraStyle={button.extraStyle}
                        color={button.color}
                        children={button.children}
                        icon={button.icon}
                        selected={button.selected}
                    />
                </div>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content>
                {items.map((item, index) => {
                    if (item === "divider") {
                        return (
                            <DropdownMenu.Separator
                                className={["h-[1px] m-[5px] bg-white"].join(
                                    " "
                                )}
                                key={index}
                            />
                        );
                    } else {
                        if (isMenuSubItem(item)) {
                            return (
                                <DropdownMenu.Sub key={`submenu-${item.label}`}>
                                    <DropdownMenu.SubTrigger
                                        className={[
                                            //
                                            "flex items-center gap-1",
                                            "outline-none",
                                            //
                                            "text-13 py-1 px-2 rounded",
                                            "data-highlighted:bg-blue-500",
                                            //
                                            "data-disabled:text-gray-500",
                                            "cursor-pointer",
                                        ].join(" ")}
                                    >
                                        {item.label}
                                        <div className="ml-auto pl-[20px]">
                                            <Icon icon={Icons.chevronRight} />
                                        </div>
                                    </DropdownMenu.SubTrigger>
                                    <DropdownMenu.Portal>
                                        <DropdownMenu.SubContent
                                            className={[
                                                "p-1 rounded-lg bg-gray-900 text-white shadow-xl",
                                                "min-w-[100px]",
                                            ].join(" ")}
                                        >
                                            {item.items.map(
                                                (subItem, index) => {
                                                    return (
                                                        <DropdownMenu.Item
                                                            key={index}
                                                            onClick={subItem.fn}
                                                        >
                                                            {subItem.label}
                                                        </DropdownMenu.Item>
                                                    );
                                                }
                                            )}
                                        </DropdownMenu.SubContent>
                                    </DropdownMenu.Portal>
                                </DropdownMenu.Sub>
                            );
                        } else {
                            return (
                                <DropdownMenu.Item
                                    key={index}
                                    onClick={() => {
                                        if (item.disabled) {
                                            return;
                                        }
                                        item.fn?.();
                                    }}
                                    disabled={item.disabled}
                                >
                                    {item.label}
                                </DropdownMenu.Item>
                            );
                        }
                    }
                })}
                <DropdownMenuArrow />
            </DropdownMenu.Content>
        </DropdownMenu.Root>
    );
};
