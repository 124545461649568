import { HeaderFooter } from "app/header/footer/footer";
import { HeaderMore } from "app/header/more/more";
import { HeaderReport } from "app/header/report/report";
import { headerHostStyle } from "app/header/styles/host";
import { HeaderTheme } from "app/header/theme/theme";
import { HeaderZoom } from "app/header/zoom/zoom";
import { Border } from "border/border";
import { isAppHost } from "host/host";
import { LibraryItem } from "library/library";
import * as React from "react";
import { SharedHeaderReturn } from "./return/return";

interface Props {
    item: LibraryItem;
}
const ViewControls = (props: Props) => {
    return (
        <>
            <div
                className={[
                    "px-2 h-[64px] flex items-center",
                    headerHostStyle.box,
                ].join(" ")}
            >
                <HeaderZoom />
            </div>
        </>
    );
};

export const SharedHeader = (props: Props) => {
    const [more, setMore] = React.useState(false);
    const { item } = props;
    const { name } = item;

    return (
        <div
            className={[headerHostStyle.container, "bg-[var(--slate2)]"].join(
                " "
            )}
        >
            <div className={["flex items-center px-2"].join(" ")}>
                <div className={"px-2 h-[64px] flex items-center"}>
                    <SharedHeaderReturn />
                </div>
                <div
                    className={[
                        "px-2 h-[64px] flex items-center",
                        "flex-[1_1_auto] justify-center",
                        "text-[var(--slate12)]",
                        headerHostStyle.box,
                    ].join(" ")}
                >
                    <p className="leading-normal">
                        Viewing shared diagram
                        <span className="font-bold"> {name} </span>
                    </p>
                </div>
                <ViewControls {...props} />
                <div
                    className={[
                        "px-2 h-[64px] flex items-center",
                        headerHostStyle.box,
                    ].join(" ")}
                >
                    <HeaderMore more={more} setMore={setMore} />
                </div>
            </div>
            {more && (
                <>
                    <Border color="weak" />
                    <div
                        className={[
                            headerHostStyle.more,
                            "flex items-center px-2",
                        ].join(" ")}
                    >
                        {isAppHost === false && (
                            <div
                                className={[
                                    "px-2 h-[64px] flex items-center",
                                    headerHostStyle.box,
                                ].join(" ")}
                                children={<HeaderTheme />}
                            />
                        )}
                        <div
                            className={[
                                "px-2 h-[64px] flex items-center",
                                headerHostStyle.box,
                            ].join(" ")}
                            children={<HeaderFooter />}
                        />
                        <div className="block flex-[1_0_0px]" />
                        <div
                            className={[
                                "px-2 h-[64px] flex items-center",
                                headerHostStyle.box,
                            ].join(" ")}
                            children={<HeaderReport />}
                        />
                    </div>
                </>
            )}
        </div>
    );
};
