import { host } from "../host";

export const postMessageToHost = (method: string, body: unknown): void => {
    switch (host) {
        case "mac":
            const handlers = (window as any).webkit.messageHandlers;
            handlers[method].postMessage(body);
            return;
        case "win":
            const message = { message: method, args: body };
            (window as any).CefSharp.PostMessage(message);
            return;
        case "web":
            throw Error('Cannot post message to "web" host');
    }
};
