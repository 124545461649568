import { CanvasInactive } from "canvas/canvas/inactive/inactive";
import { DiagramElementsUtils } from "canvas/elements";
import { EditDiagram } from "diagram/model/edit/edit";
// html2canvas does not supported SVG explicitly so we still need
// to use this outdated library
// Ref: https://github.com/niklasvh/html2canvas/issues/2329
import domtoimage from "dom-to-image";
import { FileFormat, saveFileToHost } from "host/message/save";
// this html2canvas is a manual fork to fix a safari issue
// Ref: https://github.com/TablePlus/diagram/issues/31
// @ts-ignore
import html2canvas from "./html2canvas";
import { MenuItemProps } from "app/button/menu";
import { createRoot } from "react-dom/client";

interface Option {
    format: FileFormat;
    scale: number;
}

const getImage = async (
    canvas: HTMLElement,
    option: Option
): Promise<string> => {
    const { format, scale } = option;
    switch (format) {
        case "png":
            const result = await html2canvas(canvas, { scale });
            return result.toDataURL();
        case "svg":
            return await domtoimage.toSvg(canvas);
        default:
            throw Error(`Unknown format "${format}"`);
    }
};

const saveImage = async (
    diagram: EditDiagram,
    option: Option
): Promise<void> => {
    // Render
    const container = document.createElement("div");
    container.style.position = "fixed";
    container.style.top = "-100000";
    document.body.appendChild(container);
    const elements = DiagramElementsUtils.newDiagram();
    const canvas = <CanvasInactive {...{ elements, diagram }} />;

    const root = createRoot(container);
    root.render(canvas);
    await new Promise((r) => setTimeout(r, 100)); // Wait for effects to run

    // Get image
    const target = container.firstElementChild;
    if (!(target instanceof HTMLElement)) throw Error("Canvas is not rendered");
    let content = await getImage(target, option);

    // Remove the mimetype prefix (e.g. "data:png;base64,")
    content = content.slice(content.indexOf(",") + 1);
    saveFileToHost({ content, format: option.format, name: "diagram" });

    // Clean up
    root.unmount();
    container.remove();
};

export const getHeaderExportImageItems = (
    diagram: EditDiagram
): MenuItemProps[] => {
    const images: [string, FileFormat, 1 | 2][] = [
        ["Save as PNG (High quality)", "png", 2],
        ["Save as PNG (Low quality)", "png", 1],
        ["Save as SVG (Web page)", "svg", 1],
    ];
    return images.map(([label, format, scale]) => {
        const fn = () => saveImage(diagram, { format, scale });
        return { label, fn };
    });
};
