import { Separator } from "@radix-ui/react-separator";
import { Alert } from "app/alert/alert";
import { __DiagramContext } from "diagram/context/context";
import { EditDiagram, EditEdge, EditEdgeSide } from "diagram/model/edit/edit";
import { AddRefPayload } from "diagram/state/reducer/ref/add";
import { DiagramProps } from "diagram/state/state";
import * as React from "react";
import { createPortal } from "react-dom";
import { CreateEdgeSide, CreateEdgeSideUtils } from "./create";
import { EdgeCreateFooter } from "./footer/footer";
import { EdgeCreateHeader } from "./header/header";
import { EdgeCreateSide } from "./side/side";

interface Props {
    defaultFrom: EditEdgeSide;
    edge: EditEdge | null;
    diagramDispatch: DiagramProps["diagramDispatch"];
    onDone?: () => void;
}

export const EDGE_CREATE_FORM_ID = "edge-create-form";

export const EdgeCreateForm = (props: Props) => {
    const { diagramDispatch } = props;

    const diagram = React.useContext(__DiagramContext);

    const [from, setFrom] = React.useState<CreateEdgeSide>(() => {
        const side = props.edge?.from ?? props.defaultFrom;
        return CreateEdgeSideUtils.makeCreate(diagram, side);
    });

    const [to, setTo] = React.useState<CreateEdgeSide>(() => {
        return props.edge
            ? CreateEdgeSideUtils.makeCreate(diagram, props.edge.to)
            : CreateEdgeSideUtils.makeEmpty();
    });

    const [alertDescription, setAlertDescription] = React.useState<string>("");
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false);

    const alert = (description: string) => {
        setAlertDescription(description);
        setAlertOpen(true);
    };

    const remove = (props: Props) => {
        if (props.edge === null) return null;
        const id = props.edge.id;
        return () => {
            props.diagramDispatch({ type: "remove-ref", payload: { id } });
        };
    };

    const submit = (props: {
        diagram: EditDiagram;
        diagramDispatch: DiagramProps["diagramDispatch"];
        from: CreateEdgeSide;
        to: CreateEdgeSide;
    }): void => {
        try {
            const payload: AddRefPayload = {
                from: CreateEdgeSideUtils.makeEdit(props.diagram, props.from),
                to: CreateEdgeSideUtils.makeEdit(props.diagram, props.to),
            };
            props.diagramDispatch({ type: "add-ref", payload });
        } catch (error: unknown) {
            const message = error instanceof Error ? error.message : "Unknown";
            alert(["Cannot create ref", message].join("\n"));
        }
    };

    const form = (
        <form
            id={EDGE_CREATE_FORM_ID}
            onSubmit={(event) => {
                event.preventDefault();
                submit({ diagram, diagramDispatch, from, to });
                props.onDone?.();
            }}
        >
            <EdgeCreateHeader />
            <Separator />
            <EdgeCreateSide
                label="From"
                side={from}
                setSide={setFrom}
                autoFocus={false}
                diagram={diagram}
            />
            <Separator />
            <EdgeCreateSide
                label="To"
                side={to}
                setSide={setTo}
                autoFocus={true}
                diagram={diagram}
            />
            <Separator />
            <EdgeCreateFooter remove={remove(props)} />
        </form>
    );

    // To avoid de-selection when clicking outside of the Popover. This blocks
    // the CanvasSelect element
    const backdrop = createPortal(
        <div
            className={["fixed top-0 left-0 w-full h-full z-[1]"].join(" ")}
        />,
        document.querySelector("#portals")!
    );

    const alertDialog = (
        <Alert
            title="Error"
            description={alertDescription}
            cancel="Done"
            open={alertOpen}
            setOpen={setAlertOpen}
        />
    );

    return (
        <>
            {form}
            {backdrop}
            {alertDialog}
        </>
    );
};
