import { Icons } from "utilities/icon";
import { Button } from "app/button/button";

export const HeaderReport = () => {
    return (
        <>
            <Button
                onClick={() => {
                    window.location.href = "mailto:nick@tableplus.com";
                }}
                icon={Icons.issue}
                children="Report issue"
                color={"solid"}
            />
        </>
    );
};
