import { CanvasActive } from "canvas/canvas/active/active";
import { DiagramElements } from "canvas/elements";
import { TableMenuCanvas } from "canvas/table/active/visible/menu/canvas/canvas";
import { DiagramHistoryProps, DiagramProps } from "diagram/state/state";
import { Position } from "geometry/point";
import { PreferencesContext } from "preferences/preferences";
import * as React from "react";
import { useDragToScroll } from "./scroll/scroll";
import { onCanvasCopy } from "./shortcut/copy";
import { onCanvasKeyDown } from "./shortcut/key";
import { onCanvasPaste } from "./shortcut/paste";

interface Props {
    diagram: DiagramProps["diagram"];
    diagramHistory: DiagramHistoryProps["diagramHistory"];
    diagramDispatch: DiagramHistoryProps["diagramHistoryDispatch"];
    elements: DiagramElements;
}

export const AppCanvas = (props: Props) => {
    const { elements, diagram, diagramDispatch } = props;
    const { setPreferences } = React.useContext(PreferencesContext);

    const tableMenuPositionState = React.useState<Position | null>(null);
    const [tableMenuPosition, setTableMenuPosition] = tableMenuPositionState;

    useDragToScroll(elements);

    return (
        <div
            className={["w-full h-full overflow-scroll outline-none"].join(" ")}
            tabIndex={0}
            ref={(element) => void (elements.viewport = element)}
            // Keyboard shortcuts
            onCopy={onCanvasCopy(props)}
            onPaste={onCanvasPaste(props)}
            onKeyDown={onCanvasKeyDown({ ...props, setPreferences })}
        >
            <TableMenuCanvas
                {...{ elements, diagram, diagramDispatch }}
                {...{ tableMenuPosition, setTableMenuPosition }}
            />
            <CanvasActive
                {...{ elements, diagram, diagramDispatch }}
                setTableMenuPosition={setTableMenuPosition}
            />
        </div>
    );
};
