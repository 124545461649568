import { FooterLink } from "../link/link";

export const FooterLinks = () => (
    <div
        className={["flex whitespace-nowrap items-center text-gray-400"].join(
            " "
        )}
    >
        <p>
            <FooterLink
                href="https://github.com/TablePlus/diagram-plugin"
                children="Issue tracker"
            />
            <span> </span>
            <FooterLink href="mailto:nick@tableplus.com" children="Email us" />
            <span> </span>
            <span className="font-bold">Apps: </span>
            <FooterLink
                href="https://tableplus.com/sql"
                children="SQL"
                title="SQL Formatter"
            />
            <span> </span>
            <FooterLink
                href="https://tableplus.com/xml"
                children="XML"
                title="XML Viewer & Formatter"
            />
            <span> </span>
            <FooterLink
                href="https://tableplus.com/json"
                children="JSON"
                title="JSON Viewer & Formatter"
            />
        </p>
    </div>
);
