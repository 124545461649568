import { EditTable } from "diagram/model/edit/edit";
import { Position } from "geometry/geometry";
import { DiagramReducer } from "../reducer";

export interface MoveSelectedTablesPayload extends Position {}

type Reducer = DiagramReducer<MoveSelectedTablesPayload>;

const move = (
    position: Position,
    payload: MoveSelectedTablesPayload
): Position => ({
    top: position.top + payload.top,
    left: position.left + payload.left,
});

export const moveSelectedTables: Reducer = (diagram, payload) => {
    const tables: EditTable[] = diagram.tables.map((table) => {
        if (table.selected === false) return table;
        const position = move(table.position, payload);
        return { ...table, position };
    });
    return { ...diagram, tables };
};
