import axios from "axios";
import { instance } from "backend/backend";
import { Config } from "config/config";
import { createContext, useEffect, useState } from "react";

interface State {
    userDisplayName: string;
    isAuthenticated: boolean;
    //
    isLoadingAuth: boolean;
    //
    loginWithGithub: () => void;
    loginWithGoogle: () => void;
    logout: () => Promise<void>;
}

const authInstance = axios.create({
    baseURL: Config.BASE_URL,
    withCredentials: true,
});

export type { State as AuthState };

export const AuthContext = createContext<State>({
    userDisplayName: "",
    isAuthenticated: false,
    isLoadingAuth: true,
    loginWithGithub: () => {
        throw new Error("loginWithGithub not implemented");
    },
    loginWithGoogle: () => {
        throw new Error("loginWithGoogle not implemented");
    },
    logout: async () => {
        throw new Error("logout not implemented");
    },
});

export const useAuth = (): State => {
    const [userDisplayName, setUserDisplayName] = useState<string>("");
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [isLoadingAuth, setIsLoadingAuth] = useState<boolean>(true);

    const loginWithGithub = async () => {
        let url = Config.BASE_URL + "/login/github";
        window.location.href = url;
    };

    const loginWithGoogle = () => {
        let url = Config.BASE_URL + "/login/google";
        window.location.href = url;
    };

    const logout = async () => {
        let endpoint = "/logout";

        await instance
            .get(endpoint, {
                withCredentials: true,
            })
            .then(async (response) => {
                if (response.status === 200) {
                    setIsAuthenticated(false);
                    window.location.reload();
                }
            });
    };

    const checkAuthenticated = async () => {
        const endpoint = "/auth/check";

        setIsLoadingAuth(true);
        await authInstance
            .get(endpoint, {
                withCredentials: true,
            })
            .then((response) => {
                if (response.status === 200) {
                    const responseCode = response.data["Code"];
                    const authData = response.data["Data"];
                    if (responseCode === 200) {
                        setUserDisplayName(authData["display_name"]);
                    }
                    setIsAuthenticated(authData["authenticated"]);
                    setIsLoadingAuth(false);
                }
            })
            .catch(async (error) => {
                // On any error, we assume that the user is not authenticated
                setIsAuthenticated(false);
                setIsLoadingAuth(false);
                return;
            });
    };

    useEffect(() => {
        const checkAuth = async () => {
            checkAuthenticated();
        };
        checkAuth();
    }, []);

    return {
        userDisplayName,
        isAuthenticated,
        //
        isLoadingAuth,
        //
        loginWithGithub,
        loginWithGoogle,
        logout,
    };
};
