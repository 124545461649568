import { Icons } from "utilities/icon";
import { DiagramProps } from "diagram/state/state";
import * as React from "react";
import { SetState } from "utilities/set-state";
import { EditRow } from "diagram/model/edit/edit";
import { Button } from "app/button/button";
import { UpdateRowPayload } from "diagram/state/reducer/row/update";

interface Props {
    tableId: number;
    row: EditRow;
    rowIsPrimary: boolean;
    setRowIsPrimary: SetState<boolean>;
    diagramDispatch: DiagramProps["diagramDispatch"];
}

const updateRowPrimary = (props: Props) => (value: boolean) => {
    const { tableId, row } = props;
    const [rowId] = [row.id];
    const property = "isPrimary";
    const newValue = value ? "true" : "false";
    const payload: UpdateRowPayload = {
        tableId,
        rowId,
        property,
        value: newValue,
    };
    props.diagramDispatch({ type: "update-row", payload });
};

export const RowPrimaryKey = (props: Props) => {
    const { row, rowIsPrimary, setRowIsPrimary } = props;

    const elementRef = React.useRef<HTMLDivElement>(null);

    return (
        <div
            className={["block text-[var(--slate12)]"].join(" ")}
            ref={elementRef}
        >
            <Button
                onClick={() => {
                    setRowIsPrimary(!rowIsPrimary);
                    row.isPrimary = !rowIsPrimary;
                    updateRowPrimary(props)(row.isPrimary);
                }}
                color="clear"
                icon={rowIsPrimary ? Icons.selection : Icons.circle}
                iconSize={12}
                extraStyle="m-1"
            />
        </div>
    );
};
