import { Icons } from "utilities/icon";
import * as React from "react";
import { Button } from "app/button/button";

interface Props {
    more: boolean;
    setMore: React.Dispatch<React.SetStateAction<boolean>>;
}

export const HeaderMore = (props: Props) => (
    <Button
        selected={props.more}
        onClick={() => props.setMore((bool) => !bool)}
        icon={Icons.more}
        color={"solid"}
    />
);
