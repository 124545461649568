export type AppHost = "mac" | "win";

/**
 * "Host" is where the Diagram app is running inside. It may be:
 * - "web": inside a browser, like Firefox or Safari, as a website
 * - "mac": in TablePlus Mac app, via [WkWebView][wk]
 * - "win": in TablePlus Win app, via [CefSharp][cs]
 *
 * Note that "host" is our own concept and is NOT the same as the native
 * "[navigator.platform][nv]".
 *
 * [wk]: https://developer.apple.com/documentation/webkit/wkwebview
 * [cs]: https://github.com/cefsharp/CefSharp
 * [nv]: https://developer.mozilla.org/en-US/docs/Web/API/NavigatorID/platform
 */
export type Host = "web" | AppHost;

/** Returns @interface Host of current app */
const getHost = () => {
    // Check if it's "web"
    const webHostnames = [
        "localhost",
        "diagramplus.com",
        "beta.diagramplus.com",
        "diagram-plus.vercel.app",
        "192.168.0",
    ];
    if (webHostnames.includes(window.location.hostname)) return "web";
    // Check "platform" for non-"web" values
    const platform = navigator.platform.toLowerCase();
    if (platform.includes("mac")) return "mac";
    if (platform.includes("win")) return "win";
    return "web"; // linux and phones
};

export const host: Host = getHost();

export const isAppHost: boolean = host === "mac" || host === "win";
