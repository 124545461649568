import { MenuItemProps } from "app/button/menu";
import { dialogPrompt } from "app/dialog/prompt";

type Replace = (source: unknown) => void;

const importText = async (replace: Replace): Promise<void> => {
    const message = [
        "Paste a diagram into the textbox below.",
        'To export diagrams as text, go to "Export" > "Copy to clipboard".',
    ].join(" ");
    const content = <div style={{ width: 800 }}>{message}</div>;
    const rows = 10;
    const diagram = await dialogPrompt(rows, content);
    if (diagram === null || !diagram) return;
    replace(diagram);
};

const importFile = (replace: Replace) => {
    const input = document.createElement("input");
    input.type = "file";
    input.addEventListener("change", (event) => {
        const file = (event as any).target.files[0];
        const reader = new FileReader();
        reader.readAsText(file);
        reader.addEventListener("load", (event) => {
            const diagram = event.target?.result;
            if (typeof diagram !== "string")
                throw Error(`Unknown content type: ${typeof diagram}`);
            replace(diagram);
        });
    });
    input.click();
};

export const getHeaderImportTextItems = (
    replace: (source: unknown) => void
): MenuItemProps[] => [
    { label: `Open file…`, fn: () => importFile(replace) },
    { label: `Import from text…`, fn: () => importText(replace) },
];
