import { Checkbox } from "checkbox/checkbox";
import { Input } from "input/input";
import * as React from "react";

export interface ReportItemState {
    included: boolean;
    setIncluded: React.Dispatch<React.SetStateAction<boolean>>;
    text: string;
    setText: React.Dispatch<React.SetStateAction<string>>;
}

interface Props {
    label: string;
    state: ReportItemState;
    placeholder?: string;
    children?: React.ReactNode;
}

export const useReportItem = (getInitial: () => string): ReportItemState => {
    const [included, setIncluded] = React.useState(true);
    const [text, setText] = React.useState(getInitial);
    return { included, setIncluded, text, setText };
};

export const ReportItem = (props: Props) => (
    <div>
        <div className={["flex items-center"].join(" ")}>
            <div className={"flex-1"}>
                <Checkbox
                    checked={props.state.included}
                    onCheckedChange={(value) => {
                        const checked = value === true;
                        props.state.setIncluded(checked);
                    }}
                    children={props.label}
                />
            </div>
            <div className={"flex-1"}>
                <Input
                    value={props.state.text}
                    onValueChange={props.state.setText}
                    disabled={!props.state.included}
                    placeholder={props.placeholder}
                />
            </div>
        </div>
        {props.children && (
            <div className="text-[var(--slate11)]">
                <p className="leading-normal">{props.children}</p>
            </div>
        )}
    </div>
);
