import * as React from "react";
import { SetState } from "utilities/set-state";
import { initialTheme, Theme, useThemePreference } from "./theme";

export interface Preferences {
    didAskToImport: boolean;
    theme: Theme;
    zoom: number;
    footerVisible: boolean;
    edgeAnimation: boolean;
    libraryVisible: boolean;
    libraryWidth: number;
}

const defaultPrefs: Preferences = {
    didAskToImport: false,
    theme: "auto",
    zoom: 1,
    footerVisible: true,
    libraryVisible: true,
    edgeAnimation: true,
    libraryWidth: 320,
};

const STORAGE_KEY = "dg/prefs";

// Note that we need to take initialTheme into account
const getInitial = (): Preferences => {
    // Start with default prefs so we have all fields
    const prefs: Preferences = { ...defaultPrefs };
    // Merge with stored fields
    const stored = window.localStorage.getItem(STORAGE_KEY);
    if (stored !== null) Object.assign(prefs, JSON.parse(stored));
    // Theme set via host has the highest priority
    if (initialTheme !== null) prefs.theme = initialTheme;
    return prefs;
};

export const PreferencesContext = React.createContext<PreferencesProps>({
    preferences: defaultPrefs,
    setPreferences: () => {
        throw Error("Preferences context not found");
    },
});

export const usePreferences = (): PreferencesProps => {
    const [preferences, setPreferences] = React.useState(getInitial);

    const props: PreferencesProps = React.useMemo(
        () => ({ preferences, setPreferences }),
        [preferences, setPreferences]
    );

    // Save preferences
    React.useEffect(() => {
        const text = JSON.stringify(preferences);
        window.localStorage.setItem(STORAGE_KEY, text);
    }, [preferences]);

    // Theme
    useThemePreference(props);

    return props;
};

export interface PreferencesProps {
    preferences: Preferences;
    setPreferences: SetState<Preferences>;
}
