import { DiagramElements, DiagramElementsUtils } from "canvas/elements";
import { EditTable } from "diagram/model/edit/edit";
import * as React from "react";

export interface TableWrapperProps {
    elements: DiagramElements;
    table: EditTable;
}

interface Props extends TableWrapperProps {
    children: React.ReactNode;
}

const makeRef =
    (elements: DiagramElements, id: EditTable["id"]) =>
    (element: HTMLDivElement | null) => {
        if (element === null) {
            elements.tables.delete(id);
        } else {
            const table =
                elements.tables.get(id) ?? DiagramElementsUtils.newTable();
            table.element = element;
            elements.tables.set(id, table);
        }
    };

export const TableWrapper = (props: Props) => {
    // Avoid ref being called on every render
    // See: https://github.com/facebook/react/issues/6249
    const [elements, id] = [props.elements, props.table.id];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const ref = React.useCallback(makeRef(elements, id), [elements, id]);
    return (
        <div
            className={"absolute"}
            key={props.table.id}
            ref={ref}
            style={{
                top: props.table.position.top,
                left: props.table.position.left,
                zIndex: props.table.selected ? 1 : 0,
            }}
            children={props.children}
        />
    );
};
