import { border } from "border/border";
import React from "react";

const root = document.documentElement;

interface Props {
    /** In pixels, the value to be set on double click */
    fallback?: number;
    /** In pixels */
    min: number;
    /** In pixels */
    max: number;
    /** In pixels, to save to storage */
    onStop: (width: number) => void;
    /** In pixels, to update in real time */
    onChange: (width: number) => void;
}

export const Resizer = (props: Props) => {
    const [isDragging, setIsDragging] = React.useState(false);

    const getWidth = (event: MouseEvent): number => {
        let width = event.clientX;
        width = Math.min(width, props.max);
        width = Math.max(width, props.min);
        return width - 8; // half of container width
    };

    const whileDrag = (event: MouseEvent) => {
        props.onChange(getWidth(event));
    };

    const stopDrag = (event: MouseEvent) => {
        root.classList.remove("select-none");
        root.removeEventListener("mousemove", whileDrag);
        root.removeEventListener("mouseup", stopDrag);
        props.onStop(getWidth(event));
        setIsDragging(false);
    };

    return (
        <div
            className={`${[
                "h-full cursor-col-resize w-4 -mr-[15px] relative",
                "group",
            ].join(" ")} ${
                isDragging
                    ? "group-hover:border-l-2 group-hover:border-l-[var(--blue10)]"
                    : ""
            }`}
            onMouseDown={() => {
                root.classList.add("select-none");
                root.addEventListener("mousemove", whileDrag);
                root.addEventListener("mouseup", stopDrag);
                setIsDragging(true);
            }}
            onDoubleClick={() => {
                if (props.fallback) props.onStop(props.fallback);
            }}
        >
            <div
                className={[
                    "h-full",
                    "transition-[border-left-color]",
                    "border-solid border-l-[1px]",
                    "group-hover:border-l-2 group-hover:border-l-[var(--blue10)]",
                    border.strong,
                ].join(" ")}
            />
        </div>
    );
};
