import { EditDiagram, EditTable } from "diagram/model/edit/edit";
import * as React from "react";
import { EdgeCreateRows } from "../rows/rows";
import { CreateEdgeSide } from "../create";
import { Border } from "border/border";
import { Input } from "input/input";

interface Props {
    label: string;
    diagram: EditDiagram;
    side: CreateEdgeSide;
    setSide: React.Dispatch<React.SetStateAction<CreateEdgeSide>>;
    autoFocus: boolean;
}

const getSuggestions = (props: Props): string[] => {
    return props.diagram.tables.map((table) => table.value.name);
};

const getTable = (props: Props): EditTable | null => {
    const byName = (t: EditTable) => t.value.name === props.side.table;
    return props.diagram.tables.find(byName) ?? null;
};

const setTable =
    (props: Props) =>
    (table: string): void => {
        props.setSide((side) => ({ ...side, table }));
    };

const setRows = (props: Props) => {
    return (reducer: (rows: string[]) => string[]): void => {
        props.setSide((side) => ({ ...side, rows: reducer(side.rows) }));
    };
};

export const EdgeCreateSide = (props: Props) => (
    <div className={["flex items-center p-2"].join(" ")}>
        <div className={"flex-0 w-[40px] text-right"}>
            <span className="text-[var(--slate10)]">{props.label}</span>
        </div>
        <div className="h-3 w-3" />
        <div className={["flex-0 w-[120px]"].join("")}>
            <Input
                value={props.side.table}
                onValueChange={setTable(props)}
                autoFocus={props.autoFocus}
                placeholder="Table"
                list={getSuggestions(props).join(" ")}
            />
        </div>
        <div className="h-3 w-3" />
        <Border color="weak" />
        <div className="h-3 w-3" />
        <EdgeCreateRows
            diagram={props.diagram}
            table={getTable(props)}
            rows={props.side.rows}
            setRows={setRows(props)}
        />
    </div>
);
