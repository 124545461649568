import { getEdgePaths } from "canvas/edge/path/path";
import { DiagramElements } from "canvas/elements";
import { EditEdge, EditTable } from "diagram/model/edit/edit";
import { Position } from "geometry/geometry";

/**
 * @param target is the table that is currently moved by the user
 */
export type CanvasPreviewDrag = (
    target: EditTable["id"],
    delta: Position
) => void;

interface Props {
    tables: EditTable["id"][];
    edges: EditEdge[];
    elements: DiagramElements;
}

const translateTables = (
    props: Props,
    target: EditTable["id"],
    delta: Position
): void => {
    const transform = `translate(${delta.left}px, ${delta.top}px)`;
    props.tables.forEach((id) => {
        if (target === id) return;
        const table = props.elements.tables.get(id)?.element ?? null;
        if (table === null) throw Error("Table is null");
        table.style.transform = transform;
    });
};

const updateEdges = (props: Props): void => {
    const { edges, elements } = props;
    const pathsMap = getEdgePaths({ edges, elements });
    edges.forEach((edge) => {
        const group = elements.edges.get(edge.id);
        const paths = pathsMap.get(edge.id);
        if (group === undefined) throw Error("Edge is undefined");
        if (paths === undefined) throw Error("Edge paths is undefined");
        // This is a little bit risky: to avoid complex selector, we depend on
        // the rendering order of Edge's path elements: from > to > main
        //
        // It's better if we can use HTMLCollection.namedItem but our children
        // are paths so no naming
        let i = 0;
        const setD = (d: string) => {
            const element = group.children.item(i);
            if (!(element instanceof SVGPathElement))
                throw Error("Element is not SVGPath");
            // "d" is not an official attribute of "path" element
            // https://stackoverflow.com/a/6817703/6621213
            element.setAttribute("d", d);
            i = i + 1;
        };
        paths.from.forEach(setD);
        paths.to.forEach(setD);
        setD(paths.main);
        // Edge animation. This may not renders, depend on preferences
        const circle = group.children.item(i);
        if (circle instanceof SVGCircleElement) {
            const motion = circle.firstElementChild;
            if (!(motion instanceof SVGAnimateMotionElement))
                throw Error("Element is not SVGAnimationMotion");
            // Running motion looks very laggy when path changes continously,
            // so we just disable it while dragging
            motion.setAttribute("path", "");
        }
    });
};

/**
 * Preview the changes while the user drags tables around.
 *
 * When the drag stops, the actual changes are applied by the Diagram reducer.
 * This only applies the temporary CSS so that the user can see the changes in
 * real time.
 *
 * This includes 2 things:
 * - Translate the selected tables to new position
 * - Re-draw edges. Preferably we should only re-draw related edges, but due to
 * the complexity of edges
 */
export const makeCanvasPreviewDrag = (props: Props): CanvasPreviewDrag => {
    return (target, delta) => {
        translateTables(props, target, delta);
        // Ensure only run after translating tables are stablized
        window.setTimeout(() => updateEdges(props), 10);
    };
};
