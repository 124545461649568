import { DiagramElements, DiagramElementsUtils } from "canvas/elements";
import { EditRow, EditTable } from "diagram/model/edit/edit";

export const makeRowElementsRef =
    (
        elements: DiagramElements,
        tableId: EditTable["id"],
        rowId: EditRow["id"]
    ) =>
    (target: HTMLDivElement | null): void => {
        const table =
            elements.tables.get(tableId) ?? DiagramElementsUtils.newTable();
        const row = table.rows.get(rowId) ?? DiagramElementsUtils.newRow();
        if (target === null) {
            // @TODO: How to handle this if we don't have the element?
        } else {
            const name = DiagramElementsUtils.getFieldName(target);
            row.fields.set(name, target);
            table.rows.set(rowId, row);
            elements.tables.set(tableId, table);
        }
    };
