import { ComponentProps, useEffect, useRef } from "react";

export const textAreaFocusStyles = [
    //
    "transition-shadow ease-out duration-300",
    // border
    "outline outline-transparent",
    "focus:outline-blue-500",
    // shadow
    "ring-8 ring-transparent",
    "focus:ring focus:ring-blue-200",
].join(" ");

export const textAreaBlockStyles = [
    "block text-13 w-full rounded bg-[var(--slate1)]",
    "text-[var(--slate12)]",
    "border border-[var(--slate7)]",
].join(" ");

export const textAreaDisabledStyles = [
    //
    "disabled:text-[var(--slate10)]",
].join(" ");

interface Props extends ComponentProps<"textarea"> {
    onValueChange?: (value: string) => void;
    forceAutoFocus?: boolean;
    placeholder?: string;
    extraStyle?: string;
    paddingStyle?: string;
}

export type { Props as TextAreaProps };

export const TextArea = (props: Props): JSX.Element => {
    const {
        onValueChange,
        forceAutoFocus,
        placeholder,
        extraStyle,
        paddingStyle,
        ...native
    } = props;

    const ref = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (!forceAutoFocus) return;
        window.setTimeout(() => {
            ref.current?.focus();
        }, 100);
    }, [forceAutoFocus]);

    return (
        <textarea
            className={[
                //
                textAreaFocusStyles,
                textAreaBlockStyles,
                paddingStyle ? paddingStyle : "py-1.5 px-2",
                textAreaDisabledStyles,
                extraStyle ? extraStyle : "",
            ].join(" ")}
            onChange={(event) => {
                onValueChange?.(event.currentTarget.value);
            }}
            onClick={(e) => {
                e.currentTarget.focus();
                e.stopPropagation();
            }}
            ref={ref}
            placeholder={placeholder}
            {...native}
        />
    );
};
