import { ProgressCircle } from "app/progress/circle";
import { AuthContext } from "auth/auth";
import { Border } from "border/border";
import { LibraryItem, LibraryProps } from "library/library";
import * as React from "react";
import { LibraryCreate } from "./create/create";
import { LibraryItem as LibraryItemC } from "./item/item";

const compare = (a: LibraryItem, b: LibraryItem) => {
    if (a.saved === b.saved) return a.name.localeCompare(b.name);
    return (a.saved ? 1 : 0) - (b.saved ? 1 : 0);
};

export interface AppLibraryProps extends LibraryProps {}

export const AppLibrary = (props: AppLibraryProps) => {
    const { isLoadingAuth } = React.useContext(AuthContext);
    return isLoadingAuth ? (
        <div
            className={[
                "w-full h-full overflow-y-auto overflow-x-hidden flex",
            ].join(" ")}
        >
            <div className={["flex-0 m-auto block"].join(" ")}>
                {ProgressCircle({ size: 32 })}
            </div>
        </div>
    ) : (
        <div
            className={[
                "w-full h-full overflow-y-auto overflow-x-hidden",
                "bg-[var(--slate2)]",
            ].join(" ")}
        >
            <div>
                <div className={["flex items-center p-3"].join(" ")}>
                    <LibraryCreate {...props} />
                </div>

                {[...props.library.all].sort(compare).map((item) => (
                    <React.Fragment key={item.id}>
                        <LibraryItemC
                            item={item}
                            all={props.library.all}
                            setLibrary={props.setLibrary}
                            isCurrent={props.library.current === item.id}
                        />
                        <Border color="weak" />
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};
