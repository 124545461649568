import { EditTable } from "diagram/model/edit/edit";
import { DiagramReducer } from "../../reducer";

export interface ExpandSelectedTableRowsPayload {
    expanded: boolean;
}

type Reducer = DiagramReducer<ExpandSelectedTableRowsPayload>;

export const expandSelectedTableRows: Reducer = (diagram, payload) => {
    const tables: EditTable[] = diagram.tables.map((table) => {
        if (table.selected === false) return table;
        return { ...table, rowsExpanded: payload.expanded };
    });
    return { ...diagram, tables };
};
