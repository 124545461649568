import { Button } from "app/button/button";
import { Input } from "input/input";
import { TextArea } from "input/textarea";
import { useState } from "react";
import { Dialog } from "./dialog";
import { renderDialog } from "./native";

interface Props {
    children: React.ReactNode;
    onOk: (text: string) => void;
    onCancel: () => void;
    // Options
    initialText?: string;
    rows?: number;
}

export const PromptDialog = (props: Props) => {
    const { rows, initialText, children, onOk, onCancel } = props;
    const [text, setText] = useState(initialText ?? "");

    return (
        <Dialog.Root open={true}>
            <Dialog.Content onClickOverlay={onCancel}>
                <>
                    <div
                        className={["p-3 flex items-center text-center"].join(
                            " "
                        )}
                    >
                        <Dialog.Description asChild>
                            {children}
                        </Dialog.Description>
                    </div>

                    <div className={["p-3"].join(" ")}>
                        {rows !== 1 ? (
                            <TextArea
                                forceAutoFocus
                                extraStyle={["block w-full"].join(" ")}
                                value={text}
                                onValueChange={setText}
                                rows={rows}
                            />
                        ) : (
                            <Input
                                forceAutoFocus
                                extraStyle={["block h-full w-full"].join(" ")}
                                value={text}
                                onValueChange={setText}
                            />
                        )}
                    </div>

                    <div
                        className={["flex justify-center gap-2 p-4"].join(" ")}
                    >
                        <Dialog.Close asChild>
                            <Button
                                extraStyle="w-[96px]"
                                color="gray"
                                children="Cancel"
                                onClick={() => {
                                    onCancel();
                                }}
                            />
                        </Dialog.Close>
                        <Dialog.Close asChild>
                            <Button
                                extraStyle="w-[96px]"
                                color="blue"
                                children="OK"
                                onClick={() => {
                                    onOk(text);
                                }}
                            />
                        </Dialog.Close>
                    </div>
                </>
            </Dialog.Content>
        </Dialog.Root>
    );
};

export const dialogPrompt = (
    rows: Props["rows"],
    message: React.ReactNode,
    initialText?: string
): Promise<string | null> => {
    return new Promise((resolve) => {
        renderDialog((unmount) => (
            <PromptDialog
                rows={rows}
                initialText={initialText}
                children={message}
                onCancel={() => {
                    resolve(null);
                    unmount();
                }}
                onOk={(text) => {
                    resolve(text);
                    unmount();
                }}
            />
        ));
    });
};
