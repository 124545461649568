import { DiagramReducer } from "../reducer";
import { selectAllTables } from "../table/select/all";

export interface SelectRefPayload {
    id: number;
    append: boolean;
}

type Payload = SelectRefPayload;

export const selectRef: DiagramReducer<Payload> = (prev, payload) => {
    // Clear previous selection if necessary
    const diagram = payload.append
        ? prev
        : selectAllTables(prev, { selected: false });
    // Set ref selection
    const refs = diagram.refs.map((ref) => {
        if (ref.id !== payload.id) return ref;
        return { ...ref, selected: true };
    });
    return { ...diagram, refs };
};
