import { Button } from "app/button/button";

interface Props {
    remove: (() => void) | null;
}

export const EdgeCreateFooter = (props: Props) => (
    <div color="bg-[var(--slate7)]">
        <div className={["p-2 flex items-center"].join(" ")}>
            <span className="text-[var(--slate8)]">
                <div className={["text-xs"].join(" ")}>
                    You can also drag the green
                    <br />
                    circle to create reference
                </div>
            </span>
            <div className="block flex-[1_0_0px]" />
            {props.remove !== null && (
                <>
                    <Button
                        color="solid"
                        onClick={props.remove}
                        children="Delete"
                    />
                    <div className="h-3 w-3" />
                </>
            )}
            <Button color="solid" type="submit" children="Create" />
        </div>
    </div>
);
