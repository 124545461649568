import { Preferences, PreferencesContext } from "preferences/preferences";
import * as React from "react";
import { Resizer } from "utilities/resizer/resizer";

interface Props {
    libraryElement: React.RefObject<HTMLDivElement>;
}

const setWidth =
    (width: number) =>
    (prev: Preferences): Preferences => ({
        ...prev,
        libraryWidth: width,
    });

const onChange =
    (props: Props) =>
    (width: number): void => {
        const element = props.libraryElement.current;
        if (element === null) throw Error("Library element is null");
        element.style.width = `${width}px`;
    };

export const AppResizer = (props: Props) => {
    const setPrefs = React.useContext(PreferencesContext).setPreferences;
    return (
        <div className={["relative"].join(" ")}>
            <Resizer
                fallback={320}
                min={240}
                max={800}
                onChange={onChange(props)}
                onStop={(width) => setPrefs(setWidth(width))}
            />
        </div>
    );
};
