import { BaseColumn } from "diagram/model/base/base";
import { EditColumn, EditRow, EditTable } from "diagram/model/edit/edit";
import { getNumberId, getStringId } from "utilities/id";
import { DiagramReducer } from "../reducer";

export interface AddRowPayload {
    tableId: EditTable["id"];
    rowId: EditRow["id"] | null;
}

type Payload = AddRowPayload;

// TODO: Should use Record instead of Map but BaseColumn["type"] is just string
// const defaultFields: Record<BaseColumn["type"], string> = {
const defaultFields: Map<BaseColumn["type"], string> = new Map();
defaultFields.set("boolean", "FALSE");
defaultFields.set("string", "");

const getRowField = (column: EditColumn): string => {
    const field = defaultFields.get(column.value.type);
    if (field !== undefined) return field;
    throw Error(`Unknown column type "${column.value.type}"`);
};

export const getDefaultRow = (
    assignedId: EditRow["id"] | null,
    columns: EditColumn[],
    rows: EditRow[]
): EditRow => {
    // 1. Provide ids for a row. A row has 2 unique ids:
    // 1.1. row.id: the id of an EditRow, use in the app to edit the diagram
    const id: EditRow["id"] = assignedId ?? getNumberId(rows.map((r) => r.id));
    // 1.2. row.value.id: the id of a BaseRow, use when import and export the
    //    diagram
    const allValueIds: string[] = rows.map((r) => r.value.id);
    const valueId: string = getStringId(allValueIds, "column");
    // Note that there is also row.value[column[0].value] which is the id in
    // user's perspective, but for us it's just a field

    // 2. Provide fields based on columns
    const value: EditRow["value"] = { id: valueId };
    columns.forEach((column, index) => {
        // First column is special case: the "id" in user's perspective, so it's
        // better to have a meaningful string here to start with
        value[column.value.name] = index === 0 ? valueId : getRowField(column);
    });

    return { id, value, isPrimary: false };
};

const updateTable = (payload: Payload, columns: EditColumn[]) => {
    return (table: EditTable): EditTable => {
        if (table.id !== payload.tableId) return table;
        const row = getDefaultRow(payload.rowId, columns, table.value.rows);
        const rows = table.value.rows.concat(row);
        return { ...table, value: { ...table.value, rows } };
    };
};

export const addRow: DiagramReducer<Payload> = (diagram, payload) => {
    const update = updateTable(payload, diagram.columns);
    return { ...diagram, tables: diagram.tables.map(update) };
};
