type Callback = (...args: any[]) => void;

const callbacks: { [name: string]: Function } = {};
(window as any).Diagram = callbacks;

/** Logging for debug and report purpose */
export const hostCallbackLogs: [string, any][] = [["Initialized", {}]];

/**
 * Add a callback that Host can call from outside via @param name
 */
export const addHostCallback = (name: string, callback: Function) => {
    callbacks[name] = callback;
};

/**
 * Return a function to add a callback that Host can call from outside via
 * @param name. As soon as this is executed, Host will be able to "call" the
 * callback but the callback won't be executed until it is provided via the
 * returned function.
 *
 * In other words, this creates a placeholder and an argument queue to allow
 * the Host to execute even before the actual callback to be provided later.
 * When the callback is provided, it will be executed with the pending
 * arguments, FIFO.
 */
export const addHostCallbackFuture = (
    name: string
): ((callback: Callback) => void) => {
    const argsQueue: any[][] = [];
    // This is the placeholder callback that push args to queue while waiting
    // for the real callback to be assigned
    callbacks[name] = (...args: any[]) => {
        hostCallbackLogs.push([`Pending ${name}`, args]);
        argsQueue.push(args);
    };
    // Return a function to add the real callback
    return (callback: Callback) => {
        // Execute pending arguments
        while (argsQueue.length > 0) {
            const args = argsQueue.shift();
            hostCallbackLogs.push([`Execute pending ${name}`, args]);
            callback(args);
        }
        // Replace the placeholder with real callback
        callbacks[name] = callback;
    };
};
