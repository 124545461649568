import { Point } from "./point";

const RADIUS = 3;

const getAnchorAxis = (A: Point, B: Point, key: keyof Point) => {
    const [a, b] = [A[key], B[key]];
    const r = Math.min(RADIUS, Math.abs(a - b) / 2);
    if (a === b) return a;
    return b + r * (a > b ? 1 : -1);
};

const getAnchor = (A: Point, B: Point): Point => ({
    x: getAnchorAxis(A, B, "x"),
    y: getAnchorAxis(A, B, "y"),
});

/**
 * Create a rounded corner at B by returning the SVG's Q path at B
 * - Before: M(A) -> L(B) -> L(C)
 * - After:  M(A) -> L(X) -> Q(B, Y) -> L(C)
 * Where X and Y are returned by this function
 */
export const roundPolylineCorner = (
    A: Point,
    B: Point,
    C: Point
): { X: Point; Y: Point } => ({
    X: getAnchor(A, B),
    Y: getAnchor(C, B),
});
