import { getCanvasCopyText } from "app/canvas/shortcut/copy";
import { Menu, MenuItemProps } from "app/menu/menu";
import { ExpandSelectedTableRowsPayload } from "diagram/state/reducer/table/rows/selected";
import { DiagramProps } from "diagram/state/state";
import { copyToClipboard } from "utilities/clipboard";
import { TableMenuState } from "../menu";

interface Props extends DiagramProps, TableMenuState {}

const getMenuItems = (props: Props): MenuItemProps[] => {
    const count = props.diagram.tables.filter((t) => t.selected).length;
    const suffix = count > 1 ? ` ${count} tables` : "";
    return [
        { label: `Copy${suffix}`, fn: () => copy(props) },
        { label: `Duplicate${suffix}`, fn: () => duplicate(props) },
        "divider",
        { label: "Expand all columns", fn: () => expand(props) },
        { label: "Collapse columns", fn: () => collapse(props) },
        "divider",
        { label: `Delete${suffix}`, fn: () => remove(props) },
    ];
};

const remove = (props: Props): void => {
    props.diagramDispatch({ type: "remove-selected-items" });
};

const duplicate = (props: Props): void => {
    props.diagramDispatch({ type: "duplicate-selected-table" });
};

const expand = (props: Props): void => {
    const payload: ExpandSelectedTableRowsPayload = { expanded: true };
    props.diagramDispatch({ type: "expand-selected-table-rows", payload });
};

const collapse = (props: Props): void => {
    const payload: ExpandSelectedTableRowsPayload = { expanded: false };
    props.diagramDispatch({ type: "expand-selected-table-rows", payload });
};

const copy = (props: Props): void => {
    const text = getCanvasCopyText(props.diagram);
    copyToClipboard(text);
};

export const TableMenuCanvas = (props: Props) => {
    if (props.tableMenuPosition === null) return null;
    const { top, left } = props.tableMenuPosition;
    return (
        <div className={["fixed z-[2]"].join(" ")} style={{ top, left }}>
            <Menu
                items={getMenuItems(props).map((item) => {
                    if (item === "divider") return item;
                    const fn = () => {
                        props.setTableMenuPosition(null);
                        item.fn?.();
                    };
                    return { ...item, fn };
                })}
                onEsc={() => props.setTableMenuPosition(null)}
            />
        </div>
    );
};
