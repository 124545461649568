import { Checkbox } from "checkbox/checkbox";
import { PreferencesContext } from "preferences/preferences";
import { useContext } from "react";

export const HeaderFooter = () => {
    const context = useContext(PreferencesContext);
    const { preferences: prefs, setPreferences: setPrefs } = context;
    return (
        <Checkbox
            checked={prefs.footerVisible}
            onCheckedChange={(value) => {
                const checked = value === true;
                setPrefs((prev) => ({ ...prev, footerVisible: checked }));
            }}
            children="Show footer"
        />
    );
};
