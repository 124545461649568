import { Box, Position } from "geometry/geometry";

const getBox = (start: Position, current: Position): Box => {
    const top = Math.min(start.top, current.top);
    const right = Math.max(start.left, current.left);
    const bottom = Math.max(start.top, current.top);
    const left = Math.min(start.left, current.left);
    const [width, height] = [right - left, bottom - top];
    return { top, left, width, height };
};

const applyBox = (indicator: HTMLElement, b: Box, s: number): void => {
    indicator.style.width = `${b.width / s}px`;
    indicator.style.height = `${b.height / s}px`;
    indicator.style.transform = `translate(${b.left / s}px, ${b.top / s}px)`;
};

const create = (start: Position, scale: number): HTMLElement => {
    const indicator = document.createElement("div");
    const classList =
        "relative border-solid border-[1] border-[--highlight-5] bg-opacity bg-[#4199E1]/[0.2]".split(
            " "
        );
    for (let eachClass of classList) {
        indicator.classList.add(eachClass);
    }
    const box = getBox(start, start);
    applyBox(indicator, box, scale);
    return indicator;
};

export const SelectIndicator = { create, applyBox, getBox };
