import { Icons } from "utilities/icon";
import { useNavigate } from "react-router-dom";
import { Button } from "app/button/button";

export const SharedHeaderReturn = () => {
    const navigate = useNavigate();

    const returnToDiagramplus = () => {
        navigate("/");
    };

    return (
        <Button
            color="solid"
            onClick={returnToDiagramplus}
            icon={Icons.home}
            children="Return to Diagramplus"
        />
    );
};
