import { getCanvasScale } from "canvas/edge/path/ends/ends";
import { DiagramElements } from "canvas/elements";
import { EditEdge } from "diagram/model/edit/edit";
import { SelectAllTablesPayload } from "diagram/state/reducer/table/select/all";
import { DiagramProps } from "diagram/state/state";
import { Position, subtractPosition } from "geometry/geometry";
import * as React from "react";
import { SelectIndicator } from "./indicator";
import { SelectOverlap, SelectOverlapState } from "./overlap";

const root = document.documentElement;

interface Props {
    elements: DiagramElements;
    edges: EditEdge[];
    diagramDispatch: DiagramProps["diagramDispatch"];
}

export interface State {
    canvas: HTMLElement;
    scale: number;
    indicator: HTMLElement;
    offset: Position;
    start: Position;
    overlap: SelectOverlapState;
}

export type SelectState = State;

const makeOnScroll = (state: State) => {
    const onScroll = (): void => {
        const { top, left } = state.canvas.getBoundingClientRect();
        state.offset = { top, left };
    };
    return onScroll;
};

const makeOnMouseDown = (props: Props) => {
    const onMouseDown = (event: MouseEvent): void => {
        // Skip if dragging to scroll. See app/canvas/scroll
        if (props.elements.viewport?.classList.contains("dragscroll")) return;

        // Reset all selections
        const payload: SelectAllTablesPayload = { selected: false };
        props.diagramDispatch({ type: "select-all-tables", payload });

        // Bring target to front to avoid user selection over other elements
        // while dragging (this is like user-select: none)
        const canvas = event.target;
        if (!(canvas instanceof HTMLElement))
            throw Error("Canvas is not an HTMLElement");
        canvas.style.zIndex = "2";

        // Store canvas position as offset
        const { top, left } = canvas.getBoundingClientRect();
        const scale = getCanvasScale(canvas.parentElement!);
        const offset: Position = { top, left };

        // Create selection indicator (blue box)
        const start: Position = (() => {
            const { clientX: left, clientY: top } = event;
            return subtractPosition({ top, left }, offset);
        })();
        const indicator = SelectIndicator.create(start, scale);
        canvas.appendChild(indicator);

        // Observe indicator
        const { elements, edges } = props;
        const overlap = SelectOverlap.init({ offset, elements, edges });

        const state: State = { scale, offset, start, indicator, canvas, overlap }; // prettier-ignore
        const onMouseMove = makeOnMouseMove(state);
        root.addEventListener("mousemove", onMouseMove);
        const onScroll = makeOnScroll(state);
        props.elements.viewport?.addEventListener("scroll", onScroll);
        const onMouseUp = makeOnMouseUp(state, props, onMouseMove, onScroll);
        root.addEventListener("mouseup", onMouseUp);
    };
    return onMouseDown;
};

const makeOnMouseMove = (state: State) => {
    const onMouseMove = (event: MouseEvent): void => {
        event.preventDefault();
        // Current position
        const { clientX: left, clientY: top } = event;
        const current = subtractPosition({ left, top }, state.offset);
        // Update indicator box
        const box = SelectIndicator.getBox(state.start, current);
        SelectIndicator.applyBox(state.indicator, box, state.scale);
        // Update temporary overlap style
        SelectOverlap.setStyle(state.overlap, box);
    };
    return onMouseMove;
};

const makeOnMouseUp = (
    state: State,
    props: Props,
    onMouseMove: (event: MouseEvent) => void,
    onScroll: (event: Event) => void
) => {
    const onMouseUp = (event: MouseEvent): void => {
        // Dispatch selections
        const { clientX: left, clientY: top } = event;
        const current = subtractPosition({ left, top }, state.offset);
        const box = SelectIndicator.getBox(state.start, current);
        const tableIds = new Set(SelectOverlap.getMatched(state.overlap, box));

        if (tableIds.size > 0) {
            props.diagramDispatch({
                type: "select-some-tables",
                payload: { tableIds, append: false },
            });
        }

        // Clean up
        state.canvas.style.zIndex = "0";
        state.indicator.remove();
        SelectOverlap.clear(state.overlap);
        root.removeEventListener("mousemove", onMouseMove);
        root.removeEventListener("mouseup", onMouseUp);
        props.elements.viewport?.removeEventListener("scroll", onScroll);
    };
    return onMouseUp;
};

export const CanvasSelect = (props: Props) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const { elements, diagramDispatch, edges } = props;
    React.useEffect(() => {
        const element = ref.current;
        if (element === null) throw Error("Element is null");
        const props = { elements, diagramDispatch, edges };
        const onMouseDown = makeOnMouseDown(props);
        element.addEventListener("mousedown", onMouseDown);
        return () => element.removeEventListener("mousedown", onMouseDown);
    }, [elements, diagramDispatch, edges]);
    return (
        <div
            className={["w-full h-full absolute top-0 left-0"].join(" ")}
            ref={ref}
        />
    );
};
