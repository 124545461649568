import { Alert } from "app/alert/alert";
import { Button } from "app/button/button";
import { Dialog } from "app/dialog/dialog";
import { stringifyDiagram } from "diagram/model/stringify/stringify";
import { hostCallbackLogs } from "host/callbacks";
import { host, Host } from "host/host";
import { VERSION } from "index";
import { Input } from "input/input";
import { CurrentStorage } from "library/storage/current";
import { DocStorage } from "library/storage/doc";
import * as React from "react";
import { ReportItem, ReportItemState, useReportItem } from "./item/item";

const REPORT_URL = "https://diagram-services.vercel.app/api/report";

export type ReportType = "uncaught" | "manual";

interface Props {
    type: ReportType;
    title: string;
    description: React.ReactNode;
    footer?: React.ReactNode;
    error?: string;
    onDone?: () => void;
    //
    open?: boolean;
    setOpen?: (open: boolean) => void;
}

const getDiagram = async (): Promise<string> => {
    const current = await CurrentStorage.get();
    if (current === null) return "No diagram selected";
    const item = await DocStorage.get(current);
    return stringifyDiagram(item.diagram, "edit", "json");
};

interface ReportBody {
    // Always included
    type: ReportType;
    host: Host;
    version: number;
    // User provided
    error?: string;
    message?: string;
    contact?: string;
    agent?: string;
    diagram?: string;
    log?: string;
}

const getItem = (state: ReportItemState): string | undefined =>
    state.included ? state.text : undefined;

export const Report = (props: Props) => {
    const { open, setOpen } = props;

    const isUncaught = props.type === "uncaught";

    const message = useReportItem(() => "");
    const contact = useReportItem(() => "");
    const error = useReportItem(() => props.error ?? "");
    const log = useReportItem(() => JSON.stringify(hostCallbackLogs));
    const diagram = useReportItem(() => "");
    const agent = useReportItem(() => window.navigator.userAgent);

    // Diagram must be loaded async
    const setDiagram = diagram.setText;
    React.useEffect(() => {
        getDiagram().then((text) => void setDiagram(text));
    }, [setDiagram]);

    const [alertDescription, setAlertDescription] = React.useState<string>("");
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
    const alert = (description: string) => {
        setAlertDescription(description);
        setAlertOpen(true);
    };

    const submitReport = async (event: React.FormEvent) => {
        event.preventDefault();
        const body: ReportBody = {
            type: props.type,
            host: host,
            version: VERSION,
            // User provided
            error: getItem(error),
            message: getItem(message),
            contact: getItem(contact),
            agent: getItem(agent),
            diagram: getItem(diagram),
            log: getItem(log),
        };
        try {
            await window.fetch(REPORT_URL, {
                method: "POST",
                body: JSON.stringify(body),
                headers: { "Content-type": "application/json" },
            });
            alert("The report has been sent successfully");
        } catch (error: unknown) {
            const message = error instanceof Error ? error.message : "Unknown";
            alert(
                [
                    "There was an error sending the report",
                    `Error detail: ${message}`,
                ].join("\n")
            );
        }
    };

    return (
        <>
            <Dialog.Root open={open} onOpenChange={setOpen}>
                <Dialog.Content
                    extraStyles={["sm:w-fit sm:min-w-[300px] p-4"].join(" ")}
                >
                    <form onSubmit={(event) => submitReport(event)}>
                        <Dialog.Title>{props.title}</Dialog.Title>
                        <div>{props.description}</div>
                        <Input
                            autoFocus
                            value={message.text}
                            onValueChange={message.setText}
                        />
                        <div className="w-3 h-3" />
                        {isUncaught && (
                            <>
                                <ReportItem
                                    label="Include error detail"
                                    state={error}
                                />
                                <div className="h-3 w-3" />
                            </>
                        )}
                        <ReportItem
                            label="Include the diagram"
                            state={diagram}
                        />
                        <div className={"w-3 h-3"} />
                        <ReportItem label="Include user agent" state={agent} />
                        <div className={"w-3 h-3"} />
                        <ReportItem
                            label="Include application log"
                            state={log}
                        />
                        <div className={"w-3 h-3"} />
                        <ReportItem
                            state={contact}
                            label="Allow us to contact you at:"
                            placeholder="name@example.com"
                        />
                        <div className="flex gap-2 justify-end py-3">
                            {props.footer}
                            <Button
                                type="submit"
                                color="blue"
                                children="Send Report"
                            />
                        </div>
                    </form>
                </Dialog.Content>
            </Dialog.Root>
            <Alert
                title=""
                description={alertDescription}
                cancel="Ok"
                open={alertOpen}
                setOpen={setAlertOpen}
                onCancel={() => {
                    props.onDone?.();
                }}
            />
        </>
    );
};
