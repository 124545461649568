import airlines from "./airlines.json";
import employees from "./employees.json";
import empty from "./empty.json";
import imdb from "./imdb.json";
// import debugL from "./debug-l.json";
// import debugM from "./debug-m.json";
// import debugS from "./debug-s.json";

interface DiagramSample {
    title: string;
    source: unknown;
}

export const diagramSamples: DiagramSample[] = [
    { title: "Empty", source: empty },
    { title: "Airlines", source: airlines },
    { title: "Imdb", source: imdb },
    { title: "Employees", source: employees },
    // { title: "debugL", source: debugL },
    // { title: "debugM", source: debugM },
    // { title: "debugS", source: debugS },
];
